/** @format */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { editCoverApi, editProfileApi, editProfileDetailsApi } from "./api";

export const editProfile = createAsyncThunk(
  "editProfile/edit",
  async (payload) => {
    try {
      const response = await editProfileApi(payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const editCover = createAsyncThunk("editCover/edit", async (payload) => {
  try {
    const response = await editCoverApi(payload);
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const editInfo = createAsyncThunk("editInfo/edit", async (payload) => {
  try {
    const response = await editProfileDetailsApi(payload);
    return response.data;
  } catch (error) {
    throw error;
  }
});
