/** @format */
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import { BiPlay } from "react-icons/bi";
import { IoIosVideocam } from "react-icons/io";
import { useEffect, useRef, useState } from "react";
import Button from "../../components/button";
import { PiScreencastThin } from "react-icons/pi";
import { BiFullscreen } from "react-icons/bi";
import { BsPause } from "react-icons/bs";
import VolumeControl from "../volumeControl";
import video from "../../assets/videos.mp4";
const UploadVideo = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [totalTime, setTotalTime] = useState(0);

  const videoRef = useRef();
  const fileInputRef = useRef();

  const togglePlay = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const videoURL = URL.createObjectURL(file);
      videoRef.current.src = videoURL;
      videoRef.current.load();
      setIsPlaying(false);
    }
  };

  const updateTime = () => {
    setCurrentTime(videoRef.current.currentTime);
  };

  const updateTotalTime = () => {
    setTotalTime(videoRef.current.duration);
  };

  useEffect(() => {
    const interval = setInterval(updateTime, 1000);
    return () => clearInterval(interval);
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };
  return (
    <Box sx={{ position: "relative" }}>
      <Box>
        <video
          ref={videoRef}
          controls={false}
          onClick={togglePlay}
          style={{
            width: "100%",
            height: "224px",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            objectFit: "cover",
          }}>
          <source src={video} type="video/mp4" />
          <div
            className="progress"
            style={{
              width: isPlaying ? `${(currentTime / totalTime) * 100}%` : "0%",
              backgroundColor: isPlaying ? "red" : "grey",
            }}></div>
        </video>
        <Box
          sx={{
            position: "absolute",
            bottom: "50px",
            right: "5px",
          }}>
          <input
            type="file"
            accept="video/*"
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={handleFileChange}
          />
          <Button
            variant="contained"
            startIcon={<IoIosVideocam />}
            onClick={() => fileInputRef.current.click()}
            sx={{
              backgroundColor: "##34C77B !important",
            }}>
            Upload
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0.7rem",
          borderRadius: "0px 0px 15px 0px",
          borderRight: "1px solid #E5E8EB !important",
          borderBottom: "1px solid #E5E8EB !important",
          boxSizing: "border-box",
        }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "150px",
          }}>
          {isPlaying ? (
            <>
              <BsPause
                onClick={togglePlay}
                style={{ fontSize: "20px", cursor: "pointer" }}>
                {isPlaying ? "Play" : "Pause"}
              </BsPause>
            </>
          ) : (
            <BiPlay
              className="custom-play-button"
              onClick={togglePlay}
              style={{ fontSize: "20px", cursor: "pointer" }}>
              {isPlaying ? "Pause" : "Play"}
            </BiPlay>
          )}

          <VolumeControl />
          <span>
            <Typography sx={{ color: "#929AA1" }}>
              {formatTime(currentTime)} / {formatTime(totalTime)}
            </Typography>
          </span>
          <Box display="flex" gap="5px" alignItems="center">
            <Box
              sx={{
                width: "5px",
                height: "5px",
                borderRadius: "50%",
                backgroundColor: "red",
              }}></Box>
            <Typography variant="body1">live</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "35px",
            display: "flex",
            justifyContent: "space-between",
          }}>
          <PiScreencastThin style={{ cursor: "pointer" }} />
          <BiFullscreen style={{ cursor: "pointer" }} />
        </Box>
      </Box>
    </Box>
  );
};
export default UploadVideo;
