/** @format */

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { makeStyles } from '@mui/styles';
import cn from 'classnames';

export const CarouselArrow = ({ type, onClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper} onClick={onClick}>
      <button className={classes.leftChevron}>
        <ArrowForwardIcon
          className={cn({
            [classes.arrowRight]: type === 'NEXT',
          })}
        />
      </button>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    // width: 40,
    padding: '0px 20px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  leftChevron: {
    borderRadius: '50px',
    width: '25px',
    height: '25px',
    cursor: 'pointer',
    outline: 1,
    border: '1px solid black',
    background: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: ' matrix(-1, 0, 0, 1, 0, 0)',
    backgroundColor: 'black',
    color: 'white',
    '& .MuiSvgIcon-root': {
      // color: "#34C77B",
    },
    '&:hover': {
      color: '#E1BB54',
    },
  },
  arrowRight: {
    transform: 'rotate(180deg)',
  },
}));
