/** @format */

import {
  Typography,
  Rating,
  IconButton,
  useTheme,
  useMediaQuery,
  Box,
  Button,
} from '@mui/material';
import CustomAvatar from '../avatar';
import Wrapper from '../../components/wrapper';
import badge from '../../assets/place.png';
import { AiOutlineInstagram } from 'react-icons/ai';
import { BiArrowBack } from 'react-icons/bi';
import { BsShareFill } from 'react-icons/bs';
import {
  BiLogoDiscordAlt,
  BiLogoLinkedin,
  BiLogoTwitter,
  BiSolidShareAlt,
} from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { PROFILE_BASE_URL } from '../../http/config';
import { Link, useParams } from 'react-router-dom';
import { singleCreator } from '../../redux/singleCreator/singleCreator.action';
import { followUser } from '../../redux/arts/arts.action';
import { useAlert } from 'react-alert';
const InvestorProfile = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const socialIcons = [
    { icon: <AiOutlineInstagram fontSize="medium" />, label: 'Instagram' },
    { icon: <BiLogoDiscordAlt fontSize="medium" />, label: 'Discord' },
    { icon: <BiLogoLinkedin fontSize="medium" />, label: 'LinkedIn' },
    { icon: <BiLogoTwitter fontSize="medium" />, label: 'Twitter' },
    { icon: <BiSolidShareAlt fontSize="medium" />, label: 'Share' },
  ];
  const { username } = useParams();
  const alert = useAlert();
  const dispatch = useDispatch();
  const { user, isAuthenticated } = useSelector((state) => state.auth);
  const { data } = useSelector((state) => state.singleCreator.creator);
  const handleFollow = async () => {
    const payloadData = {
      data: { followerId: user.walletAddress, followingId: data.walletAddress },
      type: data.isFollowed ? 'unfollow' : 'follow',
    };
    const { error, payload } = await dispatch(followUser(payloadData));
    if (error) {
      alert.show('Try Again later', {
        timeout: 3000, // custom timeout just for this one alert
        type: 'error',
      });
    } else {
      let wallet = null;
      if (isAuthenticated) {
        wallet = user.walletAddress;
      }
      let payloadCreator = { wallet: wallet, user: username };
      dispatch(singleCreator(payloadCreator));
      alert.show(payload.data.message, {
        timeout: 3000, // custom timeout just for this one alert
        type: 'success',
      });
    }
  };

  return (
    <>
      <Box>
        <>
          <Box
            sx={{
              width: '100%',
              height: '400px',
              backgroundImage: `url(${PROFILE_BASE_URL + data.cover})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          />
          <Wrapper>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0px',
                position: 'relative',
                top: '-60px',
                alignItems: 'center',
              }}
            >
              <Box>
                <CustomAvatar
                  src={PROFILE_BASE_URL + data.img}
                  width="100px"
                  height="100px"
                />
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  flexDirection: 'column',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    width: {
                      lg: '610px',
                      md: '550px',
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '10px',

                      flexWrap: 'wrap',
                      flexDirection: 'column',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '5px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '2px 0',
                      }}
                    >
                      <Typography
                        variant="h1"
                        sx={{ textTransform: 'capitalize' }}
                      >
                        {data.username}
                      </Typography>
                      <img src={badge} alt="badge" width="20px" />
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '5px',
                        alignItems: 'center',
                      }}
                    >
                      <Rating name="read-only" readOnly value={data.rating} />
                      <Typography variant="body1" sx={{ color: '#8F979E' }}>
                        ({data.rating})
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  gap: '0px',

                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '10px 0px',
                  flexDirection: 'column',
                }}
              >
                <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                  <Box
                    sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}
                  >
                    <Typography variant="body1" sx={{ color: '#8F979E' }}>
                      Followers
                    </Typography>
                    <Typography variant="h1">{data.followers}</Typography>
                  </Box>
                  <Box
                    sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}
                  >
                    <Typography variant="body1" sx={{ color: '#8F979E' }}>
                      Posts
                    </Typography>
                    <Typography variant="h1">{data.posts}</Typography>
                  </Box>
                  <Box
                    sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}
                  >
                    <Typography variant="body1" sx={{ color: '#8F979E' }}>
                      Following
                    </Typography>
                    <Typography variant="h1">{data.following}</Typography>
                  </Box>
                </Box>
                <Box pt={1}>
                  {user.walletAddress === data.walletAddress ? (
                    <Box>
                      <Button
                        component={Link}
                        to={`/editProfile/${user.username}`}
                        sx={{
                          color: '#fff !important',
                          background: '#34C77B !important',
                          textTransform: 'capitalize',
                          fontSize: '12px',
                        }}
                      >
                        Edit Profile
                      </Button>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '35x',
                        justifyContent: isAuthenticated
                          ? 'space-between'
                          : 'center',
                        width: '192px',
                      }}
                    >
                      {isAuthenticated && (
                        <Button
                          sx={{
                            color: '#fff !important',
                            background: '#34C77B !important',
                            textTransform: 'capitalize',
                            fontSize: '12px',
                          }}
                          onClick={handleFollow}
                        >
                          {data.isFollowed ? 'Following' : 'Follow'}
                        </Button>
                      )}
                      <Button
                        sx={{
                          color: '#000 !important',
                          background: '#E5E8EB !important',
                          textTransform: 'capitalize',
                          fontSize: '12px',
                          padding: 1,
                        }}
                        component={Link}
                        to={`/chat?user=${data.creatorID}`}
                      >
                        Send Message
                      </Button>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box sx={{ maxWidth: '400px' }}>
                <Typography variant="body1">{data.bio}dfasdfa</Typography>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  gap: '5px',
                  width: '100%',

                  justifyContent: 'flex-end',
                  alignItems: 'end',
                  padding: '10px 0px',
                }}
              >
                {socialIcons.map((socialIcon, index) => (
                  <IconButton
                    key={index}
                    sx={{
                      background: 'white',
                      color: 'black',
                      '&:hover': {
                        background: 'white',
                      },
                    }}
                    aria-label={socialIcon.label}
                  >
                    {socialIcon.icon}
                  </IconButton>
                ))}
              </Box>
            </Box>
          </Wrapper>
        </>
      </Box>
    </>
  );
};
export default InvestorProfile;
