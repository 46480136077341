/** @format */

import { Box, Button, CircularProgress, TextField } from '@mui/material';
import {
  useGrantRole,
  useContract,
  useRoleMembers,
  useAddress,
} from '@thirdweb-dev/react';
import WalletTable from './../approvalTable';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { nftContractAddress } from './../../hooks/thirdwebHooks/contractAddresses';
import { getCreators } from '../../redux/inWallet/inwallet.action';
import { Navigate } from 'react-router-dom';
const MintingRole = ({ type = 'approvals' }) => {
  const dispatch = useDispatch();
  const [creatorsdata, setData] = useState([]);
  const [walletAddress, setWalletAddress] = useState(null);
  const [mintersList, setMintersList] = useState([]);
  const { contract } = useContract(nftContractAddress);
  const { mutateAsync: grantRole } = useGrantRole(contract);
  const { data: minters, isLoading: loading } = useRoleMembers(
    contract,
    'minter'
  );
  const { data: members, isLoading } = useRoleMembers(contract, 'admin');
  const wallet = useAddress();
  useEffect(() => {
    if (!isLoading) {
      if (!members?.includes(wallet)) {
        Navigate('/');
      }
    }
    dispatch(getCreators()).then((response) => {
      setData(response.payload);
    });
    if (!loading) {
      setMintersList(minters);
    }
  }, [dispatch, isLoading, members, wallet, minters, loading]);

  const handleApprove = async (wallet) => {
    try {
      const tx = await grantRole({
        role: 'minter', // name of your role.
        address: wallet, // address to grant the role to.
      });
      console.log(tx, 'txxxx');
    } catch (err) {
      console.log(err.message);
    }
  };
  const handleAdmin = async () => {
    try {
      if (walletAddress === null || walletAddress === '') {
        return window.alert('Enter the Wallet Address');
      } else {
        const tx = await grantRole({
          role: 'admin', // name of your role.
          address: walletAddress, // address to grant the role to.
        });
        console.log(tx, 'txxxx');
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  const setWallet = (wallet) => {
    setWalletAddress(wallet);
  };

  return (
    <>
      {mintersList.length > 0 && creatorsdata.length > 0 ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            width: '100%',
            marginY: '2vh',
          }}
        >
          {type === 'approvals' ? (
            <WalletTable
              data={creatorsdata}
              minters={mintersList}
              handleApprove={handleApprove}
            />
          ) : (
            <Box sx={{ color: '#fff', width: '100%', display: 'block' }}>
              <Box>
                {' '}
                <h2>Add Admin Wallet</h2>{' '}
              </Box>
              <Box>
                <TextField
                  sx={{ marginY: '10px', width: '40%', color: 'white' }}
                  name="wallet"
                  id="outlined-basic"
                  label="WalletAddress"
                  variant="outlined"
                  required
                  focused
                  onChange={(e) => {
                    setWallet(e.target.value);
                  }}
                />
              </Box>

              <Button
                onClick={handleAdmin}
                sx={{ width: '40%' }}
                size="large"
                variant="outlined"
              >
                Grant Role
              </Button>
            </Box>
          )}
        </Box>
      ) : (
        <CircularProgress size={30} />
      )}
    </>
  );
};

export default MintingRole;
