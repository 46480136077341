/** @format */

import React from "react";
import ArticlesCard from "../articlesCard";

const Articalslist = ({ data }) => {
  return (
    <>
      {data &&
        data.length > 0 &&
        data.map((data, i) => <ArticlesCard key={i} {...data} />)}
    </>
  );
};

export default Articalslist;
