/** @format */

import { Box } from "@mui/material";
import loadingImage from "../../assets/loader.gif";
const Loading = () => {
  return (
    <Box
      sx={{
        display: "grid",
        placeContent: "center",
        gap: 2,
      }}>
      <Box
        sx={{
          display: "grid",
          placeContent: "center",
        }}>
        <img
          src={loadingImage}
          style={{ width: "120px", height: "90px" }}
          alt=""
        />
      </Box>
    </Box>
  );
};

export default Loading;
