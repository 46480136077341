/** @format */

import { Button as MuiButton } from "@mui/material";
import { withStyles } from "@mui/styles";

const Button = withStyles(() => ({
  root: {
    backgroundColor: "transparent",
    color: "black",
    width: "100%",
  },

  contained: {
    fontWeight: "600",
    fontSize: "11px",
    border: "1px solid #E5E8EB !important",
    textTransform: "capitalize !important",
    height: "43px !important",
    "&:hover": {
      background: "#34C77B !important",
      color: "#fff !important",
    },
  },
  outlined: {
    color: "black !important",
    padding: "1px 0",
    fontWeight: "bold",
    fontSize: "11px",
    border: "1px solid #E5E8EB !important",
    textTransform: "capitalize !important",
    height: "43px !important",
    borderRadius: "none",
    "&:hover": {
      color: "#4ACD89 !important",
      border: "1px solid #4ACD89 !important",
    },
  },
  standard: {
    background: "linear-gradient(#FCE5A6,#F9C029) !important",
    fontSize: "12px !important",
    color: "black",
    "&:hover": {
      background: "#FCE5A6 !important",
    },
  },
}))(MuiButton);

export default Button;
