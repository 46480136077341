/** @format */

import {
  Box,
  FormControlLabel,
  InputBase,
  Select as MenuSelect,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';

import ImageUpload from '../imageUploader';
import Input from '../input';
import Button from '../button';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormFeedback } from 'reactstrap';

import ConnectWalletComponent from '../walletAddress';
import { username as CheckUserName } from '../../redux/wishlist/wishlist.action';

const CreatorSignupForm = ({
  handleNext,

  onFormValuesChange,
  handleBack,
  formik,
}) => {
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({});

  const { isCreatorSingupSuccess } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  useEffect(() => {
    onFormValuesChange(formik.values);
  }, [formik.values, onFormValuesChange]);
  useEffect(() => {
    fetch(
      'https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code'
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
        if (!selectedCountry) {
          setSelectedCountry(data.userSelectValue);

          formik.setFieldValue('country', null);
        }
      });
  }, [selectedCountry, formik.setFieldValue]);
  const { isUsernameSuccess } = useSelector((state) => state.helpersSlicer);

  useEffect(() => {
    dispatch(CheckUserName(formik.values.username));
  }, [formik.values.username, dispatch]);
  return (
    <Box
      sx={{
        height: '340px',
        overflowY: 'auto',
        paddingRight: '4px',
        '&::-webkit-scrollbar': {
          width: '6px',
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#DEE2E6',
          borderRadius: '3px',
        },
      }}
    >
      {isCreatorSingupSuccess ? (
        <Typography sx={{ fontSize: 16 }}>
          {' '}
          In target to complete your renewable energy project, our support team
          will contact you during the next 72 hours to start the process.
        </Typography>
      ) : (
        <Form onSubmit={() => formik.isValid && formik.handleSubmit}>
          <Box>
            <ConnectWalletComponent formik={formik} />
            {formik.touched.wallet && formik.errors.wallet ? (
              <FormFeedback
                type="invalid"
                style={{
                  fontSize: '10px',
                  color: 'red',
                  textAlign: 'center',
                }}
              >
                {formik.errors.wallet}
              </FormFeedback>
            ) : null}
          </Box>
          <Box sx={{ width: '100%' }}>
            <label
              style={{ color: '#8f979e', fontSize: '14px', paddingLeft: 10 }}
            >
              Username
            </label>
            <Box py={0.5} sx={{ width: '100%' }}>
              <Input
                name="username"
                type="username"
                className="form-control pe-5"
                placeholder="Username"
                onChange={formik.handleChange}
                value={formik.values.username}
                invalid={
                  formik.touched.share && formik.errors.share ? true : false
                }
                sx={{ width: '100%' }}
              />
              {formik.touched.username && formik.errors.username ? (
                <FormFeedback
                  type="invalid"
                  style={{ fontSize: '10px', color: 'red' }}
                >
                  {formik.errors.username}
                </FormFeedback>
              ) : null}
              {formik.values.username ? (
                <span
                  style={{
                    color: isUsernameSuccess === true ? 'green' : 'red',
                    fontSize: '12px',
                    textAlign: 'center',
                  }}
                >
                  {isUsernameSuccess === true
                    ? 'Username avilable.....'
                    : 'Username already in use'}
                </span>
              ) : (
                ''
              )}
            </Box>
          </Box>
          <Box sx={{ width: '100%' }}>
            <label
              style={{ color: '#8f979e', fontSize: '14px', paddingLeft: 10 }}
            >
              Full Name
            </label>
            <Box py={0.5} sx={{ width: '100%' }}>
              <Input
                name="fullName"
                type="fullName"
                className="form-control pe-5"
                placeholder="Full name"
                onChange={formik.handleChange}
                value={formik.values.fullName}
                invalid={
                  formik.touched.share && formik.errors.share ? true : false
                }
                sx={{ width: '100%' }}
              />
              {formik.touched.fullName && formik.errors.fullName ? (
                <FormFeedback
                  type="invalid"
                  style={{ fontSize: '10px', color: 'red' }}
                >
                  {formik.errors.fullName}
                </FormFeedback>
              ) : null}
            </Box>
          </Box>
          <Box py={0.5} sx={{ width: '100%' }}>
            <label
              style={{ color: '#8f979e', fontSize: '14px', paddingLeft: 10 }}
            >
              Gender
            </label>

            <RadioGroup
              name="gender"
              displayEmpty
              value={formik.values.gender || ''}
              inputProps={{ 'aria-label': 'Without label' }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={
                formik.touched.gender && formik.errors.gender ? true : false
              }
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                justifyItems: 'center',
              }}
              py={2}
            >
              <FormControlLabel
                value="female"
                sx={{
                  '& .MuiRadio-root': {
                    color: '#000 !important',
                  },
                }}
                control={
                  <Radio
                    sx={{
                      '& .Mui-checked': {
                        color: '#000 !important',
                      },
                    }}
                    color="secondary"
                  />
                }
                label="Female"
              />
              <FormControlLabel
                value="male"
                control={<Radio />}
                label="Male"
                sx={{
                  '& .MuiRadio-root': {
                    color: '#000 !important',
                  },
                }}
              />
              <FormControlLabel
                value="other"
                control={<Radio />}
                label="Other"
                sx={{
                  '& .MuiRadio-root': {
                    color: '#000 !important',
                  },
                }}
              />
            </RadioGroup>
          </Box>
          <Box py={0.5} sx={{ width: '100%' }}>
            <label
              style={{
                color: '#8f979e',
                fontSize: '14px',
                paddingLeft: 10,
              }}
            >
              Birthday
            </label>
            <Box py={0.5} sx={{ width: '100%' }}>
              <Input
                name="dateOfBirth"
                type="date"
                className="form-control pe-5"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.dateOfBirth}
                invalid={
                  formik.touched.share && formik.errors.share ? true : false
                }
                sx={{ width: '100%' }}
              />

              {formik.touched.dateOfBirth && formik.errors.dateOfBirth ? (
                <FormFeedback
                  type="invalid"
                  style={{
                    fontSize: '10px',
                    color: 'red',
                    textAlign: 'center',
                  }}
                >
                  {formik.errors.dateOfBirth}
                </FormFeedback>
              ) : null}
            </Box>
          </Box>
          <Box py={0.5} sx={{ width: '100%' }}>
            <label
              style={{ color: '#8f979e', fontSize: '14px', paddingLeft: 10 }}
            >
              Email
            </label>
            <Box py={0.5} sx={{ width: '100%' }}>
              <Input
                name="email"
                type="email"
                className="form-control pe-5"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                invalid={
                  formik.touched.share && formik.errors.share ? true : false
                }
                sx={{ width: '100%' }}
              />

              {formik.touched.email && formik.errors.email ? (
                <FormFeedback
                  type="invalid"
                  style={{ fontSize: '10px', color: 'red' }}
                >
                  {formik.errors.email}
                </FormFeedback>
              ) : null}
            </Box>
          </Box>
          <Box py={0.5} sx={{ width: '100%' }}>
            <label
              style={{ color: '#8f979e', fontSize: '14px', paddingLeft: 10 }}
            >
              Verify Email
            </label>
            <Box py={0.5} sx={{ width: '100%' }}>
              <Input
                onChange={formik.handleChange}
                name="verifyEmail"
                type="email"
                className="form-control pe-5"
                value={formik.values.verifyEmail}
                sx={{ width: '100%' }}
              />

              {formik.touched.verifyEmail && formik.errors.verifyEmail ? (
                <FormFeedback
                  type="invalid"
                  style={{
                    fontSize: '10px',
                    color: 'red',
                    textAlign: 'center',
                  }}
                >
                  {formik.errors.verifyEmail}
                </FormFeedback>
              ) : null}
            </Box>
          </Box>

          <Box py={0.5} sx={{ width: '100%' }}>
            <label
              style={{ color: '#8f979e', fontSize: '14px', paddingLeft: 10 }}
            >
              Country
            </label>
            <Select
              options={countries}
              value={selectedCountry}
              onChange={(selectedOption) => {
                setSelectedCountry(selectedOption);
                formik.setFieldValue('country', selectedOption.value);
              }}
            />
          </Box>
          <Box py={0.5} sx={{ width: '100%' }}>
            <label
              style={{ color: '#8f979e', fontSize: '14px', paddingLeft: 10 }}
            >
              Goverment ID Number
            </label>
            <Box py={0.5} sx={{ width: '100%' }}>
              <InputBase
                name="govermentID"
                type="govermentID"
                className="form-control pe-5"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.govermentID}
                invalid={
                  formik.touched.govermentID && formik.errors.govermentID
                    ? true
                    : false
                }
                sx={{
                  background: '#FAFCFF 0% 0% no-repeat padding-box !important',
                  border: '1px solid #E5E8EB',
                  borderRadius: '8px',
                  opacity: 1,
                  width: '100%',
                  padding: '8px',
                }}
              />
              {formik.touched.govermentID && formik.errors.govermentID ? (
                <FormFeedback
                  type="invalid"
                  style={{
                    fontSize: '10px',
                    color: 'red',
                    textAlign: 'center',
                  }}
                >
                  {formik.errors.govermentID}
                </FormFeedback>
              ) : null}
            </Box>
          </Box>
          <Box py={0.5} sx={{ width: '100%' }}>
            <label
              style={{ color: '#8f979e', fontSize: '14px', paddingLeft: 10 }}
            >
              Goverment ID Picture
            </label>
            <Box py={0.5} sx={{ width: '100%' }}>
              <ImageUpload formik={formik} value="govermentIDPicture" />
            </Box>
          </Box>
          <Box py={0.5} sx={{ width: '100%' }}>
            <label
              style={{ color: '#8f979e', fontSize: '14px', paddingLeft: 10 }}
            >
              Profile Picture
            </label>
            <Box py={0.5} sx={{ width: '100%' }}>
              <ImageUpload formik={formik} value="profilePicture" />
            </Box>
          </Box>
          <Box py={0.5} sx={{ width: '100%' }}>
            <label
              style={{ color: '#8f979e', fontSize: '14px', paddingLeft: 10 }}
            >
              Cover Picture
            </label>
            <Box py={0.5} sx={{ width: '100%' }}>
              <ImageUpload formik={formik} value="coverPicture" />
            </Box>
          </Box>
          <Box>
            <>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '.5fr 3fr',
                  justifyContent: 'space-between',
                  gap: 1,
                }}
              >
                <Button
                  onClick={() => {
                    handleBack();
                  }}
                  sx={{
                    backgroundColor: 'grey !important',
                    textTransform: 'capitalize',
                    color: '#fff !important',
                  }}
                >
                  Back
                </Button>
                {/* {(formik.dirty && !formik.isValid) || !formik.dirty ? ( */}
                {/* <FormFeedback
                  type="invalid"
                  style={{ fontSize: "10px", color: "red" }}>
                  Please fill all the values!
                </FormFeedback> */}
                {/* ) : ( */}
                {formik.values.username === '' ||
                formik.values.wallet === '' ||
                formik.values.gender === '' ||
                formik.values.dateOfBirth === '' ||
                formik.values.email === '' ||
                formik.values.fullname === '' ||
                formik.values.govermentID === '' ? (
                  <Typography sx={{ textAlign: 'center', color: 'red' }} py={1}>
                    Fill required fields
                  </Typography>
                ) : (
                  <Button
                    onClick={() => handleNext(formik)}
                    sx={{
                      backgroundColor: '#34C77B !important',
                      textTransform: 'capitalize',
                      color: '#fff !important',
                    }}
                  >
                    Next
                  </Button>
                )}

                {/* )} */}
              </Box>
            </>
          </Box>
        </Form>
      )}
    </Box>
  );
};

export default CreatorSignupForm;
