/** @format */

import { Box, Typography, Grid, Divider } from '@mui/material';
import InvestorFilter from '../../components/investorFilter';
import LeaderBoard from '../../components/leaderboard';
import BigMovers from '../../components/bigMovers';
import Wrapper from '../../components/wrapper';
import Articles from '../../components/Articles';
import TopCards from '../../components/Top10Cards';

import StoryModal from '../../components/storyModal';
import Trander from '../../components/trader';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { leaderBoardData } from '../../redux/singleCreator/singleCreator.action';
import { artsData } from '../../redux/arts/arts.action';
import { storiesData } from '../../redux/stories/stories.action';
import Notifications from '../../components/Notifications';
import { unreadMessages } from '../../redux/auth/auth.actions';

const Home = () => {
  const dispatch = useDispatch();
  const { user, isAuthenticated } = useSelector((state) => state.auth);
  const { isArtsDataLoadingSuccess } = useSelector((state) => state.artsSlicer);

  useEffect(() => {
    dispatch(leaderBoardData());

    if (isAuthenticated) {
      dispatch(artsData(user.walletAddress));
      dispatch(unreadMessages(user.walletAddress));
    } else {
      dispatch(artsData());
    }
    dispatch(storiesData());
  }, [dispatch, user, isArtsDataLoadingSuccess, isAuthenticated]);
  const { topInvestors, topCreators } = useSelector(
    (state) => state.singleCreator.leaderBoard
  );
  const [singleCardView, setSingleCardView] = useState(false);

  const handleToggleSwitch = () => {
    setSingleCardView(!singleCardView);
  };

  return (
    <Box>
      {/* Alerts for all  */}

      <Notifications />

      <Wrapper>
        {' '}
        <StoryModal />
      </Wrapper>

      <Divider />
      <InvestorFilter
        handleToggleSwitch={handleToggleSwitch}
        singleCardView={singleCardView}
      />
      <TopCards
        singleCardView={singleCardView}
        title="Top 10"
        margin="4rem 0"
      />

      <TopCards
        title="Trending"
        margin="4rem 0"
        singleCardView={singleCardView}
      />

      <Articles singleCardView={singleCardView} />
      <Box pb={2}>
        {' '}
        <Wrapper> {!singleCardView && <BigMovers />}</Wrapper>
      </Box>
      <Trander />
      <Wrapper>
        <Box margin={'4rem 0'}>
          <Typography variant="h1" marginBottom={'1rem'}>
            Top 5 Leaderboard
          </Typography>
          <Grid container justifyContent={'space-between'}>
            <LeaderBoard
              topInvestors={topInvestors}
              topCreators={topCreators}
            />
          </Grid>
        </Box>
      </Wrapper>
    </Box>
  );
};
export default Home;
