/** @format */

import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "../button";
import SignupForm from "../signupForm";
import ExclusiveSelection from "../exclusiveSelection";
import SignupProgressBar from "../signupBar";
import { useDispatch, useSelector } from "react-redux";
import CreatorDetails from "../creatorDetails";
import CreatorFiles from "../creatorFiles";
import CreatorSignupForm from "../creatorSignupForm";
import { useFormik } from "formik";
import * as Yup from "yup";
import { creatorSignup } from "../../redux/auth/auth.actions";
import { useAddress } from "@thirdweb-dev/react";
export default function HorizontalLinearStepper({
  handleClose,
  handleRemoveLogin,
}) {
  const stepsInvestor = ["Profile Type", "Sign Up", "Additional Step"];
  const stepsCreator = [
    "Profile Type",
    "Creator Step 1",
    "Creator Step 2",
    "Creator Step 3",
    "Creator Step 4",
    "Sign Up",
  ];
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [button, setButton] = React.useState(null);
  const [values, setValues] = React.useState("");
  const [finalValues, setFinalValues] = React.useState("");
  const { isCreatorSingupSuccess } = useSelector((state) => state.auth);
  const [signupFormValues, setSignupFormValues] = React.useState({});
  const [creatorDetailsValues, setCreatorDetailsValues] = React.useState(null);
  const walletAddress = useAddress();
  const dispatch = useDispatch();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: "",
      fullName: "",
      gender: "",
      email: "",
      country: "",
      govermentID: "",
      govermentIDPicture: "",
      profilePicture: "",
      coverPicture: "",
      type: button,
      dateOfBirth: "",
      wallet: walletAddress,
      companyName: "",
      registrationNumber: "",
      companyEmail: "",
      designation: "",
      physicalAddress: "",
      websiteUrl: "",
      contactPerson: "",
      companyDetails: "",
      projectLocation: "",
      estimatedValue: "",
      tokenizationObjective: "",
      APYRange: "",
      financialStatements: "",
      legalDocuments: "",
      environmentalImpactReport: "",
      technicalSpecifications: "",
      ownershipStructure: "",
      previousFunding: "",
      tokenAllocationPlan: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const fd = new FormData();
        fd.append("username", values.username);
        fd.append("fullName", values.fullName);

        fd.append("gender", values.gender);
        fd.append("email", values.email);
        fd.append("country", values.country);
        fd.append("govermentID", values.govermentID);
        fd.append("govermentIDPicture", values.govermentIDPicture);
        fd.append("profilePicture", values.profilePicture);
        fd.append("coverPicture", values.coverPicture);
        fd.append("type", values.type);
        fd.append("wallet", values.wallet);
        fd.append("dateOfBirth", values.dateOfBirth);
        fd.append("companyName", values.companyName);
        fd.append("registrationNumber", values.registrationNumber);
        fd.append("companyEmail", values.companyEmail);
        fd.append("phoneNumber", values.phoneNumber);
        fd.append("physicalAddress", values.physicalAddress);
        fd.append("websiteUrl", values.websiteUrl);
        fd.append("contactPerson", values.contactPerson);
        fd.append("designation", values.designation);
        fd.append("companyDetails", values.companyDetails);
        fd.append("projectLocation", values.projectLocation);
        fd.append("estimatedValue", values.estimatedValue);
        fd.append("tokenizationObjective", values.tokenizationObjective);
        fd.append("APYRange", values.APYRange);

        dispatch(creatorSignup(fd));
      } catch (error) {
        console.error("signup error", error);
      }
    },
  });

  const handleButtonSelect = (button) => {
    setButton(button);
  };
  const handleRemove = async () => {
    handleRemoveLogin();
  };
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = (formik, details) => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
    setValues(formik.values);
    setFinalValues(details);
  };
  const handleInvestorNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleFormValuesChange = (formValues) => {
    setSignupFormValues(formValues);
  };

  const handleFormDetailsChange = (formDetails) => {
    setCreatorDetailsValues(formDetails);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyItems: "center",
      }}>
      {/* Render steps specific to investor */}
      {activeStep === 0 && (
        <>
          <Box pb={5}>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: "bold",
                textAlign: "center",
                pb: 1,
              }}>
              Let's Create your account
            </Typography>
            <SignupProgressBar activeStep={activeStep} />
          </Box>
          <Box sx={{ padding: "20px 0 5px 0" }}>
            <ExclusiveSelection onButtonSelect={handleButtonSelect} />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            {button && (
              <Button
                sx={{
                  backgroundColor: "#34C77B !important",
                  textTransform: "capitalize",
                  color: "#fff !important",
                }}
                onClick={() => handleNext({}, {})}>
                {" "}
                {/* Pass empty values for simplicity */}
                Next
              </Button>
            )}
          </Box>
        </>
      )}
      {activeStep >= 1 && (
        <>
          {button && (
            <>
              {button === "creator" && (
                <>
                  {activeStep === 1 && (
                    <Box>
                      <Box pb={3}>
                        {" "}
                        <Typography
                          sx={{
                            fontSize: 20,
                            fontWeight: "bold",
                            textAlign: "center",
                            pb: 1,
                          }}>
                          Let's Create your account
                        </Typography>
                        <SignupProgressBar activeStep={activeStep} />
                      </Box>
                      <Box>
                        <CreatorSignupForm
                          handleNext={handleNext}
                          activeStep={activeStep}
                          step={stepsInvestor}
                          button={button}
                          onFormValuesChange={handleFormValuesChange}
                          handleBack={handleBack}
                          formik={formik}
                        />
                      </Box>
                    </Box>
                  )}
                  {activeStep === 2 && (
                    <>
                      <Box sx={{ paddingBottom: "30px" }}>
                        <Typography
                          sx={{
                            fontSize: 20,
                            fontWeight: "bold",
                            textAlign: "center",
                            pb: 1,
                          }}>
                          Let's Create your account
                        </Typography>
                        <SignupProgressBar activeStep={activeStep} />
                      </Box>
                      <Box>
                        <CreatorDetails
                          handleNext={handleNext}
                          button={button}
                          onFormDetailsChange={handleFormDetailsChange}
                          handleBack={handleBack}
                          formik={formik}
                        />
                      </Box>
                    </>
                  )}
                  {activeStep === 3 && (
                    <Box>
                      <CreatorFiles
                        handleNext={handleNext}
                        values={values}
                        finalValues={finalValues}
                        signupFormValues={signupFormValues}
                        creatorDetailsValues={creatorDetailsValues}
                        handleBack={handleBack}
                        handleClose={handleClose}
                        formik={formik}
                      />
                    </Box>
                  )}
                  {activeStep === 4 && isCreatorSingupSuccess && (
                    <Box>
                      <Typography
                        sx={{
                          fontSize: 20,
                          fontWeight: "bold",
                          textAlign: "center",
                          pb: 1,
                        }}>
                        Let's Create your account
                      </Typography>
                      <SignupProgressBar activeStep={activeStep} />
                    </Box>
                  )}
                </>
              )}
              {button !== "creator" && (
                <>
                  <Box sx={{ paddingBottom: "30px" }}>
                    <SignupForm
                      handleNext={handleInvestorNext}
                      activeStep={activeStep}
                      step={button === "creator" ? stepsCreator : stepsInvestor}
                      button={button}
                      handleClose={handleClose}
                    />
                  </Box>
                </>
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
}
const validationSchema = Yup.object({
  companyName: Yup.string().required("Company Name is required"),
  registrationNumber: Yup.string().required("Registration Number is required"),
  phoneNumber: Yup.string().required("Contact Information is required"),
  companyEmail: Yup.string()
    .email("Invalid email address")
    .required("Company Email is required"),
  designation: Yup.string().required("Designation is required"),
  physicalAddress: Yup.string().required("Physical Address is required"),
  websiteUrl: Yup.string()
    .url("Invalid URL")
    .required("Website URL is required"),
  contactPerson: Yup.string().required("Contact Person is required"),
  companyDetails: Yup.string().required("Company Details is required"),
  projectLocation: Yup.string().required("Project Location is required"),
  estimatedValue: Yup.number()
    .typeError("Estimated Value must be a number")
    .required("Estimated Value is required"),
  tokenizationObjective: Yup.string().required(
    "Tokenization Objective is required"
  ),
  username: Yup.string().required("Username is required"),
  gender: Yup.string().required("Gender is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  country: Yup.string().required("Country is required"),
  govermentID: Yup.string().required("Government ID is required"),
  govermentIDPicture: Yup.mixed().required("Government ID Picture is required"),
  type: Yup.string().required("User type is required"),
  verifyEmail: Yup.string()
    .oneOf([Yup.ref("email"), null], "Emails must match") // Ensure verifyEmail matches email
    .required("Verify Email is required"),
  fullName: Yup.string().required("Full name is required"),
});
