/** @format */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { artsApi, buyArts, buyNFTApi, followApi, getArtsApi } from "./api";

export const artsData = createAsyncThunk("arts/getarts", async (payload) => {
  try {
    if (payload) {
      payload = `?wallet=${payload}`;
    } else {
      payload = "";
    }
    const response = await artsApi(payload);
    return response.data;
  } catch (error) {
    throw error;
  }
});
export const followUser = createAsyncThunk("arts/follow", async (payload) => {
  try {
    const response = await followApi(payload);
    return response;
  } catch (error) {
    throw error;
  }
});

export const buyNFT = createAsyncThunk(
  "arts/buyarts",
  async (payload, { getState, dispatch }) => {
    const {
      transferFrom,
      transferHash,
      transferTo,
      amount,
      tokenId,
      orderId,
      amountId,
    } = payload;

    //Check balance

    const fd = new FormData();
    fd.append("transferFrom", transferFrom);
    fd.append("transferTo", transferTo);
    fd.append("amount", amount);
    fd.append("tokenId", tokenId);
    fd.append("orderId", orderId);
    fd.append("transferHash", transferHash);
    fd.append("amountId", amountId);

    console.log("Form data: ", fd);
    const response = await buyArts(fd);
    return response;
  }
);

export const sellNFT = createAsyncThunk("arts/sell", async (payload) => {
  try {
    const response = await buyNFTApi(payload);
    return response[0];
  } catch (error) {
    throw error;
  }
});

export const getSingleArts = createAsyncThunk(
  "arts/single",
  async (payload) => {
    try {
      const response = await getArtsApi(payload);
      return response;
    } catch (error) {
      throw error;
    }
  }
);
