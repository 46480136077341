/** @format */

import { lumiShare } from "../../http/config";

export const mintingApi = async (payload) => {
  try {
    const res = await lumiShare.post("/app.v1/api/mintArt", payload);
    return res;
  } catch (error) {
    throw error;
  }
};

export const putOnFixedPriceApi = async (payload) => {
  try {
    const res = await lumiShare.post("/app.v1/api/putOnFixedPrice", payload);
    return res;
  } catch (error) {
    throw error;
  }
};

export const putCollectionPriceApi = async (payload) => {
  try {
    const res = await lumiShare.post("/app.v1/api/listCollection", payload);
    return res;
  } catch (error) {
    throw error;
  }
};
