/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { addArticles, getArticle } from "./articles.action";

const initialState = {
  isArticlesLoading: false,
  isArticlesLoadingError: false,
  isArticlesLoadingSuccess: false,
  articles: [],

  addArticlesLoading: false,
  addArticlesLoadingError: false,
  addArticlesLoadingSuccess: false,
};

const articlesSlicer = createSlice({
  name: "articles",
  initialState: initialState,
  reducers: {
    resetStateBuy: (state) => {
      state.isArticlesLoading = false;
      state.isArticlesLoadingError = false;
      state.isArticlesLoadingSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getArticle.pending, (state) => {
      state.isArticlesLoading = true;
      state.isArticlesLoadingError = false;
      state.isArticlesLoadingSuccess = false;
    });
    builder.addCase(getArticle.fulfilled, (state, action) => {
      state.isArticlesLoading = false;
      state.isArticlesLoadingError = false;
      state.isArticlesLoadingSuccess = true;
      state.articles = action.payload;
    });
    builder.addCase(getArticle.rejected, (state) => {
      state.isArtsDataLoading = false;
      state.isArtsDataLoadingError = true;
      state.isArtsDataLoadingSuccess = false;
    });
    builder.addCase(addArticles.pending, (state) => {
      state.addArticlesLoading = true;
      state.addArticlesLoadingError = false;
      state.addArticlesLoadingSuccess = false;
    });
    builder.addCase(addArticles.fulfilled, (state, action) => {
      state.addArticlesLoading = false;
      state.addArticlesLoadingError = false;
      state.addArticlesLoadingSuccess = true;
    });
    builder.addCase(addArticles.rejected, (state) => {
      state.addArticlesLoading = false;
      state.addArticlesLoadingError = true;
      state.addArticlesLoadingSuccess = false;
    });
  },
});

export const { resetStateBuy } = articlesSlicer.actions;

export default articlesSlicer.reducer;
