/** @format */

import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { Rating, Typography } from '@mui/material';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ padding: '10px 0px' }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function FarmTabs() {
  const [value, setValue] = React.useState(0);
  const { data } = useSelector((state) => state.artsSlicer.singleArt);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            color: 'grey',
            '& .Mui-selected': {
              color: 'black !important',
              backgroundColor: '#f5f5f5',
            },
            '& .css-19jrd4d-MuiButtonBase-root-MuiTab-root': {
              alignItems: 'flex-start',
            },

            '& .MuiButtonBase-root': {
              color: 'grey',
            },
          }}>
          <Tab
            label="Description"
            {...a11yProps(0)}
            sx={{
              color: 'black',
              width: '50%',
              alignItems: 'flex-start',
              textTransform: 'capitalize',
            }}
          />
          <Tab
            label="Reviews (0)"
            {...a11yProps(1)}
            sx={{
              color: 'black',
              width: '50%',
              alignItems: 'flex-start',
              textTransform: 'capitalize',
            }}
          />
        </Tabs>
      </Box>
      <CustomTabPanel
        value={value}
        index={0}
        sx={{
          padding: 0,
          '& .css-19kzrtu': {
            padding: 0,
          },
        }}>
        <Typography sx={{ fontSize: '16px', padding: '' }}>
          {data?.description}
        </Typography>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
          <Rating
            precision={1}
            value={data?.rating}
            sx={{ fontSize: '20px' }}
          />
          <Typography sx={{ fontSize: '10px', color: 'grey' }}>
            ({data?.rating})
          </Typography>
        </Box>
      </CustomTabPanel>
    </Box>
  );
}
