/** @format */

import { Box, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import Wrapper from "../wrapper";

import "./style.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import CollectedCards from "../collectedCards";
import { useSelector } from "react-redux";
const InvestmentHistory = () => {
  const theme = useTheme();
  const medium = useMediaQuery(theme.breakpoints.down("md"));

  const { collectedArts } = useSelector(
    (state) => state.inWalletSlicer.inWallet.data
  );

  return (
    <Wrapper>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          marginBottom: "4rem",
        }}>
        <Box>
          <Typography
            variant="h1"
            sx={{ textAlign: medium ? "center" : "start" }}>
            Investment Profile
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            paddingX: "4vw",
            paddingY: "20px",
          }}>
          {collectedArts.length > 0 ? (
            collectedArts?.map((items) => {
              return <CollectedCards {...items} />;
            })
          ) : (
            <Typography
              variant="h1"
              sx={{ textAlign: medium ? "center" : "start" }}>
              No Data Found
            </Typography>
          )}
        </Box>
      </Box>
    </Wrapper>
  );
};
export default InvestmentHistory;
