/** @format */

import { Box, LinearProgress, Typography } from "@mui/material";

export default function SignupProgressBar({ activeStep }) {
  function getProgressValue(activeStep) {
    switch (activeStep) {
      case 0:
        return 0;
      case 1:
        return 25;
      case 2:
        return 50;
      case 3:
        return 75;
      case 4:
        return 100;
      default:
        return 0;
    }
  }

  return (
    <Box sx={{ width: "100%", margin: "5px 0", position: "relative" }}>
      <LinearProgress
        color="success"
        variant="determinate"
        value={getProgressValue(activeStep)}
        sx={{
          borderRadius: "50px",
          height: "30px",
          position: "absolute",
          width: "100%",
          zIndex: 1,
          "& .MuiLinearProgress-bar": {
            backgroundColor: "#fac73f !important",
            color: "red",
          },
        }}
      />
      <Typography
        variant="body2"
        sx={{
          textAlign: "center",
          position: "absolute",
          width: "100%",
          zIndex: 2,
          top: 15,
          left: 0,
          transform: "translateY(-50%)",
          color: "#000",
        }}>
        {getProgressValue(activeStep)}% Completed Profile
      </Typography>
    </Box>
  );
}
