/** @format */

import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import './style.css';
const AlertPopup = ({ open, type, close }) => {
  return (
    <Dialog open={open}>
      <DialogTitle
        sx={{
          width: '30vw',
          textAlign: 'center',
          fontWeight: 'bolder',
          fontFamily: 'sans-serif',
        }}
      >
        {type}
      </DialogTitle>
      <DialogContent sx={{ font: 'black' }}>
        {type === 'success' ? (
          <div class="success-animation">
            <svg
              class="checkmark"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 52 52"
            >
              <circle
                class="checkmark__circle"
                cx="26"
                cy="26"
                r="25"
                fill="none"
              />
              <path
                class="checkmark__check"
                fill="none"
                d="M14.1 27.2l7.1 7.2 16.7-16.8"
              />
            </svg>
          </div>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {' '}
            <h1>
              <ErrorIcon sx={{ fontSize: '120px', color: 'red' }} />
            </h1>
          </div>
        )}

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '40px',
          }}
        >
          <Button
            onClick={() => {
              close();
            }}
            variant="contained"
          >
            Close
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AlertPopup;
