/** @format */

import { Box, Typography, InputBase } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { PROFILE_BASE_URL } from "../../http/config";
import { editCover } from "../../redux/editProfile/editProfile.action";
import { useRef } from "react";

const UploadCoverImg = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.singleCreator.creator);
  const [coverImg, setCoverImg] = useState();
  const inputRef = useRef(null);
  const handleUpload = () => {
    console.log("testing");
  };
  const handleChange = (event) => {
    setCoverImg(event.target.files[0]);
    const fd = new FormData();
    fd.append("cover", event.target.files[0]);
    fd.append("walletAddress", data?.walletAddress);
    dispatch(editCover(fd));
  };
  return (
    <>
      <Box>
        <Box
          sx={{
            height: "300px",
            width: "100%",
            backgroundImage: `url(${
              coverImg
                ? URL.createObjectURL(coverImg)
                : PROFILE_BASE_URL + data?.cover
            })`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            position: "relative",
          }}>
          <Box
            sx={{
              position: "absolute",
              bottom: "10px",
              right: "10px",

              zIndex: "1000",
            }}>
            <label
              htmlFor="uploadCoverInput"
              style={{
                width: "fit-content",
                height: "fit-content",
                border: "1px solid black",
                borderRadius: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                cursor: "pointer",
                gap: "5px",
                padding: "10px 20px",
                background: "#FFFFFF",
              }}>
              <InputBase
                type="file"
                name="image"
                onChange={handleChange}
                style={{ display: "none" }}
                id="uploadCoverInput"
                ref={inputRef}
              />{" "}
              <Typography
                variant="body2"
                sx={{ color: "black", fontWeight: "bold" }}>
                Edit Cover
              </Typography>
            </label>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default UploadCoverImg;
