/** @format */

import { createSlice } from "@reduxjs/toolkit";
const logsSlice = createSlice({
  name: "logs",
  initialState: {
    messages: [],
  },
  reducers: {
    addLog: (state, action) => {
      state.messages.push(action.payload);
    },
    clearLogs: (state) => {
      state.messages = [];
    },
  },
});

export const { addLog, clearLogs } = logsSlice.actions;

export const selectLogs = (state) => state.logs.messages;

export default logsSlice.reducer;
