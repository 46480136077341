/** @format */

import { useDispatch, useSelector } from "react-redux";
import UploadCoverImg from "../../components/uploadCover";
import UploadProfile from "../../components/uploadProfile";
import Wrapper from "../../components/wrapper";
import CustomToaster from "../../components/toaster";
import { useEffect } from "react";
import { resetStateEdit } from "../../redux/editProfile/editProfile.slice";
import { singleCreator } from "../../redux/singleCreator/singleCreator.action";
import { useParams } from "react-router-dom";
import Loading from "../../components/loading";
import Notifications from "../../components/Notifications";

const EditProfile = () => {
  const { isEditProfileSuccess, isEditCoverSuccess, isEditInfoSuccess } =
    useSelector((state) => state.editProfileSlice);
  const { isSingleCreatorLoadingSuccess } = useSelector(
    (state) => state.singleCreator
  );
  const { username } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(singleCreator(username));
  }, [dispatch, username]);
  if (isSingleCreatorLoadingSuccess) {
    return (
      <>
        <Notifications />

        <UploadCoverImg />
        <Wrapper>
          <UploadProfile />
        </Wrapper>
      </>
    );
  } else {
    <>
      <Loading />
    </>;
  }
};
export default EditProfile;
