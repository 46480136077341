/** @format */

import {
  Alert,
  Box,
  FormControlLabel,
  InputBase,
  MenuItem,
  Select,
  Radio,
  RadioGroup,
  Typography,
  InputLabel,
} from "@mui/material";
import { useFormik } from "formik";
import ImageUpload from "../imageUploader";
import Input from "../input";
import Button from "../button";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { singup } from "../../redux/auth/auth.actions";
import { Form, FormFeedback } from "reactstrap";
import CircularProgress from "@mui/material/CircularProgress";
import * as Yup from "yup";
import ConnectWalletComponent from "../walletAddress";
import SignupProgressBar from "../signupBar";
import { singupResetState } from "../../redux/auth/auth.slicer";
import { useAlert } from "react-alert";
import { username as CheckUserName } from "../../redux/wishlist/wishlist.action";
import { resetUsername } from "../../redux/wishlist/wishlist.slice";
import { useAddress } from "@thirdweb-dev/react";

const SignupForm = ({
  handleNext,
  button,
  handleBack,
  handleClose,
  activeStep,
}) => {
  const [countries, setCountries] = useState([]);
  const {
    isSignupLoading,
    isSignupSuccess,
    isSignupLoadingFailed,
    signupError,
  } = useSelector((state) => state.auth);
  const walletAddress = useAddress();
  const dispatch = useDispatch();
  const alert = useAlert();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: "",
      gender: "",
      email: "",
      country: "",
      govermentID: "",
      govermentIDPicture: "",
      profilePicture: "",
      coverPicture: "",
      type: button,
      dateOfBirth: "",
      wallet: walletAddress,
      fullName: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const fd = new FormData();
        fd.append("username", values.username);
        fd.append("gender", values.gender);
        fd.append("email", values.email);
        fd.append("country", values.country);
        fd.append("govermentID", values.govermentID);
        fd.append("govermentIDPicture", values.govermentIDPicture);
        fd.append("profilePicture", values.profilePicture);
        fd.append("coverPicture", values.coverPicture);
        fd.append("type", values.type);
        fd.append("wallet", values.wallet);
        fd.append("dateOfBirth", values.dateOfBirth);
        fd.append("fullName", values.fullName);

        dispatch(singup(fd));

        resetForm();
      } catch (error) {
        console.error("signup error", error);
      }
    },
  });

  console.log(formik.values);
  const handleResetState = () => {
    handleClose();
    dispatch(singupResetState());
  };

  useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });

    if (isSignupSuccess) {
      handleNext();
    }
  }, []);
  if (isSignupLoadingFailed) {
    alert.show(signupError.message, {
      timeout: 5000, // custom timeout just for this one alert
      type: "error",
    });
    dispatch(singupResetState());
  }
  const datePickerStyles = {
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "8px",
    width: "200px",
    fontSize: "14px",
    color: "#333",
  };
  useEffect(() => {
    if (formik.values.username) {
      dispatch(CheckUserName(formik.values.username));
    }
  }, [dispatch, formik.values.username]);
  const { isUsernameSuccess } = useSelector((state) => state.helpersSlicer);
  return (
    <>
      {isSignupSuccess ? (
        <Box sx={{ height: "340px", textAlign: "center" }}>
          <Box>
            <SignupProgressBar activeStep={4} />
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: "bold",

                pt: 9,
              }}>
              You have registered as an investor. Thank you for joining us at
              LumiPlace.{" "}
            </Typography>
            <span
              style={{
                color: "red",
                fontSize: "12px",
                fontWeight: "lighter",
              }}>
              {" "}
              Please Check Your Email for verification
            </span>
          </Box>
          <Box sx={{ pt: 13 }}>
            <Button
              onClick={handleResetState}
              sx={{
                backgroundColor: "#34C77B !important",
                textTransform: "capitalize",
                color: "#fff !important",
              }}>
              Close
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            height: "340px",
            overflowY: "auto",
            paddingRight: "4px",
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-track": {
              background: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#DEE2E6",
              borderRadius: "3px",
            },
          }}>
          <Form onSubmit={formik.handleSubmit}>
            <Box pb={5}>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                  pb: 1,
                }}>
                Let's Create your account
              </Typography>
              <SignupProgressBar activeStep={2} />
            </Box>
            <Box>
              <ConnectWalletComponent formik={formik} />
              {formik.touched.wallet && formik.errors.wallet ? (
                <FormFeedback
                  type="invalid"
                  style={{
                    fontSize: "10px",
                    color: "red",
                    textAlign: "center",
                  }}>
                  {formik.errors.wallet}
                </FormFeedback>
              ) : null}
            </Box>
            <Box sx={{ width: "100%" }}>
              <label
                style={{ color: "#8f979e", fontSize: "14px", paddingLeft: 10 }}>
                Username
              </label>
              <Box py={0.5} sx={{ width: "100%" }}>
                <Input
                  sx={{ width: "100%" }}
                  name="username"
                  type="username"
                  className="form-control pe-5"
                  placeholder="Username"
                  onChange={formik.handleChange}
                  invalid={
                    formik.touched.share && formik.errors.share ? true : false
                  }
                />
                {formik.touched.username && formik.errors.username ? (
                  <FormFeedback
                    type="invalid"
                    style={{
                      fontSize: "10px",
                      color: "red",
                      textAlign: "center",
                    }}>
                    {formik.errors.username}
                  </FormFeedback>
                ) : null}
                {formik.values.username ? (
                  <span
                    style={{
                      color: isUsernameSuccess === true ? "green" : "red",
                      fontSize: "12px",
                      textAlign: "center",
                    }}>
                    {isUsernameSuccess === true
                      ? "Username avilable....."
                      : "Username already in use"}
                  </span>
                ) : (
                  ""
                )}
              </Box>
            </Box>
            <Box sx={{ width: "100%" }}>
              <label
                style={{ color: "#8f979e", fontSize: "14px", paddingLeft: 10 }}>
                Full Name
              </label>
              <Box py={0.5} sx={{ width: "100%" }}>
                <Input
                  name="fullName"
                  type="fullName"
                  className="form-control pe-5"
                  placeholder="Full name"
                  onChange={formik.handleChange}
                  value={formik.values.fullName}
                  invalid={
                    formik.touched.share && formik.errors.share ? true : false
                  }
                  sx={{ width: "100%" }}
                />
                {formik.touched.fullName && formik.errors.fullName ? (
                  <FormFeedback
                    type="invalid"
                    style={{ fontSize: "10px", color: "red" }}>
                    {formik.errors.fullName}
                  </FormFeedback>
                ) : null}
              </Box>
            </Box>
            <Box py={0.5} sx={{ width: "100%" }}>
              <label
                style={{ color: "#8f979e", fontSize: "14px", paddingLeft: 10 }}>
                Gender
              </label>

              <RadioGroup
                name="gender"
                displayEmpty
                value={formik.values.gender || ""}
                inputProps={{ "aria-label": "Without label" }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={
                  formik.touched.gender && formik.errors.gender ? true : false
                }
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  justifyItems: "center",
                }}>
                <FormControlLabel
                  value="female"
                  sx={{
                    "& .MuiRadio-root": {
                      color: "#000 !important",
                    },
                  }}
                  control={
                    <Radio
                      sx={{
                        "& .Mui-checked": {
                          color: "#000 !important",
                        },
                      }}
                      color="secondary"
                    />
                  }
                  label="Female"
                />
                <FormControlLabel
                  value="male"
                  control={<Radio />}
                  label="Male"
                  sx={{
                    "& .MuiRadio-root": {
                      color: "#000 !important",
                    },
                  }}
                />
                <FormControlLabel
                  value="other"
                  control={<Radio />}
                  label="Other"
                  sx={{
                    "& .MuiRadio-root": {
                      color: "#000 !important",
                    },
                  }}
                />
              </RadioGroup>
            </Box>
            <Box py={0.5} sx={{ width: "100%" }}>
              <label
                style={{
                  color: "#8f979e",
                  fontSize: "14px",
                  paddingLeft: 10,
                }}>
                Date of Birth
              </label>
              <Box py={0.5} sx={{ width: "100%" }}>
                <Input
                  name="dateOfBirth"
                  type="date"
                  className="form-control pe-5"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={
                    formik.touched.share && formik.errors.share ? true : false
                  }
                />
              </Box>
            </Box>
            <Box py={0.5} sx={{ width: "100%" }}>
              <label
                style={{
                  color: "#8f979e",
                  fontSize: "14px",
                  paddingLeft: 10,
                }}>
                Email
              </label>
              <Box py={0.5} sx={{ width: "100%" }}>
                <Input
                  sx={{ width: "100%" }}
                  name="email"
                  type="email"
                  className="form-control pe-5"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={
                    formik.touched.share && formik.errors.share ? true : false
                  }
                />

                {formik.touched.email && formik.errors.email ? (
                  <FormFeedback
                    type="invalid"
                    style={{
                      fontSize: "10px",
                      color: "red",
                      textAlign: "center",
                    }}>
                    {formik.errors.email}
                  </FormFeedback>
                ) : null}
              </Box>
            </Box>
            <Box py={0.5} sx={{ width: "100%" }}>
              <label
                style={{
                  color: "#8f979e",
                  fontSize: "14px",
                  paddingLeft: 10,
                }}>
                Verify Email
              </label>
              <Box py={0.5} sx={{ width: "100%" }}>
                <Input
                  sx={{ width: "100%" }}
                  name="verifyEmail"
                  type="verifyEmail"
                  className="form-control pe-5"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={
                    formik.touched.share && formik.errors.share ? true : false
                  }
                />

                {formik.touched.verifyEmail && formik.errors.verifyEmail ? (
                  <FormFeedback
                    type="invalid"
                    style={{
                      fontSize: "10px",
                      color: "red",
                      textAlign: "center",
                    }}>
                    {formik.errors.verifyEmail}
                  </FormFeedback>
                ) : null}
              </Box>
            </Box>

            <Box py={0.5} sx={{ width: "100%" }}>
              <label
                style={{ color: "#8f979e", fontSize: "14px", paddingLeft: 10 }}>
                Country
              </label>

              <Select
                sx={{ width: "100%", border: "1px solid lightgrey" }}
                name="country"
                onChange={formik.handleChange}
                label="Country">
                {countries.map((data) => {
                  return <MenuItem value={data.value}>{data.label}</MenuItem>;
                })}
              </Select>
              {formik.touched.country && formik.errors.country ? (
                <FormFeedback
                  type="invalid"
                  style={{
                    fontSize: "10px",
                    color: "red",
                    textAlign: "center",
                  }}>
                  {formik.errors.country}
                </FormFeedback>
              ) : null}
            </Box>
            <Box py={0.5} sx={{ width: "100%" }}>
              <label
                style={{ color: "#8f979e", fontSize: "14px", paddingLeft: 10 }}>
                Goverment ID Number
              </label>
              <Box py={0.5} sx={{ width: "100%" }}>
                <InputBase
                  name="govermentID"
                  type="govermentID"
                  className="form-control pe-5"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={
                    formik.touched.govermentID && formik.errors.govermentID
                      ? true
                      : false
                  }
                  sx={{
                    background:
                      "#FAFCFF 0% 0% no-repeat padding-box !important",
                    border: "1px solid #E5E8EB",
                    borderRadius: "8px",
                    opacity: 1,
                    width: "100%",
                    padding: "8px",
                  }}
                />
                {formik.touched.govermentID && formik.errors.govermentID ? (
                  <FormFeedback
                    type="invalid"
                    style={{
                      fontSize: "10px",
                      color: "red",
                      textAlign: "center",
                    }}>
                    {formik.errors.govermentID}
                  </FormFeedback>
                ) : null}
              </Box>
            </Box>
            <Box py={0.5} sx={{ width: "100%" }}>
              <label
                style={{ color: "#8f979e", fontSize: "14px", paddingLeft: 10 }}>
                Goverment ID Picture
              </label>
              <Box py={0.5} sx={{ width: "100%" }}>
                <ImageUpload formik={formik} value="govermentIDPicture" />
              </Box>
            </Box>
            <Box py={0.5} sx={{ width: "100%" }}>
              <label
                style={{ color: "#8f979e", fontSize: "14px", paddingLeft: 10 }}>
                Profile Picture
              </label>
              <Box py={0.5} sx={{ width: "100%" }}>
                <ImageUpload formik={formik} value="profilePicture" />
              </Box>
            </Box>
            <Box py={0.5} sx={{ width: "100%" }}>
              <label
                style={{ color: "#8f979e", fontSize: "14px", paddingLeft: 10 }}>
                Cover Picture
              </label>
              <Box py={0.5} sx={{ width: "100%" }}>
                <ImageUpload formik={formik} value="coverPicture" />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "10px",
                color: "red",
              }}>
              {Object.keys(formik.errors).length > 0 &&
                "Please fill the required Fields"}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}>
              {isSignupLoading ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                <>
                  {button === "creator" ? (
                    walletAddress && (
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: ".5fr 3fr",
                          justifyContent: "space-between",
                          gap: 1,
                        }}>
                        <Button
                          onClick={() => {
                            handleBack();
                          }}
                          sx={{
                            backgroundColor: "grey !important",
                            textTransform: "capitalize",
                            color: "#fff !important",
                          }}>
                          Back
                        </Button>
                      </Box>
                    )
                  ) : (
                    <Button
                      type="submit"
                      sx={{
                        backgroundColor: "#34C77B !important",
                        textTransform: "capitalize",
                        color: "#fff !important",
                      }}>
                      Sign Up
                    </Button>
                  )}
                </>
              )}
            </Box>
          </Form>
        </Box>
      )}
    </>
  );
};

export default SignupForm;
const validationSchema = Yup.object().shape({
  username: Yup.string().required("Username is required"),
  gender: Yup.string().required("Gender is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),

  country: Yup.string().required("Country is required"),
  govermentID: Yup.string().required("Government ID is required"),
  govermentIDPicture: Yup.mixed().required("Government ID Picture is required"),
  type: Yup.string().required("User type is required"),
  verifyEmail: Yup.string()
    .oneOf([Yup.ref("email"), null], "Emails must match") // Ensure verifyEmail matches email
    .required("Verify Email is required"),
  wallet: Yup.string().required("Add your wallet address"),
});
