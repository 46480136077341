/** @format */

import React from "react";
import { Avatar as MuiAvatar } from "@mui/material";

const AvatarComponent = ({ url }) => (
  <MuiAvatar
    src={url}
    alt="Avatar"
    style={{
      verticalAlign: "middle",
      width: "20px",
      height: "20px",
      borderRadius: "50%",
      marginLeft: "10px",
    }}
  />
);

export default AvatarComponent;
