/** @format */

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Avatar, Box, Paper, Skeleton, Typography } from "@mui/material";
import { PROFILE_BASE_URL } from "../../http/config";
import { makeStyles } from "@mui/styles";

const SearchPlace = ({ handleClose }) => {
  const classes = useStyle();
  const navigate = useNavigate();
  const { searchData, isSearchParamsLoading, isSearchParamsSuccess } =
    useSelector((state) => state?.helpersSlicer);
  console.log(searchData);
  return (
    <Paper marginTop={2} elevation={5} className={classes.paperContainer}>
      {!isSearchParamsSuccess ? (
        ""
      ) : (
        <>
          {searchData?.nfts?.length > 0 && (
            <div>
              <h3 style={{ padding: "5px 0px" }}>NFTs</h3>
              <Box style={{ display: "flex", flexDirection: "column" }}>
                {searchData?.nfts.map(
                  ({ nft_id, title, image, tokenId, amountId, creatorImg }) => (
                    <Box
                      key={nft_id}
                      className={classes.nftContainer}
                      onClick={(e) => {
                        handleClose();
                        navigate(`/farmprofile/${tokenId}/${amountId}`);
                      }}>
                      <Box margin={1}>
                        {isSearchParamsLoading ? (
                          <Skeleton variant="square">
                            <Avatar />
                          </Skeleton>
                        ) : (
                          <Avatar
                            src={`https://lumiplace.infura-ipfs.io/ipfs/${
                              image.split("//")[1]
                            }`}
                          />
                        )}
                      </Box>
                      <Box width="100%">
                        {isSearchParamsLoading ? (
                          <Skeleton width="100%">
                            <Typography>.</Typography>
                          </Skeleton>
                        ) : (
                          <Typography className={classes.title}>
                            {title}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  )
                )}
              </Box>
            </div>
          )}
          {searchData?.investors?.length > 0 && (
            <div>
              <h3 style={{ padding: "5px 0px" }}>Investors</h3>
              <Box style={{ display: "flex", flexDirection: "column" }}>
                {searchData?.investors.map(
                  ({ nft_id, username, img, tokenId, type, creatorImg }) => (
                    <Box
                      key={nft_id}
                      className={classes.nftContainer}
                      onClick={() => {
                        handleClose();
                        navigate(`/${type}/${username}`);
                      }}>
                      <Box margin={1}>
                        {isSearchParamsLoading ? (
                          <Skeleton variant="square">
                            <Avatar />
                          </Skeleton>
                        ) : (
                          <Avatar src={`${PROFILE_BASE_URL}/${img}`} />
                        )}
                      </Box>
                      <Box width="100%">
                        {isSearchParamsLoading ? (
                          <Skeleton width="100%">
                            <Typography>.</Typography>
                          </Skeleton>
                        ) : (
                          <Typography className={classes.title}>
                            {username}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  )
                )}
              </Box>
            </div>
          )}
          {searchData?.creators?.length > 0 && (
            <div>
              <h3 style={{ padding: "5px 0px" }}>Creators</h3>
              <Box style={{ display: "flex", flexDirection: "column" }}>
                {searchData?.creators.map(
                  ({ nft_id, username, img, tokenId, type, creatorImg }) => (
                    <Box
                      key={nft_id}
                      className={classes.nftContainer}
                      onClick={() => {
                        handleClose();
                        navigate(`/${type}/${username}`);
                      }}>
                      <Box margin={1}>
                        {isSearchParamsLoading ? (
                          <Skeleton variant="square">
                            <Avatar />
                          </Skeleton>
                        ) : (
                          <Avatar src={`${PROFILE_BASE_URL}/${img}`} />
                        )}
                      </Box>
                      <Box width="100%">
                        {isSearchParamsLoading ? (
                          <Skeleton width="100%">
                            <Typography>.</Typography>
                          </Skeleton>
                        ) : (
                          <Typography className={classes.title}>
                            {username}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  )
                )}
              </Box>
            </div>
          )}
          {searchData?.nfts?.length === 0 &&
            searchData?.investors?.length === 0 &&
            searchData?.creators?.length === 0 && (
              <div>
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <Typography>No Result Found!</Typography>
                </Box>
              </div>
            )}
        </>
      )}
    </Paper>
  );
};

export default SearchPlace;
const useStyle = makeStyles((theme) => ({
  title: {
    fontSize: "18px",
    fontWeight: "bold",
  },
  paperContainer: {
    minWidth: "400px",
    maxHeight: "250px",
    overflow: "auto",
    padding: "10px",
  },
  creatorContainer: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  nftContainer: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    width: "100%",
  },
}));
