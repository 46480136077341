/** @format */

import { lumiShare } from "../../http/config";

export const storiesApi = async (payload) => {
  try {
    const res = await lumiShare.get("/app.v1/api/getStories", payload);
    return res;
  } catch (error) {
    throw error;
  }
};

export const storiesPostApi = async (payload) => {
  try {
    const res = await lumiShare.post("/app.v1/api/addStory", payload);
    return res;
  } catch (error) {
    throw error;
  }
};
