/** @format */

import React, { useState, useEffect } from "react";
import { Box, IconButton, Skeleton, } from "@mui/material";

import "stories-react/dist/index.css";
import StoryHeaderComponent from "../storiesHeader";
import { LuImagePlus } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import { storiesPostData } from "../../redux/stories/stories.action";
import CustomToaster from "../toaster";
import { resetStories } from "../../redux/stories/stories.slicer";
import { PROFILE_BASE_URL } from "../../http/config";

const UserHeader = ({ selectedStory, setOpen }) => {
  const dispatch = useDispatch();
  const {
    user: { creatorID, username, img },
  } = useSelector((state) => state.auth);
  const [existingStories, setExistingStories] = useState([
    {
      id: creatorID,
      type: "image",
      url: PROFILE_BASE_URL + img,
      duration: 15000,
    },
  ]);

  const [newlyUploadedStory, setNewlyUploadedStory] = useState(null);
  const { isStoriesPostSuccess } = useSelector((state) => state.storiesSlice);
  const handleChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        setNewlyUploadedStory({
          id: 1, 
          type: "image",
          url: reader.result,
          duration: 15000,
        });
      };

      reader.readAsDataURL(file);
    }
    const fd = new FormData();
    fd.append("creatorId", creatorID);
    fd.append("story", file);
    dispatch(storiesPostData(fd));
  };

  useEffect(() => {
    if (isStoriesPostSuccess) {
      setTimeout(() => {
        setOpen(false);
        dispatch(resetStories());
      }, 2000);
    }
  }, [isStoriesPostSuccess, setOpen, dispatch]);

  const storiesData = [...existingStories];
  if (newlyUploadedStory) {
    storiesData.push(newlyUploadedStory);
  }

  return (
    <>
      {isStoriesPostSuccess && (
        <CustomToaster>Story Uploaded Successfully</CustomToaster>
      )}
      <Box display="flex" justifyContent="center" width="100%">
        {selectedStory && (
          <StoryHeaderComponent
            name={username}
            url={PROFILE_BASE_URL + img}
            time=""
          />
        )}
        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          id="uploadProfileInput"
          onChange={handleChange}
        />
        <Box
          sx={{ border: "1px dotted white", borderRadius: "10px" }}
          width="400px"
          height="89vh">
          {newlyUploadedStory ? (
            <img
              src={newlyUploadedStory.url}
              alt="Preview"
              style={{ width: "100%", height: "100%", borderRadius: "10px" }}
            />
          ) : (
            <label htmlFor="uploadProfileInput" style={{ cursor: "pointer" }}>
              <Skeleton
                sx={{ bgcolor: "grey.900", height: "100%" }}
                variant="rectangular"
                width={400}
              />
              <IconButton
                sx={{
                  position: "fixed",
                  top: "45%",
                  left: "46%",
                  zIndex: 1000,
                }}>
                <LuImagePlus
                  style={{
                    color: "white",
                    fontWeight: "15px",
                    fontSize: "80px",
                  }}
                />
              </IconButton>
            </label>
          )}
        </Box>
      </Box>
    </>
  );
};

export default UserHeader;
