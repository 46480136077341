/** @format */

import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./styles.css";
import Stories from "../stories/index";
import { AiFillCloseCircle } from "react-icons/ai";
import HeaderStory from "../headerStories";

const StoryModal = () => {
  const [open, setOpen] = React.useState(false);
  const [selectedStory, setSelectedStory] = React.useState(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedStory(null);
  };

  const handleStoryClick = (storyId, username, creatorImg, storyContent) => {
    setSelectedStory({
      storyId,
      username,
      creatorImg,
      storyContent,
    });
    handleOpen();
  };

  return (
    <Box>
      <Stories handleStoryClick={handleStoryClick} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <>
          <Box
            sx={{
              color: "white",
              position: "absolute",
              right: { md: 45, xs: 10 },
              top: { md: 45, xs: 50 },
              cursor: "pointer",
              zIndex: "9",
            }}>
            <AiFillCloseCircle size={30} onClick={handleClose} />
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { md: "100%", xs: "100%" },
              bgcolor: { md: "#000", xs: "transparent" },
              height: "100%",
              border: "2px solid #000",
              boxShadow: 24,
              paddingTop: "5rem",
            }}>
            <HeaderStory selectedStory={selectedStory} />
          </Box>
        </>
      </Modal>
    </Box>
  );
};

export default StoryModal;
