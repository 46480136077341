/** @format */

import { lumiShare } from "../../http/config";

export const singleCreatorApi = async (payload) => {
  try {
    let query = "";
    if (payload.wallet) {
      query = `?wallet=${payload.wallet}`;
    }
    const response = await lumiShare.get(
      `/app.v1/api/singleCreator/${payload.user + query}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

//LeaderBoard

export const leaderBoardApi = async (payload) => {
  try {
    const response = await lumiShare.get("/app.v1/api/leadersboard");
    return response;
  } catch (error) {
    throw error;
  }
};
