/** @format */

const data = [
  {
    name: "10:00",
    pv1: 110,
    pv2: 220,
    pv3: 100,
  },
  {
    name: "11:00",
    pv1: 200,
    pv2: 100,
    pv3: 200,
  },
  {
    name: "12:00",
    pv1: 200,
    pv2: 300,
    pv3: 100,
  },
  {
    name: "13:00",
    pv1: 110,
    pv2: 220,
    pv3: 100,
  },
  {
    name: "14:00",
    pv1: 100,
    pv2: 100,
    pv3: 200,
  },
  {
    name: "15:00",
    pv1: 50,
    pv2: 450,
    pv3: 100,
  },
  {
    name: "16:00",
    pv1: 300,
    pv2: 100,
    pv3: 150,
  },
  {
    name: "17:00",
    pv1: 100,
    pv2: 250,
    pv3: 110,
  },
  {
    name: "18:00",
    pv1: 150,
    pv2: 250,
    pv3: 110,
  },
  {
    name: "19:00",
    pv1: 200,
    pv2: 250,
    pv3: 110,
  },
  {
    name: "20:00",
    pv1: 100,
    pv2: 250,
    pv3: 110,
  },
  {
    name: "21:00",
    pv1: 200,
    pv2: 50,
    pv3: 150,
  },
];
export default data;
