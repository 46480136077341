/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { editCover, editInfo, editProfile } from "./editProfile.action";

const initialState = {
  isEditProfileLoading: false,
  isEditProfileSuccess: false,
  isEditProfileFailed: false,
  //////////////////////////////
  isEditCoverLoading: false,
  isEditCoverSuccess: false,
  isEditCoverFailed: false,
  /////////////////////////////
  isEditInfoLoading: false,
  isEditInfoSuccess: false,
  isEditInfoFailed: false,
  editProfile: {},
};

const editProfileSlice = createSlice({
  name: "edit",
  initialState: initialState,
  reducers: {
    resetStateEdit: (state) => {
      state.isEditProfileSuccess = false;
      state.isEditCoverSuccess = false;
      state.isEditInfoSuccess = false;
      state.isEditProfileFailed = false;
      state.isEditCoverFailed = false;
      state.isEditInfoFailed = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(editProfile.pending, (state) => {
      state.isEditProfileLoading = true;
    });
    builder.addCase(editProfile.fulfilled, (state, action) => {
      state.isEditProfileLoading = false;
      state.editProfile = action.payload;
      state.isEditProfileSuccess = true;
    });
    builder.addCase(editProfile.rejected, (state) => {
      state.isEditProfileLoading = false;
      state.isEditProfileFailed = true;
      state.isEditProfileSuccess = false;
    });
    ////////////////////////////////////////
    builder.addCase(editCover.pending, (state) => {
      state.isEditCoverLoading = true;
    });
    builder.addCase(editCover.fulfilled, (state, action) => {
      state.isEditCoverLoading = false;
      state.editProfile = action.payload;
      state.isEditCoverSuccess = true;
    });
    builder.addCase(editCover.rejected, (state) => {
      state.isEditCoverLoading = false;
      state.isEditCoverSuccess = false;
      state.isEditCoverFailed = true;
    });
    ////////////////////////////////////////////////
    builder.addCase(editInfo.pending, (state) => {
      state.isEditInfoLoading = true;
    });
    builder.addCase(editInfo.fulfilled, (state, action) => {
      state.isEditInfoLoading = false;
      state.editProfile = action.payload;
      state.isEditInfoSuccess = true;
    });
    builder.addCase(editInfo.rejected, (state) => {
      state.isEditInfoLoading = false;
      state.isEditInfoFailed = true;
      state.isEditInfoSuccess = false;
    });
  },
});

export const { resetStateEdit } = editProfileSlice.actions;

export default editProfileSlice.reducer;
