/** @format */

import React, { Component, useEffect } from "react";
import "./style.css";
import ChatList from "../../components/chatList";
import ChatContent from "../../components/chatContent";
import UserProfile from "../../components/userProfile";
import { Box, CircularProgress, Paper, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getChatUsers } from "../../redux/chat/chats.action";
import { useNavigate, useLocation } from "react-router-dom";
const Chat = () => {
  const navigate = useNavigate();
  const {
    ischatUsersLoading,
    chatUsers,
    chatInfo,
    chatMessages,
    ischatInfoLoading,
  } = useSelector((state) => state.chat);
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const messageUser = queryParams.get("user");
  if (!isAuthenticated) {
    navigate("/");
  }

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getChatUsers(user.walletAddress));
  }, [user.walletAddress, dispatch]);

  return (
    <Box className="main__chatbody" sx={{ paddingBottom: "8vh" }}>
      <Paper
        className="messages"
        sx={{ width: "18vw", borderRadius: "10px", height: "72vh" }}>
        <Box sx={{ borderBottom: "1px solid lightgrey" }}>
          <h4 align="center">Messages</h4>
        </Box>
        {ischatUsersLoading ? (
          <Box>
            <CircularProgress />
          </Box>
        ) : (
          <ChatList
            listData={chatUsers}
            user={user.walletAddress}
            chat={messageUser}
          />
        )}
      </Paper>

      <ChatContent
        data={chatMessages}
        user={user}
        sender={chatInfo}
        loading={ischatInfoLoading}
      />
      {/* <UserProfile {...chatInfo} loading={ischatInfoLoading} /> */}
    </Box>
  );
};
export default Chat;
