/** @format */

import { lumiShare } from "../../http/config";

export const editProfileApi = async (payload) => {
  try {
    const res = await lumiShare.post(
      "/app.v1/api/uploadProfilePicture",
      payload
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const editCoverApi = async (payload) => {
  try {
    const res = await lumiShare.post("/app.v1/api/uploadCoverPicture", payload);
    return res;
  } catch (error) {
    throw error;
  }
};

export const editProfileDetailsApi = async (payload) => {
  try {
    const res = await lumiShare.post("/app.v1/api/updateInfo", payload);
    return res;
  } catch (error) {
    throw error;
  }
};
