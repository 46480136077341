/** @format */

import React from "react";
import { Box, InputBase } from "@mui/material";
import Stories from "stories-react";
import "stories-react/dist/index.css";
import StoryHeaderComponent from "../storiesHeader";
import TelegramIcon from "@mui/icons-material/Telegram";
import { PROFILE_BASE_URL, STORIES_BASE_URL } from "../../http/config";
const HeaderStory = ({ selectedStory }) => {
  const image = PROFILE_BASE_URL + selectedStory.creatorImg;
  const storiesData = selectedStory.storyContent.map((story) => {
    let storyimg = STORIES_BASE_URL + story.content;
    return {
      id: selectedStory.creatorId,
      type: "image",
      url: storyimg,
      duration: 15000,
    };
  });
  return (
    <>
      <Box display="flex" justifyContent="center" width="100%" height="100vh">
        {selectedStory && (
          <StoryHeaderComponent
            name={selectedStory.username}
            url={image}
            time="1h"
          />
        )}
        <Stories
          width="400px"
          height="87vh"
          stories={storiesData.filter(
            (story) => story.id === selectedStory.creatorId
          )}
        />
        <Box sx={{ position: "fixed", top: "89%", right: "38%", zIndex: 1 }}>
          <InputBase
            placeholder="Send Message"
            type="text"
            sx={{
              color: "white",
              border: "1px solid white",
              backgroundColor: "transparent",
              borderRadius: "50px",
              padding: "5px 20px 5px 20px",
              width: "360px",
            }}
          />
        </Box>
        <Box
          sx={{
            position: "fixed",
            top: "90%",
            right: "35.5%",
            color: "white",
            cursor: "pointer",
            zIndex: 1,
          }}>
          <TelegramIcon sx={{ cursor: "pointer" }} />
        </Box>
      </Box>
    </>
  );
};

export default HeaderStory;
