import { Typography,Box } from "@mui/material";
const FooterLinks = ({heading}) =>{
    const typographyItems = [
        "Stories",
        "Top 10",
        "Trending",
        "Videos",
        "Based on your interest",
        "Recommended for you",
        "Articles"
      ];
    return(
        <>  <Typography variant='h3'>
                 {heading}
            </Typography>
            <Box  sx={{display:'flex',flexDirection:'column',gap:'3px'}}>
                {typographyItems.map((item, index) => (
                    <Typography key={index} variant='body2' sx={{cursor:'pointer',width:'fit-content'}}>
                     {item}
                    </Typography>
                ))}
            </Box>
        </>
    )
}
export default FooterLinks;