/** @format */

import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import PicChart from "../../components/portfoliochart";
import BasicCard from "../../components/portfoliosumary";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleArts } from "../../redux/arts/arts.action";
import { resetStateBuy } from "../../redux/arts/arts.slicer";
import CustomToaster from "../../components/toaster";
import { clearLogs } from "../../redux/arts/logs.slicer";

import Notifications from "../../components/Notifications";

function Portfolio() {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const { isBuyArtsLoadingSuccess, isBuyArtsLoadingFailed, isBuyArtsLoading } =
    useSelector((state) => state.artsSlicer);
  const { messages } = useSelector((state) => state.logsSlice);

  const { tokenID, amountId } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    let payload = {
      tokenID,
      amountId,
    };
    dispatch(getSingleArts(payload));
  }, [dispatch, tokenID, amountId]);

  setTimeout(() => {
    if (isBuyArtsLoadingSuccess || isBuyArtsLoadingFailed) {
      dispatch(resetStateBuy());
      dispatch(clearLogs());
    }
  }, 2000);
  return (
    <Box mb={"6rem"}>
      {(isBuyArtsLoadingSuccess ||
        isBuyArtsLoadingFailed ||
        isBuyArtsLoading) && <Notifications />}

      <Typography
        sx={{ m: "2rem 0" }}
        variant="h1"
        fontSize={"1.5rem"}
        fontWeight={"bold"}>
        Portfolio
      </Typography>
      <Grid
        style={{
          display: "grid",
          gridAutoFlow: isMobileSize ? "row" : "column",
        }}
        spacing={6}>
        <Grid item xs={8}>
          <Grid
            container
            sx={{
              borderBottom: "1px solid lightGrey",
              alignItems: "center",
              pb: "0.2rem",
            }}>
            <Grid
              item
              xs={4}
              sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
              <Checkbox
                {...label}
                size="small"
                color="success"
                disableFocusRipple
                disableRipple
                sx={{
                  padding: 0,
                  "& .MuiSvgIcon-root": {
                    borderRadius: 20,
                  },
                }}
              />
              <Typography variant="body2">Farm</Typography>
            </Grid>
            <Grid item xs={2.25}>
              <Typography variant="body2">Price</Typography>
            </Grid>
          </Grid>
          <Box sx={{ width: "100%" }}>
            <PicChart />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <BasicCard />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Portfolio;
