/** @format */
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import MainButton from "../buttonTwo";
import image1 from "../../assets/logo/light.png";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { buyNFT as NFTBought } from "../../redux/arts/arts.action";
import { resetStateBuy } from "../../redux/arts/arts.slicer";
import MouseOverPopover from "../popover";
import { useState } from "react";
import { useContract } from "@thirdweb-dev/react-core";
import {
  marketplaceContractAddress,
  marketplaceContractType,
} from "../../hooks/thirdwebHooks/contractAddresses";
import { async } from "q";

export default function BasicCard() {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.artsSlicer?.singleArt);
  const { user } = useSelector((state) => state.auth);
  const { isBuyArtsLoadingSuccess } = useSelector((state) => state.artsSlicer);
  const stringValue = data?.orderId;

  const IntOrderId = parseInt(stringValue, 10);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { amountId } = useParams();
  const { contract } = useContract(
    marketplaceContractAddress,
    marketplaceContractType
  );
  const handlebuyNFT = async () => {
    try {
      setLoader(true);
      const txResult = await contract?.directListings.buyFromListing(
        IntOrderId,
        1
      );
      let payload = {
        transferFrom: data?.ownerWallet,
        transferTo: user?.walletAddress,
        amount: data?.fixedprice,
        tokenId: data?.tokenId,
        orderId: IntOrderId,
        transferHash: txResult.receipt.transactionHash,
        amountId: amountId,
      };

      dispatch(NFTBought(payload));
    } catch (err) {
      setLoader(false);

      console.log(err);
    }
  };

  setTimeout(() => {
    if (isBuyArtsLoadingSuccess) {
      navigate(`/${user?.type}/${user?.username}`);
      dispatch(resetStateBuy());
    }
  }, 2000);
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <Paper
      elevation={1}
      variant="outlined"
      sx={{
        p: "10px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}>
      <Typography
        variant="h1"
        mb="0.5rem"
        fontSize={"1.5rem"}
        fontWeight={"bold"}>
        Summary
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}>
        <Typography variant="body2">Sub total</Typography>
        <Typography variant="h3">
          {data?.fixedprice}$
          <img style={{ width: 50 }} src={image1} alt="lumipic" />
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Typography variant="body2">Discount</Typography>
        <Typography variant="body2" sx={{ paddingRight: 5 }}>
          $0
        </Typography>
      </Box>
      <Box sx={{ borderBottom: "1px solid #e9e2e2", width: "100%" }}></Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: "0.5rem",
        }}>
        <Typography variant="h3">Grand Total</Typography>
        <Typography variant="h2">
          {data?.fixedprice}$
          <img
            style={{ width: 50, marginLeft: 3 }}
            src={image1}
            alt="lumipic"
          />
        </Typography>
      </Box>

      <MainButton
        onClick={
          isAuthenticated ||
          !user.type === "investor" ||
          data?.ownerWallet === user.walletAddress
            ? handlebuyNFT
            : null
        }
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        variant="contained"
        sx={{
          width: "100% !important",
          background:
            isAuthenticated ||
            user.type === "creator" ||
            data?.ownerWallet === user.walletAddress
              ? "#34C77B !important"
              : " #9C9C9C   !important",
        }}>
        {loader ? <CircularProgress size={20} /> : " Buy & Invest"}
      </MainButton>

      {user.type === "creator" ? (
        <MouseOverPopover
          handlePopoverOpen={handlePopoverOpen}
          handlePopoverClose={handlePopoverClose}
          anchorEl={anchorEl}>
          Only Investor can buy
        </MouseOverPopover>
      ) : data?.ownerWallet === user.walletAddress ? (
        <MouseOverPopover
          handlePopoverOpen={handlePopoverOpen}
          handlePopoverClose={handlePopoverClose}
          anchorEl={anchorEl}>
          Listed by
        </MouseOverPopover>
      ) : null}

      {!isAuthenticated && (
        <MouseOverPopover
          handlePopoverOpen={handlePopoverOpen}
          handlePopoverClose={handlePopoverClose}
          anchorEl={anchorEl}>
          Sign In First
        </MouseOverPopover>
      )}
    </Paper>
  );
}
