/** @format */

import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addToWishListApi,
  deleteWishListApi,
  getWishListApi,
  reactApi,
  searchParamsApi,
  usernameApi,
} from "./api";

export const addToWishList = createAsyncThunk(
  "helpers/addwishList",
  async (payload) => {
    try {
      const res = await addToWishListApi(payload);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getWishList = createAsyncThunk(
  "helpers/getWishList",
  async (payload) => {
    try {
      const res = await getWishListApi(payload);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);
export const searchParams = createAsyncThunk(
  "helpers/searchParams",
  async (payload) => {
    try {
      const res = await searchParamsApi(payload);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);
export const deleteWishList = createAsyncThunk(
  "helpers/deleteWishList",
  async (payload) => {
    try {
      const res = await deleteWishListApi(payload);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);

export const react = createAsyncThunk("helpers/react", async (payload) => {
  try {
    const res = await reactApi(payload);
    return res.data;
  } catch (error) {
    throw error;
  }
});
export const username = createAsyncThunk(
  "helpers/username",
  async (payload) => {
    try {
      const res = await usernameApi(payload);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);
