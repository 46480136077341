/** @format */

import { Box, CircularProgress } from "@mui/material";
import React from "react";
import Alert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import "./style.css";
import { useSelector } from "react-redux";
const SuccessAlert = ({ message, loading, success, error }) => {
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: "5%",
        padding: "10px 40px 10px 10px",
        zIndex: 1,
        borderRadius: "5px",
        right: "-100%",
        width: "20%",
        zIndex: 1000,
      }}
      className="animated-toaster">
      <Alert
        sx={{
          color: success ? "green" : error ? "red" : "white",
          fontSize: "14px",
          display: "flex",
          alignItems: "center",
          backgroundColor: "black",
          opacity: 1,
        }}
        icon={
          loading ? (
            <CircularProgress
              size={20}
              sx={{ color: "#34C77B", fontWeight: "bold" }}
            />
          ) : success ? (
            <CheckIcon />
          ) : error ? (
            <ErrorIcon sx={{ color: "red" }} />
          ) : null
        }>
        {loading ? "In Progress" : message}
      </Alert>
    </Box>
  );
};

export default SuccessAlert;
export const FailuerAlert = ({ children, startIcon }) => {
  const {
    isCreatorSingupFailed,
    isCreatorSingupSuccess,
    isCreatorSingupLoading,
  } = useSelector((state) => state.auth);
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: "5%",
        padding: "10px 40px 10px 10px",
        zIndex: 1,
        borderRadius: "5px",
        right: "-100%",
      }}
      className="animated-toaster">
      <Alert
        sx={{
          color: "white",
          fontSize: "14px",
          display: "flex",
          alignItems: "center",
          backgroundColor: "black",
          opacity: 1,
        }}
        variant="outlined"
        icon={
          isCreatorSingupLoading ? (
            <CircularProgress
              size={20}
              sx={{ color: "#34C77B", fontWeight: "bold" }}
            />
          ) : isCreatorSingupSuccess ? (
            <CheckIcon />
          ) : null
        }>
        {children}
      </Alert>
    </Box>
  );
};
