/** @format */

import { Box, Rating, Typography } from '@mui/material';
import './style.css';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import CustomAvatar from '../avatar';
import Button from '../button';
import LinearDeterminate from '../progressBar';
import { PROFILE_BASE_URL } from '../../http/config';

import { useState } from 'react';
import SharePopOver from '../shareButton';
import PopupModal from '../popupSale';
const CollectedCards = ({
  image,
  username,
  title,
  creatorImg,
  created_at,
  onSale,
  tokenId,
  ownerWallet,
  ownerId,
  amountId,
  fixedprice,
  isRated,
  apy,
}) => {
  const isIPFSImg = /ipfs:\/\//.test(image),
    imgSrc = isIPFSImg
      ? `https://lumiplace.infura-ipfs.io/ipfs/${image.split('//')[1]}`
      : image;
  const { walletAddress, type } = useSelector((state) => state.auth.user);

  const originalDate = new Date(created_at);
  const month = originalDate.getMonth() + 1;
  const day = originalDate.getDate();
  const year = originalDate.getFullYear();

  const formattedDate = `${month}/${day}/${year}`;
  const [isOpen, setIsOpen] = useState(false);
  const [isRateOpen, setIsRateOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const openRating = () => {
    setIsRateOpen(true);
  };
  const closeRateModal = () => {
    setIsRateOpen(false);
  };
  return (
    <Box
      sx={{
        width: '20vw !important',
        marginRight: '20px',
        borderRadius: '20px',
        boxShadow: '0px 5px 15px #00000014',
        textDecoration: 'none',
        marginY: '15px',
      }}
    >
      {' '}
      <Box
        sx={{
          position: 'relative',
          boxSizing: 'border-box',
        }}
      >
        <Link to={`/farmprofile/${tokenId}/${amountId}`}>
          <img
            style={{
              width: '100%',
              height: '200px',
              borderRadius: '20px 20px 0px 0px',
              backgroundRepeat: 'no-repeat',
              objectFit: 'cover',
            }}
            src={imgSrc}
            alt=""
          />
        </Link>
        <Box>
          <SharePopOver shareUrl={`/farmprofile/${tokenId}/${amountId}`} />
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            position: 'absolute',
            bottom: '0px',
            padding: '10px 10px',
            boxSizing: 'border-box',
            background:
              'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5))',
          }}
        >
          <Box>
            <Typography
              variant="h2"
              sx={{ color: 'white', textTransform: 'capitalize' }}
            >
              {title}
            </Typography>
          </Box>
          <Box>
            <Rating
              precision={1}
              value={3}
              size="small"
              sx={{ fontSize: '12px' }}
            />
          </Box>
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'space-between',
            padding: '10px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
              width: 'fit-content',
            }}
          >
            <Box>
              <CustomAvatar
                src={PROFILE_BASE_URL + creatorImg}
                width="30px"
                height="30px"
              />
            </Box>
            <Box>
              <Typography
                variant="h4"
                sx={{
                  textDecoration: 'none',
                  color: '#000 !important',
                  textTransform: 'capitalize',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                {username}
              </Typography>
              <Typography variant="body1" sx={{ color: 'black' }}>
                {formattedDate}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Rating
              precision={1}
              value={3}
              size="small"
              sx={{ fontSize: '12px' }}
            />
            <Typography
              variant="body1"
              sx={{ color: 'white', textAlign: 'end' }}
            >
              {3}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            padding: '0px 10px 10px 10px',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <Typography variant="body1">Price</Typography>
          <Typography variant="body1">{fixedprice}</Typography>
        </Box>
      </Box>
      <Box sx={{ padding: '0 10px' }}>
        <Box
          sx={{ display: 'flex', gap: '5px', justifyContent: 'space-between' }}
        >
          <Typography variant="body">The Offered Yield (APY)</Typography>
          <Typography variant="body">{apy}%</Typography>
        </Box>
        <Box sx={{ padding: '10px 0px' }}>
          <LinearDeterminate />
        </Box>
      </Box>
      {walletAddress === ownerWallet ? (
        <Box
          sx={{
            display: 'grid',

            borderRadius: '0px 0px 15px 15px',
          }}
        >
          <Box>
            {type === 'creator' ? (
              onSale === 0 ? (
                <Button variant="outlined" onClick={openModal}>
                  Put On Sale
                </Button>
              ) : (
                <Button variant="outlined">On Sale</Button>
              )
            ) : isRated ? (
              onSale === 0 ? (
                <Button variant="outlined" onClick={openModal}>
                  Put On Sale
                </Button>
              ) : (
                <Button variant="outlined">On Sale</Button>
              )
            ) : (
              <Button variant="outlined" onClick={openRating}>
                Give Rating
              </Button>
            )}
          </Box>
        </Box>
      ) : (
        <>{onSale === 1 && <Button variant="outlined">Buy</Button>} </>
      )}
      <PopupModal
        open={isOpen}
        onClose={closeModal}
        head="Are you sure?"
        buttonTitle="Proceed"
        ownerWallet={ownerWallet}
        tokenId={tokenId}
        ownerId={ownerId}
        amountId={amountId}
        rating={false}
      />
      <PopupModal
        open={isRateOpen}
        onClose={closeRateModal}
        head="Give Ratings"
        buttonTitle="Rate"
        rating={true}
        ownerWallet={ownerWallet}
        tokenId={tokenId}
        ownerId={ownerId}
        amountId={amountId}
      />
    </Box>
  );
};
export default CollectedCards;
