/** @format */

import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { searchParams } from "../../redux/wishlist/wishlist.action";
import { useDispatch } from "react-redux";
import { resetSearchData } from "../../redux/wishlist/wishlist.slice";
import SearchPlace from "../searchPlace";
import { makeStyles } from "@mui/styles";

const SearchInput = () => {
  const [isSearchResultsVisible, setIsSearchResultsVisible] = useState(false);

  const dispatch = useDispatch();
  const classes = useStyles();

  const inputRef = useRef(null);

  const handleSearchParams = (event) => {
    const inputValue = event.target.value;
    const sanitizedInput = inputValue.replace(/[^a-zA-Z]/g, "");

    dispatch(searchParams(sanitizedInput));

    if (sanitizedInput === "") {
      dispatch(resetSearchData());
    }

    setIsSearchResultsVisible(true);
  };
  const handleClose = () => {
    setIsSearchResultsVisible(false);
  };
  const handleClickOutside = (event) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target) &&
      !event.target.closest(`.${classes.searchContainer}`)
    ) {
      setIsSearchResultsVisible(false);
      dispatch(resetSearchData());
      if (inputRef.current) {
        inputRef.current.value = "";
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch, classes.searchContainer]);

  return (
    <>
      <div className={classes.LayoutInput}>
        <input
          type="text"
          placeholder=" Search "
          className={classes.inputFeild}
          onChange={handleSearchParams}
          onFocus={() => setIsSearchResultsVisible(false)}
          ref={inputRef}
        />
      </div>
      <Box className={classes.searchContainer}>
        {isSearchResultsVisible && <SearchPlace handleClose={handleClose} />}
      </Box>
    </>
  );
};
export default SearchInput;
const useStyles = makeStyles((theme) => ({
  LayoutInput: {
    display: "flex",
    alignItems: "center",

    borderRadius: "5px",
    padding: "10px",
  },
  inputFeild: {
    letterSpacing: "1px",
    border: "1px solid #cacaca",
    borderRadius: "50px",
    padding: "10px",
    width: "20vw",
    "&:focus": {
      outline: "none",
    },
  },

  btnInput: {
    textTransform: "capitalize",
  },
  searchContainer: {
    position: "absolute",
    top: "100%",
    marginTop: "10px",
    left: "3.5%",
    zIndex: 1000,
  },
}));
