/** @format */

import { createSlice } from "@reduxjs/toolkit";
import {
  addToWishList,
  deleteWishList,
  getWishList,
  react,
  searchParams,
  username,
} from "./wishlist.action";

const initialValues = {
  /*-------Search Params ---------*/
  isSearchParamsLoading: false,
  isSearchParamsSuccess: false,
  isSearchParamsFailed: false,
  searchData: {},
  /*-------ADD Wish List---------*/
  isAddWishListLoading: false,
  isAddWishListSuccess: false,
  isAddWishListFailed: false,
  AddwishList: {},

  /*-------GET Wish List---------*/
  isGetWishListLoading: false,
  isGetWishListSuccess: false,
  isGetWishListFailed: false,
  WishList: [],
  /*-------DELETE Wish List---------*/
  isWishListDeleteLoading: false,
  isWishListDeleteSuccess: false,
  isWishListDeleteFailed: false,
  Delete: {},
  /*-------REACT Wish List---------*/
  isReactLoading: false,
  isReactSuccess: false,
  isReactFailed: false,
  react: {},
  /*-------Search Params ---------*/
  isUsernameLoading: false,
  isUsernameSuccess: false,
  isUsernameFailed: false,
  userSearch: {},
  userError: {},
};

const helpersSlicer = createSlice({
  name: "helpers",
  initialState: initialValues,
  reducers: {
    resetSearchData: (state) => {
      state.isSearchParamsSuccess = false;
      state.isSearchParamsLoading = false;
      state.isSearchParamsFailed = false;
      state.searchData = {};
    },
    resetAddtoWish: (state) => {
      state.isAddWishListSuccess = false;
      state.isAddWishListFailed = false;
      state.isReactLoading = false;
      state.isReactSuccess = false;
      state.isReactFailed = false;
    },
    resetDeleteToWish: (state) => {
      state.isWishListDeleteLoading = false;
      state.isWishListDeleteSuccess = false;
      state.isWishListDeleteFailed = false;
    },
    resetGetWishList: (state) => {
      state.isGetWishListLoading = false;
      state.isGetWishListSuccess = false;
      state.isGetWishListFailed = false;
      state.WishList = {};
    },
    resetUsername: (state) => {
      state.isUsernameSuccess = false;
      state.isUsernameLoading = false;
      state.userSearch = {};
      state.isUsernameFailed = false;
    },
  },
  extraReducers: {
    /*-------Search Params ---------*/
    [searchParams.pending]: (state) => {
      state.isSearchParamsLoading = true;
    },
    [searchParams.fulfilled]: (state, action) => {
      state.isSearchParamsLoading = false;
      state.isSearchParamsSuccess = true;
      state.searchData = action.payload;
    },
    [searchParams.rejected]: (state) => {
      state.isSearchParamsLoading = false;
      state.isSearchParamsSuccess = false;
      state.isSearchParamsFailed = true;
    },
    /*-------ADD Wish List ---------*/
    [addToWishList.pending]: (state) => {
      state.isAddWishListLoading = true;
    },
    [addToWishList.fulfilled]: (state, action) => {
      state.isAddWishListLoading = false;
      state.isAddWishListSuccess = true;
      state.AddwishList = action.payload;
    },
    [addToWishList.rejected]: (state) => {
      state.isAddWishListLoading = false;
      state.isAddWishListSuccess = false;
      state.isAddWishListFailed = true;
    },
    /*-------GET Wish List ---------*/
    [getWishList.pending]: (state) => {
      state.isGetWishListLoading = true;
    },
    [getWishList.fulfilled]: (state, action) => {
      state.isGetWishListLoading = false;
      state.isGetWishListSuccess = true;
      state.WishList = action.payload;
    },
    [getWishList.rejected]: (state) => {
      state.isGetWishListLoading = false;
      state.isGetWishListSuccess = false;
      state.isGetWishListFailed = true;
    },
    /*-------DELETE Wish List ---------*/
    [deleteWishList.pending]: (state) => {
      state.isWishListDeleteLoading = true;
    },
    [deleteWishList.fulfilled]: (state, action) => {
      state.isWishListDeleteLoading = false;
      state.isWishListDeleteSuccess = true;
      state.Delete = action.payload;
    },
    [deleteWishList.rejected]: (state) => {
      state.isWishListDeleteLoading = false;
      state.isWishListDeleteSuccess = false;
      state.isWishListDeleteFailed = true;
    },
    /*-------REACT Wish List ---------*/
    [react.pending]: (state) => {
      state.isReactLoading = true;
    },
    [react.fulfilled]: (state, action) => {
      state.isReactLoading = false;
      state.isReactSuccess = true;
      state.react = action.payload;
    },
    [react.rejected]: (state) => {
      state.isReactLoading = false;
      state.isReactSuccess = false;
      state.isReactFailed = true;
    },
    // Username
    [username.pending]: (state) => {
      state.isUsernameLoading = true;
    },
    [username.fulfilled]: (state, action) => {
      state.isUsernameLoading = false;
      state.isUsernameSuccess = true;
      state.userSearch = action.payload;
    },
    [username.rejected]: (state, action) => {
      state.isUsernameLoading = false;
      state.isUsernameSuccess = false;
      state.isUsernameFailed = true;
      state.userError = action.error;
    },
  },
});

export default helpersSlicer.reducer;
export const {
  resetAddtoWish,
  resetSearchData,
  resetDeleteToWish,
  resetGetWishList,
  resetUsername,
} = helpersSlicer.actions;
