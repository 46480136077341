/** @format */

export const accordionData = [
  {
    title: "How much will copy trading cost me?",
    descritpion:
      "There is no additional charge for copying another trader or traders. You will still be charged spreads on the trading and/or transaction fees where applicable. To view our complete pricing policy",
  },
  {
    title: "What is the minimum amount required to copy a trader?",
    descritpion:
      "There is no additional charge for copying another trader or traders. You will still be charged spreads on the trading and/or transaction fees where applicable. To view our complete pricing policy",
  },
  {
    title: "How does copy trading work? How do I set up my copy trading?",
    descritpion:
      "There is no additional charge for copying another trader or traders. You will still be charged spreads on the trading and/or transaction fees where applicable. To view our complete pricing policy",
  },
  {
    title:
      "How long does it take for copied trades to be executed in my account?",
    descritpion:
      "There is no additional charge for copying another trader or traders. You will still be charged spreads on the trading and/or transaction fees where applicable. To view our complete pricing policy",
  },
  {
    title: "Can other traders copy me?",
    descritpion:
      "There is no additional charge for copying another trader or traders. You will still be charged spreads on the trading and/or transaction fees where applicable. To view our complete pricing policy",
  },
];
