/** @format */

import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Form, FormFeedback } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../button';
import FileUploader from '../fileUploader';

import SignupProgressBar from '../signupBar';
import { singupResetState } from '../../redux/auth/auth.slicer';
import { useAlert } from 'react-alert';

const CreatorFiles = ({ handleBack, handleClose, formik }) => {
  const dispatch = useDispatch();
  const {
    isCreatorSingupLoading,
    isCreatorSingupSuccess,
    isCreatorSingupFailed,
    creatorSignupError,
  } = useSelector((state) => state.auth);
  const alert = useAlert();
  if (isCreatorSingupFailed) {
    alert.show(creatorSignupError.message, {
      timeout: 5000, // custom timeout just for this one alert
      type: 'error',
    });
    dispatch(singupResetState());
  }

  const handleResetState = (state) => {
    handleClose();
    dispatch(singupResetState());
  };

  return (
    <>
      {isCreatorSingupSuccess ? (
        <Box sx={{ height: '340px' }}>
          <Box sx={{ textAlign: 'start' }}>
            <SignupProgressBar activeStep={4} />
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 'bold',

                pt: 9,
              }}
            >
              Your subscription has been received. Our team will provide updates
              as soon as the DD procedure commences.{' '}
              <span
                style={{
                  color: 'red',
                  fontSize: '12px',
                  fontWeight: 'lighter',
                }}
              >
                {' '}
                Please Check Your Email for verification
              </span>
            </Typography>
          </Box>
          <Box sx={{ pt: 8 }}>
            <Button
              onClick={handleResetState}
              sx={{
                backgroundColor: '#34C77B !important',
                textTransform: 'capitalize',
                color: '#fff !important',
              }}
            >
              Close
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyItems: 'center',
            overflowY: 'scroll',
            maxHeight: '340px',
            '&::-webkit-scrollbar': {
              width: '6px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#DEE2E6',
              borderRadius: '3px',
            },
          }}
        >
          <Form onSubmit={formik.handleSubmit}>
            <Box py={0.5} sx={{ width: '100%' }}>
              <label style={{ color: '#8f979e', fontSize: '14px' }}>
                Financial Statements
              </label>
              <Box>
                <FileUploader formik={formik} value="financialStatements" />
                {formik.touched.financialStatements &&
                formik.errors.financialStatements ? (
                  <FormFeedback
                    type="invalid"
                    style={{ fontSize: '10px', color: 'red' }}
                  >
                    {formik.errors.financialStatements}
                  </FormFeedback>
                ) : null}
              </Box>
            </Box>
            <Box py={0.5} sx={{ width: '100%' }}>
              <label style={{ color: '#8f979e', fontSize: '14px' }}>
                Legal Documents
              </label>
              <Box>
                <FileUploader formik={formik} value="legalDocuments" />
                {formik.touched.legalDocuments &&
                formik.errors.legalDocuments ? (
                  <FormFeedback
                    type="invalid"
                    style={{ fontSize: '10px', color: 'red' }}
                  >
                    {formik.errors.legalDocuments}
                  </FormFeedback>
                ) : null}
              </Box>
            </Box>
            <Box py={0.5} sx={{ width: '100%' }}>
              <label style={{ color: '#8f979e', fontSize: '14px' }}>
                Environmental Impact Report
              </label>
              <Box>
                <FileUploader
                  value="environmentalImpactReport"
                  formik={formik}
                />
                {formik.touched.environmentalImpactReport &&
                formik.errors.environmentalImpactReport ? (
                  <FormFeedback
                    type="invalid"
                    style={{ fontSize: '10px', color: 'red' }}
                  >
                    {formik.errors.environmentalImpactReport}
                  </FormFeedback>
                ) : null}
              </Box>
            </Box>
            <Box py={0.5} sx={{ width: '100%' }}>
              <label style={{ color: '#8f979e', fontSize: '14px' }}>
                Technical Specifications
              </label>
              <Box>
                <FileUploader value="technicalSpecifications" formik={formik} />
                {formik.touched.technicalSpecifications &&
                formik.errors.technicalSpecifications ? (
                  <FormFeedback
                    type="invalid"
                    style={{ fontSize: '10px', color: 'red' }}
                  >
                    {formik.errors.technicalSpecifications}
                  </FormFeedback>
                ) : null}
              </Box>
            </Box>
            <Box py={0.5} sx={{ width: '100%' }}>
              <label style={{ color: '#8f979e', fontSize: '14px' }}>
                Ownership Structure
              </label>
              <Box>
                <FileUploader formik={formik} value="ownershipStructure" />
                {formik.touched.ownershipStructure &&
                formik.errors.ownershipStructure ? (
                  <FormFeedback
                    type="invalid"
                    style={{ fontSize: '10px', color: 'red' }}
                  >
                    {formik.errors.ownershipStructure}
                  </FormFeedback>
                ) : null}
              </Box>
            </Box>
            <Box py={0.5} sx={{ width: '100%' }}>
              <label style={{ color: '#8f979e', fontSize: '14px' }}>
                Previous Funding
              </label>
              <Box>
                <FileUploader formik={formik} value="previousFunding" />
                {formik.touched.previousFunding &&
                formik.errors.previousFunding ? (
                  <FormFeedback
                    type="invalid"
                    style={{ fontSize: '10px', color: 'red' }}
                  >
                    {formik.errors.previousFunding}
                  </FormFeedback>
                ) : null}
              </Box>
            </Box>
            <Box py={0.5} sx={{ width: '100%' }}>
              <label style={{ color: '#8f979e', fontSize: '14px' }}>
                Token Allocation Plan
              </label>
              <Box>
                <FileUploader formik={formik} value="tokenAllocationPlan" />
                {formik.touched.tokenAllocationPlan &&
                formik.errors.tokenAllocationPlan ? (
                  <FormFeedback
                    type="invalid"
                    style={{ fontSize: '10px', color: 'red' }}
                  >
                    {formik.errors.tokenAllocationPlan}
                  </FormFeedback>
                ) : null}
              </Box>
            </Box>
            {isCreatorSingupLoading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress color="inherit" size={20} />
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '.5fr 3fr',
                  justifyContent: 'space-between',
                  gap: 1,
                }}
              >
                <Button
                  onClick={() => {
                    handleBack();
                  }}
                  sx={{
                    backgroundColor: 'grey !important',
                    textTransform: 'capitalize',
                    color: '#fff !important',
                  }}
                >
                  Back
                </Button>
                {Object.keys(formik.errors).length > 0 ? (
                  [...Object.values(formik.errors)].map((data) => {
                    return (
                      <Typography
                        sx={{ textAlign: 'center', color: 'red' }}
                        py={1}
                      >
                        {data}
                      </Typography>
                    );
                  })
                ) : (
                  <Button
                    type="submit"
                    sx={{
                      backgroundColor: '#34C77B !important',
                      textTransform: 'capitalize',
                      color: '#fff !important',
                    }}
                  >
                    Submit
                  </Button>
                )}
              </Box>
            )}
          </Form>
        </Box>
      )}
    </>
  );
};

export default CreatorFiles;
