/** @format */

import React, { useEffect } from "react";
import {
  useAddress,
  useChainId,
  useConnectionStatus,
  useDisconnect,
  useNetworkMismatch,
  useSwitchChain,
} from "@thirdweb-dev/react";
import { PolygonAmoyTestnet, BinanceTestnet } from "@thirdweb-dev/chains";
import { useDispatch } from "react-redux";
import { login } from "../../redux/auth/auth.actions";
import { ResetState } from "../../redux/auth/auth.slicer";
const WalletConnect = ({ children }) => {
  const connectionStatus = useConnectionStatus();
  const isMismatched = useNetworkMismatch();
  const switchChain = useSwitchChain();
  const wallet = useAddress();
  const disconnect = useDisconnect();
  const dispatch = useDispatch();
  const connectedChainId = useChainId();

  useEffect(() => {
    if (connectionStatus === "connected") {
      if (
        connectedChainId !== PolygonAmoyTestnet.chainId &&
        connectedChainId !== BinanceTestnet.chainId
      ) {
        switchChain(BinanceTestnet.chainId)
          .then(() => {
            dispatch(login({ wallet: wallet }));
          })
          .catch(() => {
            return disconnect();
          });
      } else {
        dispatch(login({ wallet: wallet }));
      }
    } else if (connectionStatus === "disconnected") {
      dispatch(ResetState());
    }
  }, [
    connectionStatus,
    dispatch,
    wallet,
    isMismatched,
    switchChain,
    disconnect,
    connectedChainId,
  ]);
  return <>{children}</>;
};

export default WalletConnect;
