/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { collections, inWallet } from "./inwallet.action";

const initialState = {
  isInwalletLoading: false,
  isInwalletLoadingFailed: false,
  isInwalletLoadingSuccess: false,
  inWallet: {},
  ////////////////////////
  isCollectionsLoading: false,
  isCollectionsSuccess: false,
  isCollectionsFailed: false,
  collections: {},
};

const inWalletSlicer = createSlice({
  name: "inWallet",
  initialState,
  reducers: {
    resetState: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(inWallet.pending, (state) => {
      state.isInwalletLoading = true;
      state.isInwalletLoadingSuccess = false;
    });
    builder.addCase(inWallet.fulfilled, (state, action) => {
      state.isInwalletLoading = false;
      state.inWallet = action.payload;
      state.isInwalletLoadingSuccess = true;
    });
    builder.addCase(inWallet.rejected, (state) => {
      state.isInwalletLoading = false;
      state.isInwalletLoadingSuccess = false;
      state.isInwalletLoadingFailed = true;
    });
    /////////////////////////////////////////
    builder.addCase(collections.pending, (state) => {
      state.isCollectionsLoading = true;
    });
    builder.addCase(collections.fulfilled, (state, action) => {
      state.isCollectionsLoading = false;
      state.isCollectionsSuccess = true;
      state.collections = action.payload;
    });
    builder.addCase(collections.rejected, (state) => {
      state.isCollectionsLoading = false;
      state.isCollectionsSuccess = false;
      state.isCollectionsFailed = true;
    });
  },
});

export const { resetState } = inWalletSlicer.actions;
export default inWalletSlicer.reducer;
