/** @format */

import { Box } from "@mui/system";
import Trander from "../../components/trader";
import PerformingTraders from "../../components/performingTraders";
import { Typography } from "@mui/material";
import TraderAccordion from "../../components/accordion";
const TranderLanding = () => {
  return (
    <Box>
      <Trander />
      <PerformingTraders />
      <Box
        sx={{
          width: "100%",
          background: "#E5E8EB94",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          padding: "4rem 0",
        }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "30px",
            width: "40%",
          }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}>
            <Typography variant="h1">How its Work</Typography>
            <Typography variant="body1">
              CopyTrader is built to be simple and intuitive. Just find the
              investor you wish to copy through the Copy Discover page — there,
              you can search for the kind of investor you’re looking for.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "15%",
              width: "100%",
              flexWrap: "wrap",
            }}>
            <Box sx={Container}>
              <Box sx={styling}>1</Box>
              <Typography variant="body1">Select an investor</Typography>
            </Box>

            <Box sx={Container}>
              <Box sx={styling}>2</Box>
              <Typography variant="body1">Hit the COPY button</Typography>
            </Box>
            <Box sx={Container}>
              <Box sx={styling}>3</Box>
              <Typography variant="body1">
                Choose how much to allocate
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: "4rem, 0",
          ...Container,
        }}>
        <Box
          sx={{
            ...Container,
            width: "600px",
          }}>
          <Typography variant="h1">FAQ</Typography>
          <Box
            sx={{
              height: "300px",
            }}>
            <TraderAccordion />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
const Container = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  gap: "10px",
};
const styling = {
  width: "40px",
  height: "40px",
  background: "#34C77B",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontWeight: "bolder",
  color: "white",
  borderRadius: "50%",
};
export default TranderLanding;
