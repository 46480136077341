/** @format */

import { Box, Checkbox, Typography } from "@mui/material";
import React from "react";
import { COVERPAGE_BASE_URL } from "../../http/config";
import SunImage from "../../assets/logo/2 place.svg";
const PortfolioCard = ({ title, creatorName, image }) => {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const isIPFSImg = /ipfs:\/\//.test(image),
    imgSrc = isIPFSImg
      ? `https://lumiplace.infura-ipfs.io/ipfs/${image.split("//")[1]}`
      : image;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
      }}>
      <Box>
        <Checkbox
          {...label}
          size="small"
          color="success"
          disableFocusRipple
          disableRipple
          sx={{
            padding: 0,
            "& .MuiSvgIcon-root": {
              borderRadius: 20,
            },
          }}
        />
      </Box>
      <Box sx={{ width: "3.8rem", height: "3.8rem" }}>
        <img
          src={imgSrc}
          alt="error"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "0.5rem",
          }}
        />
      </Box>
      <Box>
        <Typography variant="h2">{title}</Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: "0.2rem" }}>
          <Typography variant="body1">By</Typography>
          <Typography variant="h3" fontSize={"0.64rem"}>
            {creatorName}
          </Typography>

          <img src={SunImage} width={"13"} alt="error" />
        </Box>
      </Box>
    </Box>
  );
};

export default PortfolioCard;
