/** @format */

import React from "react";
import { Box, Modal } from "@mui/material";
import "./style.css";
import Button from "./../button";
const ErrorDialog = ({ open, handleClose, errors }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <section>
        <div className="modalBackdrop" />
        <Box className="modal-container">
          <header>
            <h2 align="center">An error occured!</h2>
          </header>
          <div className="error-msg">
            <ul>
              {errors.length > 0 &&
                errors.map((message) => {
                  return <li>{message}</li>;
                })}
            </ul>
          </div>
          <footer className="modal-close">
            <Button
              variant="standard"
              sx={{
                width: "100px !important",
                borderRadius: "20px",
                height: "35px",
                color: "black !important",
              }}
              onClick={handleClose}>
              Close
            </Button>
          </footer>
        </Box>
      </section>
    </Modal>
  );
};

export default ErrorDialog;
