/** @format */

import React, { useEffect, useRef } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import './style.css';
import Avatar from '../chatList/Avatar';
import ChatItem from './ChatItem';
import { PROFILE_BASE_URL } from './../../http/config';
import { Alert, Box, CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import { getChatMessages, sendMessage } from '../../redux/chat/chats.action';
import { Link } from 'react-router-dom';
const ChatContent = ({ data, user, sender, loading }) => {
  const messagesEndRef = useRef(null);

  const dispatch = useDispatch();
  const initialValues = {
    fromAddress: user.walletAddress,
    toAddress: sender.walletAddress,
    message: '',
  };
  console.log(sender, 'sender');
  const onSubmit = async (values, { resetForm }) => {
    const payloadData = {
      fromAddress: values.fromAddress,
      toAddress: values.toAddress,
      message: values.message,
    };
    const { error, payload } = await dispatch(sendMessage(payloadData));
    if (!error) {
      dispatch(
        getChatMessages({
          fromAddress: values.fromAddress,
          toAddress: values.toAddress,
        })
      );
    }

    resetForm();
  };
  if (Object.entries(sender).length > 0) {
    return (
      <div className="main__chatcontent">
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              top: '20vh',
            }}
          >
            <CircularProgress color="success" />
          </Box>
        ) : (
          <>
            <div className="content__header">
              <div className="blocks">
                <div className="current-chatting-user">
                  {sender.img && (
                    <Avatar
                      isOnline="active"
                      image={PROFILE_BASE_URL + sender.img}
                    />
                  )}

                  <Link to={`/${sender.type}/${sender.username}`}>
                    {' '}
                    <p>{sender.username}</p>
                  </Link>
                </div>
              </div>

              {/* <div className="blocks">
            <div className="settings">
              <button className="btn-nobg">
                <i className="fa fa-cog"></i>
              </button>
            </div>
          </div> */}
            </div>
            <div className="content__body">
              <div className="chat__items">
                {data.map((itm, index) => {
                  let userType = null;
                  let image = null;
                  if (itm.fromAddress === user.walletAddress) {
                    userType = 'me';
                    image = user.img;
                  } else {
                    userType = 'other';
                    image = sender.img;
                  }

                  return (
                    <ChatItem
                      animationDelay={index + 2}
                      key={itm.chatId}
                      user={userType}
                      msg={itm.message}
                      image={image}
                      date={itm.createdAt}
                    />
                  );
                })}
                <div ref={messagesEndRef} />
              </div>
            </div>
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
              {({ handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="content__footer">
                    <div className="sendNewMessage">
                      <Avatar
                        isOnline="active"
                        image={PROFILE_BASE_URL + user.img}
                      />
                      <Field
                        type="text"
                        placeholder="Type a message here"
                        name="message"
                        required={true}
                        // onChange={this.onStateChange}
                        // value={this.state.msg}
                      />

                      <button
                        className="btnSendMsg"
                        id="sendMsgBtn"
                        type="submit"
                      >
                        <i className="fa fa-paper-plane"></i>
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        )}
      </div>
    );
  } else {
    return (
      <div className="main__chatcontent">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            top: '20vh',
            width: '100%',
          }}
        >
          <Alert variant="filled" severity="info">
            You do not have any conversation yet!
          </Alert>
        </Box>
      </div>
    );
  }
};
export default ChatContent;
