/** @format */

import { lumiShare } from "../../http/config";

export const artsApi = async (payload) => {
  try {
    const res = await lumiShare.get(`/app.v1/api/getArts${payload}`);
    return res;
  } catch (error) {
    throw error;
  }
};
///////////////////////////////////
export const followApi = async (payload) => {
  try {
    const res = await lumiShare.post(
      `/app.v1/api/action?action=${payload.type}`,
      payload.data
    );
    return res;
  } catch (error) {
    throw error;
  }
};
///////////////////////////////////////////
export const buyArts = async (payload) => {
  try {
    const res = await lumiShare.post("/app.v1/api/directTransfer", payload);
    return res;
  } catch (error) {
    throw error;
  }
};

export const buyNFTApi = async (payload) => {
  try {
    return payload;
  } catch (error) {
    throw error;
  }
};

export const getArtsApi = async ({ amountId, tokenID }) => {
  try {
    const res = await lumiShare.get(
      `/app.v1/api/singleArt/${tokenID}/${amountId}`
    );
    return res;
  } catch (error) {
    throw error;
  }
};
