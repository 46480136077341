/** @format */

import {
  Typography,
  Rating,
  IconButton,
  Box,
  Button,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Avatar,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import CustomAvatar from '../avatar';
import Wrapper from '../../components/wrapper';
import badge from '../../assets/place.png';
import { useEffect, useState } from 'react';
import { AiOutlineInstagram } from 'react-icons/ai';

import {
  BiLogoDiscordAlt,
  BiLogoLinkedin,
  BiLogoTwitter,
  BiSolidShareAlt,
} from 'react-icons/bi';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { PROFILE_BASE_URL } from '../../http/config';
import { followUser } from '../../redux/arts/arts.action';
import { Link, Navigate } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { useParams } from 'react-router-dom';
import { singleCreator } from '../../redux/singleCreator/singleCreator.action';
import StoryUpload from '../storyUploadModal';
import { useFormik } from 'formik';
import { Form } from 'reactstrap';
import { useContract, useRoleMembers, useAddress } from '@thirdweb-dev/react';
import { addArticles } from '../../redux/articles/articles.action';
import AlertPopup from '../alerts/alertPopup';
import AddVideo from '../AddVideo';
import { nftContractAddress } from '../../hooks/thirdwebHooks/contractAddresses';
const CreatorProfile = ({ inwalletData }) => {
  const { id } = useParams();

  const socialIcons = [
    { icon: <AiOutlineInstagram fontSize="medium" />, label: 'Instagram' },
    { icon: <BiLogoDiscordAlt fontSize="medium" />, label: 'Discord' },
    { icon: <BiLogoLinkedin fontSize="medium" />, label: 'LinkedIn' },
    { icon: <BiLogoTwitter fontSize="medium" />, label: 'Twitter' },
    { icon: <BiSolidShareAlt fontSize="medium" />, label: 'Share' },
  ];

  const alertPopup = useAlert();
  const wallet = useAddress();
  const {
    creator: { data },
  } = useSelector((state) => state.singleCreator);
  const { user, isAuthenticated } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [storyOpen, setStoryOpen] = useState(false);
  const [followingOpen, setFollowingOpen] = useState(false);
  const [followersOpen, setFollowersOpen] = useState(false);
  const [articleOpen, setArticleOpen] = useState(false);
  const [videoOpen, setVideoOpen] = useState(false);
  const [articleImage, setArticleImage] = useState('');
  const [loader, setLoader] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [admin, setAdmin] = useState(false);
  const { contract } = useContract(nftContractAddress);
  const { data: members, isLoading } = useRoleMembers(contract, 'admin');

  useEffect(() => {
    if (isLoading) {
      setAdmin(false);
    } else {
      if (members?.includes(wallet)) {
        setAdmin(true);
      } else {
        setAdmin(false);
      }
    }
  }, [isLoading, members, wallet]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      creator: wallet,
      title: '',
      subTitle: '',
      description: '',
      image: '',
    },

    onSubmit: async (values) => {
      setLoader(true);
      const fd = new FormData();
      fd.append('creator', wallet);
      fd.append('title', values.title);
      fd.append('subTitle', values.subTitle);
      fd.append('description', values.description);
      fd.append('image', articleImage);
      dispatch(addArticles(fd));
      setTimeout(() => {
        setLoader(false);
        handleArticleClose();
        setAlertOpen(true);
      }, 5000);
    },
  });

  const handleClick = () => {
    setOpen(true);
  };
  const handleImage = (image) => {
    setArticleImage(image);
  };
  const handleStory = () => {
    setStoryOpen(true);
  };
  const handleArticle = () => {
    setOpen(false);
    setArticleOpen(true);
  };
  const handleVideo = () => {
    setOpen(false);
    setVideoOpen(true);
  };
  const handleAlertClose = () => {
    setStoryOpen(false);
    setArticleOpen(false);
    setVideoOpen(false);
    setOpen(false);
    setAlertOpen(false);
  };

  const handleArticleClose = () => {
    setStoryOpen(false);
    setArticleOpen(false);
    setVideoOpen(false);
    setOpen(true);
  };
  const handleVideoClose = () => {
    setStoryOpen(false);
    setArticleOpen(false);
    setVideoOpen(false);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setStoryOpen(false);
  };
  const handleFollowingClose = () => {
    setFollowingOpen(false);
  };
  const handleFollowingOpen = () => {
    setFollowingOpen(true);
  };
  const handleFollowersClose = () => {
    setFollowersOpen(false);
  };
  const handleFollowersOpen = () => {
    setFollowersOpen(true);
  };

  const handleFollow = async () => {
    const payloadData = {
      data: { followerId: user.walletAddress, followingId: data.walletAddress },
      type: data.isFollowed ? 'unfollow' : 'follow',
    };
    const { error, payload } = await dispatch(followUser(payloadData));
    if (error) {
      alertPopup.show(error.message, {
        timeout: 3000, // custom timeout just for this one alert
        type: 'error',
      });
    } else {
      let wallet = null;
      if (isAuthenticated) {
        wallet = user.walletAddress;
      }
      let payloadCreator = { wallet: wallet, user: id };
      dispatch(singleCreator(payloadCreator));
      alertPopup.show(payload.data.message, {
        timeout: 3000, // custom timeout just for this one alert
        type: 'success',
      });
    }
  };

  return (
    <>
      <Box>
        <>
          <Box
            sx={{
              width: '100%',
              height: '320px',
              backgroundImage: `url(${PROFILE_BASE_URL + data.cover})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          >
            {/* {isSmallScreen ? (
              <Box display="flex" justifyContent="space-between" padding="20px">
                <IconButton
                  sx={{
                    background: 'white',
                    color: 'black',
                    '&:hover': {
                      background: 'white',
                      color: 'black',
                    },
                  }}>
                  <BiArrowBack />
                </IconButton>
                <IconButton sx={{ background: 'white', color: 'black' }}>
                  <BsShareFill />
                </IconButton>
              </Box>
            ) : null} */}
          </Box>
          <Wrapper>
            <Box
              sx={{
                position: 'relative',
                top: '-60px',
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <CustomAvatar
                src={PROFILE_BASE_URL + data.img}
                width="100px"
                height="100px"
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '10px',

                      flexWrap: 'wrap',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                    py={1}
                  >
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                      <Typography
                        variant="h1"
                        sx={{ textTransform: 'capitalize' }}
                      >
                        {data.username}
                      </Typography>
                      <img src={badge} alt="badge" width="20px" />
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                      {' '}
                      <Rating value={data.rating} readOnly component="legend" />
                      <Typography variant="body1" sx={{ color: '#8F979E' }}>
                        ({data.rating})
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px',
                    }}
                    pb={1}
                  >
                    <Box sx={{ display: 'flex', gap: '20px' }}>
                      <Box sx={{ display: 'flex', gap: '5px' }}>
                        <Typography variant="body1" sx={{ color: '#8F979E' }}>
                          Posts
                        </Typography>
                        <Typography variant="h1">{data.posts}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', gap: '5px' }}>
                        <Typography
                          variant="body1"
                          sx={{ color: '#8F979E', cursor: 'pointer' }}
                          onClick={handleFollowersOpen}
                        >
                          Followers
                        </Typography>
                        <Typography variant="h1">{data.followers}</Typography>
                      </Box>

                      <Box sx={{ display: 'flex', gap: '5px' }}>
                        <Typography
                          variant="body1"
                          sx={{ color: '#8F979E', cursor: 'pointer' }}
                          onClick={handleFollowingOpen}
                        >
                          Following
                        </Typography>

                        <Typography variant="h1">{data.following}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                {user.walletAddress === data.walletAddress ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                      padding: '10px 0 0 0 ',
                    }}
                  >
                    <Button
                      sx={{
                        marginRight: '10px',
                        backgroundColor: 'lightgrey',
                      }}
                      variant="primary"
                      aria-describedby={id}
                      onClick={handleClick}
                    >
                      <AddIcon />
                    </Button>
                    <Dialog onClose={handleFollowersClose} open={followersOpen}>
                      <DialogTitle>Followers</DialogTitle>
                      <List sx={{ pt: 0 }}>
                        {inwalletData?.data &&
                        inwalletData?.data?.followers.length > 0 ? (
                          inwalletData?.data?.followers.map((item) => {
                            return (
                              <ListItem disableGutters>
                                <ListItemButton
                                  onClick={() => {
                                    <Navigate replace={true} />;
                                  }}
                                >
                                  <ListItemAvatar>
                                    <Avatar>
                                      <img
                                        src={PROFILE_BASE_URL + item.img}
                                        alt="images of users"
                                      />
                                    </Avatar>
                                  </ListItemAvatar>
                                  <ListItemText primary={item.username} />
                                </ListItemButton>
                              </ListItem>
                            );
                          })
                        ) : (
                          <ListItem disableGutters>
                            <ListItemButton>
                              <ListItemText primary="No Followers" />
                            </ListItemButton>
                          </ListItem>
                        )}
                      </List>
                    </Dialog>
                    <Dialog onClose={handleFollowingClose} open={followingOpen}>
                      <DialogTitle>Following</DialogTitle>
                      <List sx={{ pt: 0 }}>
                        {inwalletData?.data &&
                          inwalletData?.data?.following.length > 0 &&
                          inwalletData?.data?.following.map((item) => {
                            return (
                              <ListItem disableGutters>
                                <ListItemButton
                                  autoFocus
                                  component={Link}
                                  to={`/${item.type}/${item.username}`}
                                >
                                  <ListItemAvatar>
                                    <Avatar>
                                      <Avatar
                                        src={PROFILE_BASE_URL + item.img}
                                      />
                                    </Avatar>
                                  </ListItemAvatar>
                                  <ListItemText primary={item.username} />
                                </ListItemButton>
                              </ListItem>
                            );
                          })}
                      </List>
                    </Dialog>

                    <Dialog onClose={handleClose} open={open}>
                      <DialogTitle>Choose post type</DialogTitle>
                      <List sx={{ pt: 0 }}>
                        <ListItem disableGutters>
                          <ListItemButton autoFocus onClick={handleStory}>
                            <ListItemAvatar>
                              <Avatar>
                                <StoryUpload storyOpen={storyOpen} />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Add Story" />
                          </ListItemButton>
                        </ListItem>

                        <ListItem disableGutters>
                          <ListItemButton autoFocus onClick={handleArticle}>
                            <ListItemAvatar>
                              <Avatar>
                                <AddIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Add Article" />
                          </ListItemButton>
                        </ListItem>

                        <ListItem disableGutters>
                          <ListItemButton autoFocus onClick={handleVideo}>
                            <ListItemAvatar>
                              <Avatar>
                                <AddIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Add Video" />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </Dialog>
                    <AlertPopup
                      open={alertOpen}
                      type="success"
                      close={handleAlertClose}
                    />
                    <Dialog open={articleOpen}>
                      <Form onSubmit={formik.handleSubmit}>
                        <DialogTitle>Upload Article</DialogTitle>
                        <DialogContent sx={{ font: 'black' }}>
                          <DialogContentText
                            sx={{ color: 'black', marginBottom: '10px' }}
                          >
                            Upload your article by filling all the required
                            fields
                          </DialogContentText>
                          <TextField
                            sx={{ marginBottom: '10px' }}
                            color="success"
                            name="title"
                            onChange={formik.handleChange}
                            fullWidth
                            label="Title"
                            id="fullWidth"
                            size="large"
                            required
                          />
                          <TextField
                            sx={{ marginBottom: '10px' }}
                            color="success"
                            fullWidth
                            label="Sub-Title"
                            id="fullWidth"
                            name="subTitle"
                            onChange={formik.handleChange}
                            required
                          />
                          <TextField
                            id="outlined-multiline-static"
                            label="Description"
                            color="success"
                            sx={{ marginBottom: '10px' }}
                            required
                            multiline
                            rows={4}
                            fullWidth
                            name="description"
                            onChange={formik.handleChange}
                          />
                          <span>Image: </span>{' '}
                          <input
                            type="file"
                            name="image"
                            accept="image/*"
                            onChange={(e) => {
                              const fileType = e.target.files[0].type;
                              if (!fileType.startsWith('image/')) {
                                alert('Please select only Image files.');
                                e.target.value = ''; // Clear the selected file
                                return;
                              }
                              handleImage(e.target.files[0]);
                            }}
                          />
                        </DialogContent>
                        <DialogActions>
                          <Button
                            variant="default"
                            onClick={handleArticleClose}
                          >
                            Cancel
                          </Button>
                          <Button type="submit" variant="default">
                            {loader ? (
                              <CircularProgress color="success" size={30} />
                            ) : (
                              'Add Article'
                            )}
                          </Button>
                        </DialogActions>
                      </Form>
                    </Dialog>
                    <AddVideo
                      handleVideoClose={handleVideoClose}
                      open={videoOpen}
                      handleAlertClose={handleAlertClose}
                    />
                    <Button
                      component={Link}
                      to={`/editProfile/${user.username}`}
                      sx={{
                        color: '#fff !important',
                        background: '#34C77B !important',
                        textTransform: 'capitalize',
                        fontSize: '12px',
                      }}
                    >
                      Edit Profile
                    </Button>
                    {admin && (
                      <Button
                        component={Link}
                        to={`/admin`}
                        sx={{
                          color: '#fff !important',
                          background: 'blue !important',
                          textTransform: 'capitalize',
                          fontSize: '12px',
                          marginLeft: '10px',
                        }}
                      >
                        Admin
                      </Button>
                    )}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '10px',
                      justifyContent: isAuthenticated
                        ? 'space-between'
                        : 'center',
                      alignItems: 'center',
                      width: '100%',
                    }}
                    py={0.5}
                  >
                    {isAuthenticated && (
                      <Button
                        sx={{
                          color: '#fff !important',
                          background: '#34C77B !important',
                          textTransform: 'capitalize',
                          fontSize: '12px',
                        }}
                        onClick={handleFollow}
                      >
                        {data.isFollowed ? 'Following' : 'Follow'}
                      </Button>
                    )}

                    <Button
                      sx={{
                        color: '#000 !important',
                        background: '#E5E8EB !important',
                        textTransform: 'capitalize',
                        fontSize: '12px',
                        padding: 1,
                      }}
                      component={Link}
                      to={`/chat?user=${data.creatorID}`}
                    >
                      Send Message
                    </Button>
                  </Box>
                )}
              </Box>
              <Box sx={{ maxWidth: '400px' }}>
                <Typography variant="body1">{data.bio}</Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  gap: '5px',
                  justifyContent: 'flex-end',
                  width: '100%',
                  padding: '5px 0',
                }}
              >
                <IconButton
                  component={Link}
                  to={data?.instagram}
                  sx={{
                    background: 'white',
                    color: 'black',
                    '&:hover': {
                      background: 'white',
                    },
                  }}
                  aria-label={socialIcons[0].label}
                >
                  {socialIcons[0].icon}
                </IconButton>
                <IconButton
                  component={Link}
                  to={data?.instagram}
                  sx={{
                    background: 'white',
                    color: 'black',
                    '&:hover': {
                      background: 'white',
                    },
                  }}
                  aria-label={socialIcons[1].label}
                >
                  {socialIcons[1].icon}
                </IconButton>
                <IconButton
                  component={Link}
                  to={data?.instagram}
                  sx={{
                    background: 'white',
                    color: 'black',
                    '&:hover': {
                      background: 'white',
                    },
                  }}
                  aria-label={socialIcons[2].label}
                >
                  {socialIcons[2].icon}
                </IconButton>
                <IconButton
                  component={Link}
                  to={data?.instagram}
                  sx={{
                    background: 'white',
                    color: 'black',
                    '&:hover': {
                      background: 'white',
                    },
                  }}
                  aria-label={socialIcons[3].label}
                >
                  {socialIcons[3].icon}
                </IconButton>
              </Box>
            </Box>
          </Wrapper>
        </>
      </Box>
    </>
  );
};
export default CreatorProfile;
