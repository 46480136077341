/** @format */

import { createTheme } from '@mui/material';

const theme = createTheme({
  fontFamily: 'Exo 2, sans-serif',
  fontStyle: 'normal',
  fontWeight: 'normal',
  overflowX: 'hidden',
  palette: {
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#1f1c2f',
    },
    background: {
      default: '#080D17',
      // paper: "#1B232D",
    },
    text: {
      primary: '#000000',
      secondary: '#c5c5c5',
    },
  },
  typography: {
    fontFamily: 'Exo 2, sans-serif',
    color: '#ffffff',
    allVariants: {
      fontFamily: 'Exo 2, sans-serif',
    },
    h1: {
      fontSize: '18px',
      fontWeight: '550',
    },
    h2: {
      fontFamily: 'Exo 2, sans-serif',
      fontSize: '16px',
      fontWeight: '550',
    },
    h3: {
      fontSize: '13px',
      fontWeight: '600',
    },
    h4: {
      fontSize: '12px',
      fontWeight: '600',
    },
    h5: {},
    h6: {},
    body2: {
      fontSize: '12px',
      fontWeight: '500',
      color: '#9C9C9C',
    },
    body1: {
      fontSize: '12px',
    },
  },
  palette: {
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#1f1c2f',
    },
    background: {
      default: '#080D17',
    },
    text: {
      primary: '#000000',
      secondary: '#c5c5c5',
    },
  },
});

export default theme;
