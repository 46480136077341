/** @format */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { putOnFixedPriceApi, mintingApi, putCollectionPriceApi } from "./api";

async function postCollectionDataFixedPrice({
  orderId,
  tokenId,
  transactionHash,
  ownerWallet,
  amount,
  price,
}) {
  let payload = {
    orderId,
    tokenId,
    transactionHash,
    ownerWallet,
    price: price.toString(),
  };

  try {
    const response = await putCollectionPriceApi(payload);
    console.log("Response: ", response);
  } catch (err) {
    console.log("Error while posting data: ", err);
  }
}

// async function subscribeToLogsOrder(
//   connectedWallet,
//   connectedChainId,
//   amountId,
//   tokenId,
//   fixedprice,
//   amount
// ) {
//   const _wssURL = chainIdToNetworkInfo[connectedChainId]?.wssUrl;
//   const web3 = new Web3(_wssURL);

//   const marketplaceAddress =
//     chainIdToNetworkInfo[connectedChainId]?.contracts?.marketplaceAddress;
//   const signature = Web3.utils.keccak256Wrapper(
//     "Order(address,uint256,uint256,uint256,uint256)"
//   );

//   const logsFilter = {
//     address: marketplaceAddress,
//     topics: [signature],
//   };
//   try {
//     const subscription = await web3.eth.subscribe("logs", logsFilter);
//     console.log(`Subscription ID Second: ${subscription.id}`);

//     subscription.on("data", (data) => {
//       console.log("Event data: ", data);
//       // const sender = data.topics[1];
//       const orderId = web3.utils.hexToNumber(data.topics[2]);
//       const transactionHash = data.transactionHash;

//       console.log("Order id: ", orderId, " Event data: ", data);

//       postCollectionDataFixedPrice(
//         orderId,
//         tokenId,
//         transactionHash,
//         connectedWallet,
//         fixedprice,
//         amountId,
//         amount
//       );
//     });
//     subscription.on("error", (error) => {
//       console.log("Event error: ", error);
//     });
//   } catch (error) {
//     console.error(`Error subscribing to new logs: ${error}`);
//   }
// }

export const PutCollectionOnFixedPrice = createAsyncThunk(
  "minting/putOnFixedPrice",
  async (payload, { getState, dispatch }) => {
    postCollectionDataFixedPrice(payload);
  }
);
