import { Grid, Typography, Box } from "@mui/material";
import TradeCard from "../../components/tradeCard";
import image from "../../assets/thums.png";
const PerformingTraders = () => {
  return (
    <Box
      sx={{
        width: "60%",
        margin: "auto",
        padding: "4rem 0px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "5px",
        }}
      >
        <Typography variant="h1">Why copy top-performing traders?</Typography>
        <Typography variant="body1">
          Invest like our best-performing traders: simply choose an investor to
          copy, and when they trade, so do you.
        </Typography>
      </Box>
      <Grid container gap={3} justifyContent="space-between">
        <Grid item lg={3} md={5} sm={8}>
          <TradeCard
            img={image}
            title="User-friendly"
            description="Invest like our best-performing traders: simply choose an investor to copy, and when they trade, so do you."
          />
        </Grid>
        <Grid item lg={3} md={5} sm={8}>
          <TradeCard
            img={image}
            title="Automatic"
            description="Copied trades are replicated to your portfolio in real time"
          />
        </Grid>
        <Grid item lg={3} md={5} sm={8}>
          <TradeCard
            img={image}
            title="Invest with invested traders"
            description="Invest like our best-performing traders: simply choose an investor to copy, and when they trade, so do you."
          />
        </Grid>
        <Grid item lg={3} md={5} sm={8}>
          <TradeCard
            img={image}
            title="Copying is free"
            description="No additional charges for using the CopyTrader feature."
          />
        </Grid>
        <Grid item lg={3} md={5} sm={8}>
          <TradeCard
            img={image}
            title="Save time"
            description="Manage your portfolio without having to constantly watch the markets."
          />
        </Grid>
        <Grid item lg={3} md={5} sm={8}>
          <TradeCard
            img={image}
            title="Leverage their expertise"
            description="Benefit from their knowledge and experience."
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default PerformingTraders;
