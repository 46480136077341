/** @format */

import { Box, Typography } from '@mui/material';
import './style.css';

import ArticlesCard from '../articlesCard';
import Carousel from 'react-elastic-carousel';
import { CarouselArrow } from '../../utiles/units';

import { useSelector } from 'react-redux';

const Articles = ({ singleCardView }) => {
  const { articles } = useSelector((state) => state.articles);

  if (singleCardView) {
    var breakPoints = [
      { width: 1, itemsToShow: 1 },
      { width: 550, itemsToShow: 1 },
      { width: 768, itemsToShow: 1 },
      { width: 1200, itemsToShow: 1 },
    ];
  } else {
    var breakPoints = [
      { width: 1, itemsToShow: 1 },
      { width: 550, itemsToShow: 2, itemsToScroll: 2 },
      { width: 768, itemsToShow: 3 },
      { width: 1200, itemsToShow: 3 },
    ];
  }
  const renderArticles = () => {
    return (
      <div
        style={{
          paddingLeft: '10px',
          paddingRight: '10px',
          display: 'flex',
          padding: '20px',
          marginY: '20px',
        }}
      >
        <Carousel
          autoPlaySpeed={5000}
          enableAutoPlay
          disableArrowsOnEnd={false}
          breakPoints={breakPoints}
          infinite={true}
          pagination={false}
          renderArrow={(arrowProps) => <CarouselArrow {...arrowProps} />}
        >
          {articles.map((data, i) => (
            <ArticlesCard key={i} {...data} />
          ))}
        </Carousel>
      </div>
    );
  };

  return (
    <Box>
      <Box>
        <Typography sx={{ paddingLeft: '40px' }} variant="h1">
          Articles
        </Typography>
      </Box>
      <Box>{renderArticles()}</Box>
    </Box>
  );
};

export default Articles;
