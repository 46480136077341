import { Box, Typography, Tabs, Tab } from '@mui/material';
import Wrapper from '../wrapper';
import { useState } from 'react';
import BarChart from '../BarChart/index';
const EnergyProduce = ({ title }) => {
  const [value, setValue] = useState(0);
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ pt: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  return (
    <Wrapper>
      <Box sx={{ marginBottom: '4rem' }}>
        <Typography variant="h1">{title}</Typography>
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={(e, val) => setValue(val)}
              indicatorColor="secondary"
              textColor="inherit"
            >
              <Tab
                label="Live"
                sx={{ fontWeight: 'bolder', fontSize: '12px' }}
                disableFocusRipple
                disableRipple
              />
              <Tab
                label="Monthly"
                sx={{ fontWeight: 'bolder', fontSize: '12px' }}
                disableFocusRipple
                disableRipple
              />
              <Tab
                label="Yearly"
                sx={{ fontWeight: 'bolder', fontSize: '12px' }}
                disableFocusRipple
                disableRipple
              />
            </Tabs>
          </Box>
          <Box>
            <CustomTabPanel value={value} index={0}>
              <BarChart />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <BarChart />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <BarChart />
            </CustomTabPanel>
          </Box>
        </Box>
      </Box>
    </Wrapper>
  );
};
export default EnergyProduce;
