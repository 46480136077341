/** @format */

import { Box, Grid, Typography, IconButton } from "@mui/material";
import SearchInput from "../searchInput";
import { AiOutlineInstagram } from "react-icons/ai";
import {
  BiLogoDiscordAlt,
  BiLogoLinkedin,
  BiLogoTwitter,
  BiSolidShareAlt,
} from "react-icons/bi";
import Button from "../button";
import { useDispatch } from "react-redux";
import { inWallet } from "../../redux/inWallet/inwallet.action";
const FooterSection = () => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(inWallet("0x10a521997bcC3090D8511dA685B3aB6f1255E7f3"));
  };

  const socialIcons = [
    { icon: <AiOutlineInstagram fontSize="medium" />, label: "Instagram" },
    { icon: <BiLogoDiscordAlt fontSize="medium" />, label: "Discord" },
    { icon: <BiLogoLinkedin fontSize="medium" />, label: "LinkedIn" },
    { icon: <BiLogoTwitter fontSize="medium" />, label: "Twitter" },
    { icon: <BiSolidShareAlt fontSize="medium" />, label: "Share" },
  ];
  return (
    <Box
      sx={{
        borderBottom: "1px solid #F9F9FA",
        paddingBottom: "25px",
        paddingLeft: { lg: 5, md: 3, sm: 0 },
        paddingRight: { lg: 5, md: 3, sm: 0 },
      }}>
      <Grid container gap={4}>
        <Grid item lg={6} md={9} sm={12}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "25px" }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Typography variant="h2">Stay in the loop</Typography>
              <Typography>
                Join our mailing list to stay in the loop with our newest
                feature, NFT dropd, and tips and tricks for navigateing LUMI.
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "çenter" }}>
              <Box>
                <SearchInput
                  placeholder="Your email address"
                  borderRadius="20px"
                  borderColor="#cacaca"
                  height="35px"
                />
              </Box>
              <Box>
                <Button
                  variant="standard"
                  sx={{
                    width: "100px !important",
                    borderRadius: "20px",
                    height: "35px",
                  }}
                  onClick={handleClick}>
                  Sign up
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={3} md={5} sm={9} xs={12}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <Typography variant="h2">Join the community</Typography>
            <Box sx={{ display: "flex", gap: "5px" }}>
              {socialIcons.map((socialIcon, index) => (
                <IconButton
                  key={index}
                  sx={{
                    backgroundImage: "linear-gradient(#FCE5A6,#F9C029)",
                    color: "black",
                    "&:hover": {
                      background: "#FFFFFF",
                    },
                  }}
                  aria-label={socialIcon.label}>
                  {socialIcon.icon}
                </IconButton>
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default FooterSection;
