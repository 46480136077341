/** @format */

import ImageSlider from '../../components/farmProfileSlider';
import ProfileDetails from '../../components/farmProfileDetails';
import EnergyProduce from '../../components/EnergyProduce';
import { Box, Typography } from '@mui/material';
import Wrapper from '../../components/wrapper';
import FeedTrafic from '../../components/feedTrafic';
import FarmVideo from '../../components/farmProfileVideo';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleArts } from '../../redux/arts/arts.action';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Loading from '../../components/loading';
import GoogleMap from '../../components/searchlocation';
import { ARTICLES_BASE_URL } from '../../http/config';
import { Image } from '@mui/icons-material';

const Article = () => {
  const dispatch = useDispatch();
  const { articleId } = useParams();
  const [singleArticle, setSingleArticle] = useState([]);
  const { articles } = useSelector((state) => state.articles);

  useEffect(() => {
    if (articles.length > 0) {
      setSingleArticle(
        articles.filter((article) => article.articleId == articleId)
      );
    }
  }, [articleId, articles]);
  console.log(singleArticle, 'article');
  return (
    <>
      {singleArticle.length === 0 ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              marginY: '10px',
            }}
          >
            <Box sx={{ width: { sm: '100%', md: '50%' } }}>
              <img
                style={{ width: '100%', height: '50vh', objectFit: 'contain' }}
                src={ARTICLES_BASE_URL + singleArticle[0].image}
                alt="images"
                loading="lazy"
              />
            </Box>
            <Box
              sx={{
                width: { sm: '100%', md: '50%' },
                padding: '10px',
              }}
            >
              <h2 style={{ textTransform: 'capitalize' }}>
                {singleArticle[0].title}
              </h2>
              <h3>{singleArticle[0].subTitle}</h3>
              <p>{singleArticle[0].description}</p>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};
export default Article;
