/** @format */

import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Rating,
  Typography,
} from '@mui/material';
import CustomAvatar from '../avatar';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import LinearDeterminate from '../progressBar';
import { useState } from 'react';
import { BiSun } from 'react-icons/bi';
import { WiLightning } from 'react-icons/wi';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PROFILE_BASE_URL } from '../../http/config';
import { artsData } from '../../redux/arts/arts.action';
import SharePopOver from '../shareButton';
import Ligghting from '../../assets/lightning.svg';
import Cancel from '../../assets/Red cancel.svg';
import { addToWishList, react } from '../../redux/wishlist/wishlist.action';
import { resetAddtoWish } from '../../redux/wishlist/wishlist.slice';
import { useAlert } from 'react-alert';
const NFTArtsCard = ({
  image,
  ownerImg,
  no,
  created_at,
  fixedprice,
  rating,

  tokenId,
  title,
  singleCardView,
  handlePopoverClose,
  handlePopoverOpen,

  onNextClick,
  username,
  amountId,
  totalBulbs,
  totalSuns,
  totalFavorites,
  favorite,
  bulb,
  sun,
  apy,
}) => {
  const {
    user: { walletAddress },
    isAuthenticated,
  } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(null);
  const { isAddWishListSuccess, isAddWishListFailed } = useSelector(
    (state) => state.helpersSlicer
  );
  const alert = useAlert();
  const isIPFSImg = /ipfs:\/\//.test(image),
    imgSrc = isIPFSImg
      ? `https://lumiplace.infura-ipfs.io/ipfs/${image.split('//')[1]}`
      : image;

  const { isReactLoading, isReactSuccess } = useSelector(
    (state) => state.helpersSlicer
  );
  const handleonNextClick = () => {
    onNextClick(no);
  };
  const checkLogin = () => {
    if (!isAuthenticated) {
      return alert.show('Login to your account first!', {
        timeout: 5000, // custom timeout just for this one alert
        type: 'error',
      });
    }
  };

  const handleReact = async (value) => {
    setLoading(value);
    let data = {
      walletAddress,
      tokenId,
      amountId,
      value,
    };
    if (isAuthenticated) {
      const { error, payload } = await dispatch(react(data));

      if (error) {
        setLoading(false);
        alert.show(error.message, {
          timeout: 5000, // custom timeout just for this one alert
          type: 'error',
        });
      } else {
        alert.show(payload.message, {
          timeout: 5000, // custom timeout just for this one alert
          type: 'success',
        });
        setLoading(false);
        dispatch(artsData());
      }
    } else {
      setLoading(false);
      alert.show('Login to your account first!', {
        timeout: 5000, // custom timeout just for this one alert
        type: 'error',
      });
    }
  };

  const originalDate = new Date(created_at);

  const month = originalDate.getMonth() + 1;
  const day = originalDate.getDate();
  const year = originalDate.getFullYear();
  const dispatch = useDispatch();
  const formattedDate = `${month}/${day}/${year}`;

  const handleSubmit = (tokenId, amountId) => {
    checkLogin();
    let payload = {
      userWallet: walletAddress,
      nftToken: tokenId,
      amountId: amountId,
    };
    if (isAuthenticated) {
      dispatch(addToWishList(payload));
    }
    onNextClick(no);
  };

  const { arts } = useSelector((state) => state.artsSlicer);
  let tokenID = null;
  if (arts.nftData) {
    tokenID = arts.nftData[0].tokenId;
  }
  if (
    isAddWishListSuccess ||
    isAddWishListFailed ||
    isReactLoading ||
    isReactSuccess
  ) {
    setTimeout(() => {
      dispatch(resetAddtoWish());
    }, 2000);
  }
  return (
    <Box
      sx={{
        borderRadius: '20px',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        overflow: 'hidden',
        textDecoration: 'none',
        width: { xs: '80vw', sm: '40vw', md: '20vw' },
        position: 'relative',
      }}
    >
      {singleCardView && (
        <Paper>
          <IconButton
            sx={{
              position: 'absolute',
              top: '45%',
              left: '-10%',
              width: '40px',
              transform: 'translateY(-50%)',
              zIndex: 1000,
              backgroundColor: '#fff',
              padding: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: 'inherit',
              '&:hover': {
                backgroundColor: '#fff',
              },
            }}
            onClick={handleonNextClick}
          >
            <img src={Cancel} alt="" />
          </IconButton>
        </Paper>
      )}

      <Box
        sx={{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'relative',
          boxSizing: 'border-box',
        }}
      >
        <Link to={`/farmprofile/${tokenId}/${amountId}`}>
          <img
            style={{
              width: '100%',
              height: '200px',
              borderRadius: '20px 20px 0px 0px',
            }}
            src={imgSrc}
            alt=""
          />
        </Link>
        <Box>
          <SharePopOver shareUrl={`/farmprofile/${tokenId}/${amountId}`} />
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            position: 'absolute',
            bottom: '0px',
            padding: '10px 10px',
            boxSizing: 'border-box',
            background:
              'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5))',
            zIndex: 1,
            textTransform: 'capitalize',
          }}
        >
          <Box>
            <Typography variant="h2" sx={{ color: 'white' }}>
              {title}
            </Typography>
          </Box>
          <Box
            sx={{
              zIndex: 0,
            }}
          ></Box>
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'space-between',
            padding: '10px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
              width: 'fit-content',
            }}
          >
            <Box>
              <CustomAvatar
                src={`${PROFILE_BASE_URL + ownerImg}`}
                width="30px"
                height="30px"
              />
            </Box>
            <Box>
              <Typography
                component={Link}
                to={`/creator/${username}`}
                variant="h4"
                sx={{
                  textDecoration: 'none',
                  color: '#000 !important',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                {username}
              </Typography>
              <Typography variant="body1" sx={{ color: 'black' }}>
                {formattedDate}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Rating
              name="read-only"
              value={parseInt(rating)}
              sx={{ fontSize: '12px' }}
              readOnly
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0px 10px 10px 10px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '5px',
            }}
          >
            <Typography variant="body1">Price&nbsp;</Typography>
            <Typography variant="h4">{fixedprice}</Typography>
          </Box>
        </Box>
        <Box></Box>
        <Box></Box>
      </Box>
      <Box sx={{ padding: '0 10px' }}>
        <Box sx={{ display: 'flex', gap: '5px', alignItems: 'end' }}>
          <Typography variant="body" sx={{ fontSize: '12px' }}>
            The Offered Yield (APY)
          </Typography>
          <Typography variant="h3"> {apy}%</Typography>
        </Box>
        <Box sx={{ padding: '10px 0px' }}>
          <LinearDeterminate />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'grid',

          borderRadius: '0px 0px 15px 15px',
        }}
      >
        <Box
          sx={{
            display: 'flex',

            borderRadius: '0px 0px 15px 15px',
          }}
        >
          <Box sx={{ width: '100%' }}>
            <Button
              sx={{
                color: 'black',
                // margin: '5px 0px',
                width: '100%',
                borderRadius: '0px',
                border: '1px solid lightgrey',
                textTransform: 'capitalize',
                font: 'normal normal medium 16px/40px Exo 2',
                '&:hover': {
                  // border: '1px solid #34C77B',
                  color: '#34C77B',
                  background: '#fff',
                },
              }}
              component={Link}
              to={`/portfolio/${tokenId}/${amountId}`}
            >
              Buy Now
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            height: '43px !important',
            borderRadius: '0px 0px 10px 10px',
            transition: 'background 0.6s ease-in',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            gap: 4,

            color: '#fff',
            backgroundColor: '#34C77B !important',
            justifyContent: 'center',
          }}
        >
          {loading === 'sun' ? (
            <CircularProgress size={12} />
          ) : (
            <IconButton onClick={() => handleReact('sun')}>
              <BiSun
                style={{
                  strokeWidth: '1.5',
                  color: sun === 0 ? 'white' : '#F5BB23',
                }}
                size={'1rem'}
              />
              <span style={{ fontSize: '12px', color: 'white' }}>
                &nbsp;{totalSuns}
              </span>
            </IconButton>
          )}

          <IconButton
            sx={{ color: '#fff' }}
            onClick={() => handleSubmit(tokenId, amountId)}
          >
            <WiLightning
              style={{
                strokeWidth: '1.5',
                color: favorite === 0 ? 'white' : '#F5BB23',
              }}
              size={'1.5rem'}
            />
            <span style={{ fontSize: '12px', color: 'white' }}>
              &nbsp;{totalFavorites}
            </span>
          </IconButton>
          {loading === 'bulb' ? (
            <CircularProgress size={12} />
          ) : (
            <IconButton onClick={() => handleReact('bulb')}>
              <LightbulbOutlinedIcon
                sx={{
                  color: bulb === 0 ? 'white' : '#F5BB23',
                }}
              />
              <span style={{ fontSize: '12px', color: 'white' }}>
                &nbsp;{totalBulbs}
              </span>
            </IconButton>
          )}
        </Box>
      </Box>
      {singleCardView && (
        <Paper elevation={5}>
          <IconButton
            sx={{
              position: 'absolute',
              top: '45%',
              right: '-7%',
              width: '40px',
              transform: 'translateY(-50%)',
              zIndex: 1000,
              backgroundColor: '#fff',
              padding: 1.3,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: 'inherit',
              '&:hover': {
                backgroundColor: '#fff',
              },
            }}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            onClick={() => {
              handleSubmit(tokenId, amountId);
            }}
          >
            <img src={Ligghting} width={15} alt="" />
          </IconButton>
        </Paper>
      )}
    </Box>
  );
};
export default NFTArtsCard;
