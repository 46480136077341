/** @format */

import { Box, LinearProgress } from "@mui/material";
export default function LinearDeterminate() {
  return (
    <Box sx={{ width: "100%", margin: "5px 0" }}>
      <LinearProgress
        color="success"
        variant="determinate"
        value={30}
        sx={{
          color: "red",
          borderRadius: "5px",
          "& .MuiLinearProgress-bar": {
            backgroundColor: "#34C77B",
          },
        }}
      />
    </Box>
  );
}
