/** @format */

import { lumiShare } from "../../http/config";

export const sendMessageApi = async (payload) => {
  try {
    const res = await lumiShare.post(`/app.v1/api/sendMessage`, payload);
    return res;
  } catch (error) {
    throw error;
  }
};
///////////////////////////////////

///////////////////////////////////////////
export const getChatMessagesApi = async ({ fromAddress, toAddress }) => {
  try {
    const res = await lumiShare.get(
      `/app.v1/api/chat/${fromAddress}/${toAddress}`
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const getChatUsersApi = async (payload) => {
  try {
    const res = await lumiShare.get(`/app.v1/api/userChats?wallet=${payload}`);
    return res;
  } catch (error) {
    throw error;
  }
};
