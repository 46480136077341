/** @format */

import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { AiFillCloseCircle } from "react-icons/ai";
import UserStory from "../userStory";
import UserHeader from "../userHeader";
import { useSelector } from "react-redux";
import { PROFILE_BASE_URL } from "../../http/config";
import { useEffect } from "react";

const StoryUpload = ({ storyOpen }) => {
  const {
    user: { username, img, creatorID },
  } = useSelector((state) => state.auth);
  const [open, setOpen] = React.useState(false);
  const [selectedStory, setSelectedStory] = React.useState({
    name: username,
    url: PROFILE_BASE_URL + img,
    time: "1h",
    storyId: creatorID,
  });
  const handleOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    if (storyOpen) {
      setOpen(true);
    }
  }, [storyOpen]);

  const handleClose = () => {
    setOpen(false);
    setSelectedStory(null);
  };
  const { isLoginSuccess } = useSelector((state) => state.auth);

  return (
    <Box>
      <>{isLoginSuccess && <UserStory handleOpen={handleOpen} />}</>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <>
          <Box
            sx={{
              color: "white",
              position: "absolute",
              right: { md: 45, xs: 10 },
              top: { md: 45, xs: 50 },
              cursor: "pointer",
              zIndex: "9",
            }}>
            <AiFillCloseCircle size={30} onClick={handleClose} />
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { md: "100%", xs: "100%" },
              bgcolor: { md: "#000", xs: "transparent" },
              height: "100%",
              border: "2px solid #000",
              boxShadow: 24,
              paddingTop: "5rem",
            }}>
            <UserHeader selectedStory={selectedStory} setOpen={setOpen} />
          </Box>
        </>
      </Modal>
    </Box>
  );
};

export default StoryUpload;
