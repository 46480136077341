/** @format */

import * as React from "react";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import { Box, Button, Paper } from "@mui/material";
import HorizontalLinearStepper from "../stepper";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  boxShadow: "0px 3px 6px #0000001A",
  padding: "30px 50px 50px 50px",
  borderRadius: "8px",
};
const miniModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 200,
  bgcolor: "background.paper",
  boxShadow: "0px 3px 6px #0000001A",
  padding: "30px",
  borderRadius: "8px",
};
const CustomModal = () => {
  const [open, setOpen] = React.useState(false);
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleBackdropClick = () => {
    setConfirmationOpen(true);
  };

  const handleConfirmationCancel = () => {
    setConfirmationOpen(false);
  };

  const handleConfirmationConfirm = () => {
    setConfirmationOpen(false);
    handleClose();
  };

  return (
    <>
      <Typography
        onClick={handleOpen}
        sx={{
          cursor: "pointer",
          fontWeight: "bolder",
        }}>
        Sign&nbsp;Up
      </Typography>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropComponent={Backdrop}
        BackdropProps={{
          onClick: handleBackdropClick,
        }}>
        <Paper sx={style}>
          <HorizontalLinearStepper
            handleClose={handleClose}
            handleRemoveLogin={handleClose}
          />
        </Paper>
      </Modal>

      <ConfirmationPopup
        open={confirmationOpen}
        onCancel={handleConfirmationCancel}
        onConfirm={handleConfirmationConfirm}
      />
    </>
  );
};

export default CustomModal;

const ConfirmationPopup = ({ open, onCancel, onConfirm }) => {
  return (
    <Modal
      open={open}
      onClose={onCancel}
      BackdropComponent={Backdrop}
      BackdropProps={{
        onClick: onCancel,
      }}>
      <Paper sx={miniModal}>
        <Typography
          sx={{ fontSize: "14px", textAlign: "center", fontWeight: "bold" }}
          mb={2}>
          You have a few last steps left, Are you sure you want to go out?
        </Typography>
        <Box display="flex" justifyContent="center" gap="10px">
          <Button
            sx={{
              backgroundColor: "black !important",
              textTransform: "capitalize",
              color: "#fff !important",
              width: "40px !important",
              padding: 0,
            }}
            onClick={onConfirm}>
            Yes
          </Button>
          <Button
            sx={{
              textTransform: "capitalize",
              color: "#000 !important",
              width: "40px !important",
            }}
            onClick={onCancel}
            variant="contained"
            color="primary">
            No
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
};
