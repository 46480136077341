/** @format */

/**
 * @dev this functions generates dynamic props for mui tab
 * @param {number} index value of the active tab
 * @returns {object} dynamic props generated from value of the active tab
 */
export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

/**
 * @dev this function slices the long usernames and wallet addresses into a string of length 8
 * @param {string} username The usernameof the user to slice
 * @returns {string} sliced string of length 8
 */
export const shortenUserName = (username) => {
  if (username.length > 8) {
    return username.slice(0, 8) + "...";
  }
  return username;
};

/**
 * @dev reads the content of file asynchronously and returns a buffer
 * @param file to read asyn as a buffer
 * @returns buffer content
 */
export function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsArrayBuffer(file);
  });
}

export function createIPFS_URL(cid = "") {
  if (!cid) return null;
  return `https://ipfs.io/ipfs/${cid.split("//")[1]}`;
}

export function timeAgo(timestamp) {
  const date = new Date(timestamp);
  const now = new Date();
  const seconds = Math.floor((now - date) / 1000);

  let interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return interval + " years ago";
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return interval + " months ago";
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + " days ago";
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + " hours ago";
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + " minutes ago";
  }
  return Math.abs(Math.floor(seconds)) + " seconds ago";
}
