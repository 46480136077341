/** @format */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { allCreators, collectionsApi, inWalletApi, setRatingsApi } from "./api";

export const inWallet = createAsyncThunk("wallet/inWallet", async (payload) => {
  try {
    const res = await inWalletApi(payload);
    return res;
  } catch (error) {
    throw error;
  }
});
export const collections = createAsyncThunk(
  "wallet/collections",
  async (payload) => {
    try {
      const res = await collectionsApi(payload);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);
export const setRatings = createAsyncThunk(
  "wallet/setRatings",
  async (payload) => {
    try {
      const res = await setRatingsApi(payload);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getCreators = createAsyncThunk("wallet/getCreators", async () => {
  try {
    const res = await allCreators();
    return res.data;
  } catch (error) {
    throw error;
  }
});
