/** @format */

import { useDispatch, useSelector } from "react-redux";
import { PROFILE_BASE_URL } from "../../http/config";
import { Box } from "@mui/material";
import { useState } from "react";
import UserDetails from "../../components/userDetails";
import EditForm from "../../components/editprofileform";

import { LuImagePlus } from "react-icons/lu";

import { editProfile } from "../../redux/editProfile/editProfile.action";
import { resetStateEdit } from "../../redux/editProfile/editProfile.slice";

const UploadProfile = () => {
  const { data } = useSelector((state) => state.singleCreator.creator);
  const { isEditProfileSuccess } = useSelector(
    (state) => state.editProfileSlice
  );
  const [profileImg, setProfileImg] = useState();
  const dispatch = useDispatch();
  const [changed, setChanged] = useState(true);

  const handleProfileChange = (event) => {
    setProfileImg(event.target.files[0]);
    setChanged(false);
    const fd = new FormData();
    fd.append("profile", event.target.files[0]);
    fd.append("walletAddress", data?.walletAddress);
    dispatch(editProfile(fd)).then(() => {
      if (isEditProfileSuccess) {
        resetStateEdit();
      }
    });
  };

  return (
    <>
      <Box sx={{ position: "relative", top: "-50px" }}>
        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleProfileChange}
          id="uploadProfileInput"
        />
        <Box sx={{ width: "100px" }}>
          <label htmlFor="uploadProfileInput" style={{ cursor: "pointer" }}>
            <Box
              sx={{
                gap: 1,
                width: "100px",
                height: "100px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background:
                  "linear-gradient(227deg, #27D182, #FAC73F, #C2992A)",
                borderRadius: "50% !important",
              }}>
              <Box
                sx={{
                  width: "90%",
                  height: "90%",
                  cursor: "pointer",
                  borderRadius: "50%",
                  backgroundImage: `url(${
                    profileImg
                      ? URL.createObjectURL(profileImg)
                      : PROFILE_BASE_URL + data.img
                  })`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                }}>
                {changed ? (
                  <Box
                    sx={{
                      background: "rgba(0, 0, 0, 0.5)",
                      width: "100%",
                      height: "100%",
                      borderRadiu: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <LuImagePlus
                      style={{ color: "white", fontWeight: "15px" }}
                    />
                  </Box>
                ) : null}
              </Box>
            </Box>
          </label>
        </Box>
      </Box>
      <UserDetails />
      <EditForm />
    </>
  );
};

export default UploadProfile;
