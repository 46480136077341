/** @format */

import { Box, Rating } from '@mui/material';
import { Typography } from '@mui/material';
import { BiPlay, BiSun } from 'react-icons/bi';
import Button from '../button';
import CustomAvatar from '../avatar';
import { useEffect, useRef, useState } from 'react';
import { WiLightning } from 'react-icons/wi';
import { AiOutlineBulb } from 'react-icons/ai';
import { PiScreencastThin } from 'react-icons/pi';
import { BiFullscreen } from 'react-icons/bi';
import { BsPause } from 'react-icons/bs';
import VolumeControl from '../volumeControl';
import { ARTICLES_BASE_URL, VIDEOS_BASE_URL } from '../../http/config';

const Video = ({ title, subTitle, creator, video, thumbnail, description }) => {
  const [isFirstButtonHovered, setIsFirstButtonHovered] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const handleFirstButtonHover = () => {
    setIsFirstButtonHovered(true);
  };
  const handleFirstButtonLeave = () => {
    setIsFirstButtonHovered(false);
  };

  const togglePlay = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };
  const handleFullscreen = () => {
    if (videoRef.current.requestFullscreen) {
      videoRef.current.requestFullscreen();
    } else if (videoRef.current.mozRequestFullScreen) {
      videoRef.current.mozRequestFullScreen();
    } else if (videoRef.current.webkitRequestFullscreen) {
      videoRef.current.webkitRequestFullscreen();
    } else if (videoRef.current.msRequestFullscreen) {
      videoRef.current.msRequestFullscreen();
    }
  };

  const videoRef = useRef();
  const updateTime = () => {
    setCurrentTime(videoRef.current.currentTime);
  };

  useEffect(() => {
    const interval = setInterval(updateTime, 1000);
    return () => clearInterval(interval);
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };
  return (
    <Box
      sx={{
        marginRight: '15px',
        height: 'fit-content',
        borderRadius: '15px',
        // boxShadow: '0px 5px 15px #00000014',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        overflow: 'hidden',
        width: '400px',
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <img
          src={VIDEOS_BASE_URL + thumbnail}
          alt={title}
          style={{
            display: isPlaying ? 'none' : 'block',
            width: '100%',
            height: '224px',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            objectFit: 'cover',
          }}
        />
        <video
          ref={videoRef}
          controls={false}
          onClick={togglePlay}
          style={{
            display: isPlaying ? 'block' : 'none',
            width: '100%',
            height: '224px',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            objectFit: 'cover',
          }}
        >
          <source src={VIDEOS_BASE_URL + video} type="video/mp4" />
          <div
            className="progress"
            style={{
              width: isPlaying ? `${(currentTime / totalTime) * 100}%` : '0%',
              backgroundColor: isPlaying ? 'red' : 'grey',
            }}
          ></div>
        </video>
        <Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              position: 'absolute',
              bottom: '0px',
              boxSizing: 'border-box',
              padding: '0.7rem',
            }}
          >
            <Box>
              <Typography variant="h3" sx={{ color: 'white' }}>
                {title}
              </Typography>
              <Typography variant="body1" sx={{ color: 'white' }}>
                {subTitle}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          boxSizing: 'border-box',
          padding: '0.7rem',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box>
            <CustomAvatar src={video} />
          </Box>
          <Box>
            <Typography>{creator}</Typography>
            {/* <Typography>{location}</Typography> */}
          </Box>
        </Box>
        {/* <Box>
          <Rating size="small" value={5} sx={{ fontSize: "12px" }} />
        </Box> */}
      </Box>
      <Box sx={{ padding: '10px' }}>{description}</Box>
      <Box
        sx={
          {
            // display: 'grid',
            // gridTemplateColumns: '0.5fr 3.5fr',
          }
        }
      >
        <Box></Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0.7rem',
            borderRadius: '0px 0px 15px 0px',
            borderRight: '1px solid #E5E8EB !important',
            borderTop: '1px solid #E5E8EB !important',
            borderBottom: '1px solid #E5E8EB !important',
            boxSizing: 'border-box',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '120px',
            }}
          >
            {isPlaying ? (
              <>
                <BsPause
                  onClick={togglePlay}
                  style={{ fontSize: '20px', cursor: 'pointer' }}
                >
                  {isPlaying ? 'Play' : 'Pause'}
                </BsPause>
              </>
            ) : (
              <BiPlay
                className="custom-play-button"
                onClick={togglePlay}
                style={{ fontSize: '20px', cursor: 'pointer' }}
              >
                {isPlaying ? 'Pause' : 'Play'}
              </BiPlay>
            )}

            <VolumeControl />
            <span>
              <Typography sx={{ color: '#929AA1' }}>
                {formatTime(currentTime)} / {formatTime(totalTime)}
              </Typography>
            </span>
          </Box>
          <Box
            sx={{
              width: '35px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <PiScreencastThin style={{ cursor: 'pointer' }} />
            <BiFullscreen
              onClick={handleFullscreen}
              style={{ cursor: 'pointer' }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default Video;
