/** @format */

import { useEffect, useState } from 'react';
import ChatListItems from './ChatListItems';
import { useSelector } from 'react-redux';

const ChatList = ({ listData, user, chat }) => {
  console.log(listData, 'data');
  const { creator } = useSelector((state) => state.singleCreator);
  const [chatUser, setChatUser] = useState(chat);
  const [selectIndex, setSelectIndex] = useState(0);
  const [userData, setUserData] = useState([...listData]);
  useEffect(() => {
    if (chatUser) {
      let userFound = userData.some((item) => item.creatorID == chatUser);
      console.log(userFound, 'found');
      if (userFound) {
        let filterData = userData.filter((item) => item.creatorID != chatUser);
        let addData = userData.filter((item) => item.creatorID == chatUser);

        setUserData([...addData, ...filterData]);
        setSelectIndex(addData[0].creatorID);
      } else {
        if (creator.data) {
          const newUser = {
            creatorID: creator.data.creatorID,
            username: creator.data.username,
            type: creator.data.type,
            bio: creator.data.bio,
            walletAddress: creator.data.walletAddress,
            img: creator.data.img,
          };
          if (userData.length === 0) {
            setUserData([newUser]);
            setSelectIndex(newUser.creatorID);
          } else {
            setUserData([newUser, ...userData]);
            setSelectIndex(newUser.creatorID);
          }
        } else {
          console.log(userData, 'fdgdgdgfg');
          if (userData.length === 0) {
            setSelectIndex(0);
          } else {
            setSelectIndex(userData[0].creatorID);
          }
        }
      }
    } else {
      setSelectIndex(userData.length > 0 && userData[0].creatorID);
    }
  }, [creator]);

  const handleUser = (index) => {
    setChatUser(null);
    setSelectIndex(index);
  };
  return (
    userData &&
    userData.length > 0 &&
    userData.map((item, index) => {
      return (
        <ChatListItems
          key={index}
          {...item}
          selected={item.creatorID === selectIndex ? true : false}
          user={user}
          handleUser={handleUser}
        />
      );
    })
  );
};
export default ChatList;
