/** @format */

import {
  Box,
  Checkbox,
  InputBase,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Form } from "reactstrap";

import { FiUpload } from "react-icons/fi";
import Button from "../../components/button";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { useDispatch, useSelector } from "react-redux";
import SuccessAlert from "../../components/toaster";

import { useNavigate } from "react-router-dom";
import { resetStateMint } from "../../redux/mint/mint.slicer";
import { minted } from "../../redux/mint/mint.action";
import { clearLogs } from "../../redux/arts/logs.slicer";
import CircularProgress from "@mui/material/CircularProgress";
import MainButton from "../../components/buttonTwo";
import { useAlert } from "react-alert";
import ErrorDialog from "../../components/ErrorModal";
import { useAddress, useChainId, useContract } from "@thirdweb-dev/react-core";
import {
  nftContractAddress,
  nftContractType,
} from "./../../hooks/thirdwebHooks/contractAddresses";
import { async } from "q";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const Mint = () => {
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedImg, setSelectedImage] = useState(false);
  const [errors, setErrors] = useState([]);
  const [open, isOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const { contract, isLoading } = useContract(
    nftContractAddress,
    nftContractType
  );

  useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });
  }, []);
  const wallet = useAddress();
  const chainId = useChainId();
  const handleChange = (event) => {
    const file = event.target.files[0];
    const extension = file.name.split(".").pop().toLowerCase();
    if (extension !== "png" && extension !== "jpeg" && extension !== "jpg") {
      window.alert("Please upload a PNG or JPEG or GIF or WEBP file.");
      event.target.value = ""; // Clear the file input
      return;
    }
    setSelectedImage(event.target.files[0]);
  };
  const handleClose = () => isOpen(false);
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const alert = useAlert();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      image: selectedImg,
      title: "",
      description: "",
      creatorWallet: user.walletAddress,
      amount: "",
      apy: "",
      terms: false,
      country: "",
      projectSize: "",
      yearsInOperation: "",
      capitalInvested: "",
      mezzanineFinancing: "",
      useOfProceeds: "",
      yieldOnMezzanineDebt: "",
    },

    onSubmit: async (values) => {
      console.log(values);
      const error = [];
      if (!values.image) {
        error.push("Image is required");
      }
      if (!values.title) {
        error.push("title is required");
      }
      if (!values.description) {
        error.push("description is required");
      }
      if (!values.amount) {
        error.push("Number of tokens is required");
      }
      if (!values.terms) {
        error.push("accept terms and conditions");
      }
      if (!values.apy) {
        error.push("APY is required");
      }
      if (error.length > 0) {
        setErrors(error);
        isOpen(true);
        return;
      }
      setLoader(true);
      const data = {
        name: values.title,
        description: values.description,
        image: values.image,
        amount: values.amount,
      };
      console.log(values, "payload");
      const tx = await contract.erc721.mintTo(wallet, data);
      console.log(tx, "tx");
      const transactionHash = tx.receipt.transactionHash; // the transaction receipt with hash
      const token = tx.id; // the id of the NFT minted
      const nft = await tx.data();
      console.log(nft, "nft");
      const metadata = nft.metadata;
      console.log(transactionHash, "transactionHash");
      let tokenId = parseInt(token._hex, 16);
      console.log(tokenId, "token");
      const payload = {
        title: values.title,
        description: values.description,
        apy: values.apy,
        amount: values.amount,
        tokenId: tokenId,
        transactionHash: transactionHash,
        metadata: metadata.uri,
        image: metadata.image,
        creatorWallet: wallet,
        country: selectedCountry,
        projectSize: values.projectSize,
        yearsInOperation: values.yearsInOperation,
        capitalInvested: values.capitalInvested,
        mezzanineFinancing: values.mezzanineFinancing,
        useOfProceeds: values.useOfProceeds,
        yieldOnMezzanineDebt: values.yieldOnMezzanineDebt,
      };

      dispatch(minted(payload));
    },
  });

  const { isMintingLoading, isMintingSuccess, isMintingFailed } = useSelector(
    (state) => state.mintSlicer
  );

  const { messages } = useSelector((state) => state.logsSlice);

  setTimeout(() => {
    if (isMintingSuccess) {
      dispatch(resetStateMint());
      dispatch(clearLogs());
      navigate(`/${user?.type}/${user?.username}`);
    }
    if (isMintingFailed) {
      dispatch(clearLogs());
      dispatch(resetStateMint());
    }
  }, 2000);

  return (
    <>
      <ErrorDialog open={open} handleClose={handleClose} errors={errors} />
      <Box
        sx={{
          textAlign: "start",
          p: 1,
          display: "flex",
          justifyContent: "center",
          pl: 10,
          gap: 3,
        }}>
        <Typography variant="h1" sx={{ fontSize: "30px" }}>
          Tokenize and Fractionalize Your Green Energy Project
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: "40px",
          gap: "30px",
        }}>
        <Box sx={{ display: "flex", gap: "20px" }}>
          {(isMintingSuccess || isMintingFailed || isMintingLoading) && (
            <SuccessAlert
              loading={isMintingLoading}
              success={isMintingSuccess}
              error={isMintingFailed}
              message={isMintingFailed ? "Check your balance" : messages[0]}
            />
          )}
          <Box>
            <Box>
              <Form onSubmit={formik.handleSubmit}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    padding: 0,
                  }}>
                  <Box>
                    <Typography variant="h3" sx={{ padding: "10px 0 10px 0" }}>
                      Upload Your Solar Farm Image.
                    </Typography>
                    <label
                      style={{
                        width: "350px",
                        height: "250px",
                        border: "1px solid #cacaca",
                        borderRadius: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        cursor: "pointer",
                        gap: "5px",
                      }}>
                      <InputBase
                        type="file"
                        name="image"
                        onChange={(values) => {
                          handleChange(values);
                        }}
                        sx={{ display: "none" }}
                      />
                      <FiUpload style={{ color: "#9C9C9C" }} />
                      <Typography variant="body2" sx={{}}>
                        PNG, GIF, WEBP, JPG, JPEG
                      </Typography>
                    </label>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mt: "5px",
                    }}>
                    <Typography variant="h3"> Project Name</Typography>

                    <InputBase
                      placeholder="Name of the project"
                      type="title"
                      name="title"
                      onChange={(values) => {
                        formik.handleChange(values);
                      }}
                      sx={{
                        border: `1px solid #cacaca`,
                        padding: "5px",
                        borderRadius: "5px",
                        width: "100%",
                      }}
                    />

                    <Typography variant="h3" sx={{ mt: "10px" }}>
                      {" "}
                      Project Overview
                    </Typography>
                    <TextareaAutosize
                      placeholder="Project Overview"
                      rows="6"
                      type="description"
                      name="description"
                      onChange={(values) => {
                        formik.handleChange(values);
                      }}
                      style={{
                        border: `1px solid #cacaca`,
                        borderRadius: "5px",
                        width: "98%",
                        height: "60px",

                        "& ::placeholder": {
                          paddingLeft: "5px",
                        },
                        "& :focus": {
                          borderColor: "none",
                        },
                      }}
                    />
                    <Typography variant="h3" sx={{ mt: "10px" }}>
                      {" "}
                      Country
                    </Typography>
                    <Select
                      placeholder="Country"
                      name="country"
                      onChange={(e) => {
                        setSelectedCountry(e.target.value);
                      }}
                      style={{
                        width: "98%",
                        border: "1px solid lightgrey",
                        "& ::placeholder": {
                          paddingLeft: "5px",
                        },
                        "& :focus": {
                          border: "1px solid lightgrey",
                        },
                      }}>
                      {countries.map((country) => {
                        return (
                          <MenuItem value={country.label}>
                            {country.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <Typography variant="h3"> Project Size</Typography>

                    <InputBase
                      placeholder=" Project Size
  "
                      type="title"
                      name="projectSize"
                      onChange={(values) => {
                        formik.handleChange(values);
                      }}
                      sx={{
                        border: `1px solid #cacaca`,
                        padding: "5px",
                        borderRadius: "5px",
                        width: "100%",
                      }}
                    />
                    <Typography variant="h3">Years in Operation:</Typography>
                    <InputBase
                      placeholder="Years in Operation
  "
                      type="number"
                      name="yearsInOperation"
                      onChange={(values) => {
                        formik.handleChange(values);
                      }}
                      sx={{
                        border: `1px solid #cacaca`,
                        padding: "5px",
                        borderRadius: "5px",
                        width: "100%",
                      }}
                    />
                    <Typography variant="h3">Capital Invested:</Typography>
                    <InputBase
                      placeholder="Capital Invested
  "
                      type="text"
                      name="capitalInvested"
                      onChange={(values) => {
                        formik.handleChange(values);
                      }}
                      sx={{
                        border: `1px solid #cacaca`,
                        padding: "5px",
                        borderRadius: "5px",
                        width: "100%",
                      }}
                    />
                    <Typography variant="h3">Mezzanine Financing:</Typography>
                    <InputBase
                      placeholder="Mezzanine Financing"
                      type="number"
                      name="mezzanineFinancing"
                      onChange={(values) => {
                        formik.handleChange(values);
                      }}
                      sx={{
                        border: `1px solid #cacaca`,
                        padding: "5px",
                        borderRadius: "5px",
                        width: "100%",
                      }}
                    />
                    <Typography variant="h3">Use of Proceeds:</Typography>
                    <InputBase
                      placeholder="Use of Proceeds  "
                      type="title"
                      name="useOfProceeds"
                      onChange={(values) => {
                        formik.handleChange(values);
                      }}
                      sx={{
                        border: `1px solid #cacaca`,
                        padding: "5px",
                        borderRadius: "5px",
                        width: "100%",
                      }}
                    />
                    <Typography variant="h3">
                      Yield on Mezzanine Debt:
                    </Typography>
                    <InputBase
                      placeholder="Yield on Mezzanine Debt "
                      type="number"
                      name="yieldOnMezzanineDebt"
                      onChange={(values) => {
                        formik.handleChange(values);
                      }}
                      sx={{
                        border: `1px solid #cacaca`,
                        padding: "5px",
                        borderRadius: "5px",
                        width: "100%",
                      }}
                    />

                    <Typography variant="h3" sx={{ mt: "10px" }}>
                      {" "}
                      Number of Tokens
                    </Typography>
                    <InputBase
                      placeholder="Number of Tokens"
                      type="number"
                      name="amount"
                      onChange={(values) => {
                        formik.handleChange(values);
                      }}
                      sx={{
                        border: `1px solid #cacaca`,
                        padding: "5px",
                        borderRadius: "5px",
                        width: "100%",
                      }}
                    />
                    <Typography variant="h3" sx={{ mt: "10px" }}>
                      {" "}
                      The Offered Yield (APY)
                    </Typography>
                    <InputBase
                      placeholder="APY"
                      type="number"
                      name="apy"
                      onChange={(values) => {
                        formik.handleChange(values);
                      }}
                      sx={{
                        border: `1px solid #cacaca`,
                        padding: "5px",
                        borderRadius: "5px",
                        width: "100%",
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      paddingTop: 5,
                      paddingBottom: 1,
                    }}>
                    <Checkbox
                      color="secondary"
                      sx={{ padding: 0 }}
                      name="terms"
                      onChange={(values) => {
                        formik.handleChange(values);
                      }}
                    />
                    <Typography>I agree to the Term and conditions</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: 3,
                    }}>
                    <MainButton
                      variant="contained"
                      type="submit"
                      sx={{
                        backgroundColor: "#34C77B !important",
                        textTransform: "capitalize",
                        color: "#fff !important",
                        borderRadius: "50px",
                        border: "none",
                        width: "100% !important",
                      }}>
                      {loader ? (
                        <CircularProgress color="success" size={30} />
                      ) : (
                        "  create NFT"
                      )}
                    </MainButton>

                    <Button
                      variant="outlined"
                      onClick={() => {
                        dispatch(resetStateMint());
                      }}
                      sx={{
                        border: "1px solid #cacaca",
                        color: "black",
                        borderRadius: "40px",
                        padding: "5px 40px",
                        "&:hover": {
                          border: "1px solid #cacaca",
                          color: "black",
                        },
                      }}>
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </Form>
            </Box>
          </Box>
          <Box
            sx={{
              border: "1px solid #cacaca",
              borderRadius: "10px",
              width: "200px",
              padding: "10px",
              height: "250px",
            }}>
            <Typography sx={{ fontSize: "25px", fontWeight: "bolder" }}>
              Preview
            </Typography>
            <Box sx={{ height: "200px", width: "100%" }}>
              {selectedImg && (
                <img
                  src={URL.createObjectURL(selectedImg)}
                  style={{ width: "100%", height: "100%" }}
                  alt=""
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default Mint;
