/** @format */

import { lumiShare } from "../../http/config";

export const getArticles = async (payload) => {
  try {
    const res = await lumiShare.get(`app.v1/api/getArticles`);
    return res;
  } catch (error) {
    throw error;
  }
};
///////////////////////////////////
export const addArticle = async (payload) => {
  try {
    const res = await lumiShare.post(`app.v1/api/newArticle`, payload);
    return res;
  } catch (error) {
    throw error;
  }
};
