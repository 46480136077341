/** @format */

import { Box, Typography } from "@mui/material";
import React from "react";

const FavCard = ({ image, ownerUsername, fixedprice, title }) => {
  const isIPFSImg = /ipfs:\/\//.test(image),
    imgSrc = isIPFSImg
      ? `https://lumiplace.infura-ipfs.io/ipfs/${image.split("//")[1]}`
      : image;
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          padding: "1.5rem 0",
        }}>
        <Box sx={{ width: "5.8rem", height: "5.8rem" }}>
          <img
            src={imgSrc}
            alt="error"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "0.5rem",
            }}
          />
        </Box>
        <Box>
          <Typography variant="h2">{title}</Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: "0.1rem" }}>
            <Typography variant="body1">By</Typography>
            <Typography variant="h3" fontSize={"0.64rem"}>
              {ownerUsername}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "2.5rem", marginTop: "0.5rem" }}>
            <Box>
              <Typography variant="h2">{fixedprice}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default FavCard;
