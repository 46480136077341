/** @format */

import React, { Component } from "react";
import { Map, GoogleApiWrapper } from "google-maps-react";
import { Marker } from "@react-google-maps/api";
const style = {
  width: "93vw",
  height: "50vh",
};
class MapContainer extends Component {
  render() {
    return (
      <Map
        google={this.props.google}
        style={style}
        zoom={14}
        initialCenter={{
          lat: 25.191964,
          lng: 55.277456,
        }}>
        <Marker
          title={"The marker`s title will appear as a tooltip."}
          name={"SOMA"}
          position={{ lat: 25.191964, lng: 55.277456 }}
        />
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyBNHh4BLvRt_GleYrgT-BK_PXvBF6T5w3c",
})(MapContainer);
