/** @format */

import { lumiShare } from "../../http/config";

export const getVideos = async (payload) => {
  try {
    const res = await lumiShare.get(`app.v1/api/getVideos`);
    return res;
  } catch (error) {
    throw error;
  }
};
///////////////////////////////////
export const addVideo = async (payload) => {
  try {
    const res = await lumiShare.post(`app.v1/api/newVideo`, payload);
    return res;
  } catch (error) {
    throw error;
  }
};
