/** @format */

import React, { useEffect, useState } from "react";
import { Box, Typography, Divider } from "@mui/material";
import FavCard from "../../components/myFavoriteCard";
import MainButton from "../../components/buttonTwo";
import { DeleteOutline } from "@mui/icons-material";

import { useDispatch, useSelector } from "react-redux";
import {
  deleteWishList,
  getWishList,
} from "../../redux/wishlist/wishlist.action";
import Loading from "../../components/loading";
import Notifications from "../../components/Notifications";
import { Link } from "react-router-dom";

const FavoritePage = () => {
  const { WishList, isGetWishListFailed } = useSelector(
    (state) => state.helpersSlicer
  );
  const {
    user: { walletAddress },
  } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Set loading to true before fetching data
        await Promise.all([dispatch(getWishList(walletAddress))]);

        setLoading(false); // Set loading to false after fetching data
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, [walletAddress, dispatch]);

  const handleDelete = async (tokenId, amountId) => {
    let payload = {
      nftToken: tokenId,
      userWallet: walletAddress,
      amountId: amountId,
    };

    await dispatch(deleteWishList(payload)).then(() => {
      dispatch(getWishList(walletAddress));
    });
  };

  if (loading) {
    return (
      <p
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Loading />
      </p>
    );
  }

  return (
    <Box marginBottom={"4rem"}>
      <Notifications />

      <Box>
        <Typography variant="h1" paddingTop={"2rem"} paddingBottom={"1rem"}>
          Your Favorite
        </Typography>
      </Box>
      <Divider />
      <Box>
        {WishList.length === 0 ? (
          <>
            <Typography variant="h1" sx={{ textAlign: "center", padding: 2 }}>
              Check out the Investor Wizard to add in favourites
            </Typography>
          </>
        ) : (
          <>
            {WishList &&
              WishList?.map(
                ({
                  title,
                  ownerUsername,
                  fixedprice,
                  image,
                  tokenId,
                  amountId,
                }) => (
                  <Box
                    key={tokenId} // Added key prop for optimization
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom: "1px solid #00000029",
                    }}>
                    <FavCard
                      image={image}
                      ownerUsername={ownerUsername}
                      fixedprice={fixedprice}
                      title={title}
                    />
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                        }}>
                        <MainButton
                          component={Link}
                          to={`/portfolio/${tokenId}/${amountId}`}
                          variant="contained">
                          Buy Now
                        </MainButton>

                        <MainButton
                          sx={{ textTransform: "capitalize" }}
                          startIcon={<DeleteOutline fontSize="small" />}
                          variant="outlined"
                          onClick={() => handleDelete(tokenId, amountId)}>
                          Delete
                        </MainButton>
                      </Box>
                    </Box>
                  </Box>
                )
              )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default FavoritePage;
