import { Box, InputBase } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

const Input = ({ className, ...props }) => {
  const classes = useStyles();
  return (
    <Box className={clsx(classes.root, className)}>
      <InputBase {...props} />
    </Box>
  );
};

export default Input;

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    background: "#FAFCFF 0% 0% no-repeat padding-box !important",
    border: "1px solid #E5E8EB",
    borderRadius: "8px",
    opacity: 1,
    padding: "8px",
    "&:before": {
      content: "''",
      padding: "2px",
      borderRadius: 5,
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    },
  },
}));
