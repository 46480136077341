/** @format */
import {
  Box,
  Typography,
  Button,
  IconButton,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import LinearDeterminate from '../progressBar';
import Wrapper from '../wrapper';

import { MdOutlineExpandMore } from 'react-icons/md';

import { AiOutlineInstagram } from 'react-icons/ai';
import {
  BiLogoDiscordAlt,
  BiLogoLinkedin,
  BiLogoTwitter,
  BiSolidShareAlt,
} from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
const Details = () => {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('sm'));
  const socialIcons = [
    { icon: <AiOutlineInstagram fontSize="medium" />, label: 'Instagram' },
    { icon: <BiLogoDiscordAlt fontSize="medium" />, label: 'Discord' },
    { icon: <BiLogoLinkedin fontSize="medium" />, label: 'LinkedIn' },
    { icon: <BiLogoTwitter fontSize="medium" />, label: 'Twitter' },
    { icon: <BiSolidShareAlt fontSize="medium" />, label: 'Share' },
  ];
  const {
    data: { description },
  } = useSelector((state) => state?.artsSlicer?.singleArt);
  return (
    <>
      <Wrapper>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '2rem',
          }}
        >
          <Box
            sx={{
              width: isMobileSize ? '100%' : '500px',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <Box>
                <Typography>{description}</Typography>
                <Button
                  disableElevation
                  disableTouchRipple
                  disableRipple
                  variant="text"
                  endIcon={<MdOutlineExpandMore />}
                  sx={{
                    color: 'black',
                    fontSize: '12px',

                    width: 'fit-content',
                    padding: '0px',
                    textTransform: 'capitalize',
                  }}
                >
                  See more
                </Button>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexWrap: isMobileSize ? 'wrap' : null,
                  gap: '5px',
                  width: '100%',
                }}
              >
                <Box sx={{ width: '100%' }}>
                  <Box
                    sx={{
                      width: isMobileSize ? '300px' : null,
                      display: 'flex',
                      gap: '20px',
                      justifyContent: isMobileSize ? 'space-between' : null,
                    }}
                  >
                    <Box>
                      <Typography variant="body1">Price</Typography>
                      <Typography variant="h2">13$</Typography>
                    </Box>
                    <Box>
                      <Typography variant="body1">yeild</Typography>
                      <Typography variant="h2" sx={{ color: '#34C77B' }}>
                        +0.87 LUMI
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ width: isMobileSize ? '100%' : null }}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '20px',
                      justifyContent: isMobileSize ? 'space-between' : null,
                      width: isMobileSize ? '100%' : null,
                    }}
                  ></Box>
                </Box>
              </Box>
              <Box>
                <Box display="flex" alignItems="center" gap="5px">
                  <Typography variant="body1">Average revenue</Typography>
                  <Typography variant="h3">255M$</Typography>
                </Box>
                <LinearDeterminate />
              </Box>
            </Box>
          </Box>
          {isMobileSize ? null : (
            <Box>
              {socialIcons.map((socialIcon, index) => (
                <IconButton
                  key={index}
                  component={Link}
                  sx={{
                    color: 'black',
                    width: 'fit-content',
                  }}
                  aria-label={socialIcon.label}
                >
                  {socialIcon.icon}
                </IconButton>
              ))}
            </Box>
          )}
        </Box>
      </Wrapper>
    </>
  );
};
export default Details;
