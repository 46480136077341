/** @format */

import {
  Box,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useSelector } from 'react-redux';
import MainButton from '../buttonTwo';
import { Link } from 'react-router-dom';
import FarmTabs from '../tabs';

const Profile = () => {
  const {
    user: { walletAddress },
  } = useSelector((state) => state.auth);
  const {
    data: { ownerWallet, tokenId },
  } = useSelector((state) => state?.artsSlicer?.singleArt);
  const { data } = useSelector((state) => state.artsSlicer.singleArt);
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box
      sx={{
        display: 'flex',
        // justifyContent: 'space-between',
        flexWrap: 'wrap',
        padding: {
          lg: '60px 40px',
          xs: ' 30px 10px',
        },

        gap: 10,
      }}
    >
      <Box
        sx={{
          flex: '1 1 300px',
          minWidth: '200px',
        }}
      >
        <img src={data?.image} width={'100%'} alt="" />
      </Box>
      <Box
        sx={{
          width: '45%',
          display: 'flex',
          flex: '1 1 300px',
          minWidth: '200px',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <Box>
          <Typography
            sx={{ fontSize: '25px', color: 'black', fontWeight: 'bold' }}
          >
            {data?.title}
          </Typography>
          <Typography
            sx={{
              fontSize: '18px',
              color: 'black',

              fontWeight: 400,
            }}
          >
            by {data?.username}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: '15px',
              color: 'black',
              display: 'flex',
              alignItems: 'center',
            }}
            variant="body1"
          >
            <span>Price in currency:&nbsp;</span>
            <Typography
              sx={{ fontSize: '15px', color: 'black', fontWeight: 'bold' }}
            >
              ${data?.fixedprice}
            </Typography>
          </Typography>
        </Box>

        <Box>
          <Typography
            sx={{
              fontSize: '15px',
              color: 'black',
              display: 'flex',
              alignItems: 'center',
            }}
            variant="body1"
            pb={2}
          >
            <span>Price in LUMI:&nbsp;</span>{' '}
            <Typography
              sx={{ fontSize: '15px', color: 'black', fontWeight: 'bold' }}
            >
              {data?.fixedprice} LUMI
            </Typography>{' '}
          </Typography>
        </Box>
        <Divider />
        <Box>
          <Typography
            sx={{
              fontSize: '12px',
              color: 'black',
              fontWeight: 'bold',
              paddingBottom: '5px',
            }}
          >
            Collection
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              gap: '3px',
            }}
          >
            <img
              src={data?.image}
              width={'30px'}
              height={'30px'}
              style={{ borderRadius: '5px' }}
              alt=""
            />
            <Typography>{data?.title}</Typography>
          </Box>
          <Box py={2}>
            {ownerWallet && walletAddress === ownerWallet ? (
              <MainButton
                variant="contained"
                component={Link}
                to={`/editfarmprofile/${tokenId}`}
                sx={{
                  backgroundColor: '#34C77B !important',
                  BoxShadow: 'none !important',
                  fontSize: '15px',
                  width: 'fit-content',
                  color: 'white',
                  minWidth: isMobileSize ? '100%' : '100px',
                  textDecoration: 'none',
                }}
              >
                Edit profile
              </MainButton>
            ) : (
              <MainButton
                variant="contained"
                component={Link}
                to={`/portfolio/${data?.tokenId}/${data?.amountId}`}
                sx={{
                  backgroundColor: '#34C77B !important',
                  BoxShadow: 'none !important',
                  fontSize: '15px',
                  width: 'fit-content',
                  color: 'white !important',
                  minWidth: isMobileSize ? '100%' : '100px',
                  textDecoration: 'none',
                }}
              >
                Buy Now
              </MainButton>
            )}
          </Box>
          <Divider />
          <Box pt={1}>
            <Box sx={{ width: '100%' }}>
              <Box
                sx={{
                  width: isMobileSize ? '300px' : null,
                  display: 'flex',
                  gap: '20px',
                }}
              >
                <Box width="50%">
                  <Typography variant="h2">Country</Typography>
                  <Typography sx={{ fontWeight: 0, fontSize: '14px' }}>
                    {data?.country}
                  </Typography>
                </Box>
                <Box width="50%">
                  <Typography variant="h2">Project Size</Typography>
                  <Typography sx={{ fontWeight: 0, fontSize: '14px' }}>
                    {data?.projectSize}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box sx={{ width: '100%' }} py={1}>
              <Box
                sx={{
                  width: isMobileSize ? '300px' : null,
                  display: 'flex',
                  gap: '20px',

                  justifyContent: isMobileSize ? 'space-between' : null,
                }}
              >
                <Box width="50%">
                  <Typography variant="h2">Years In Operation</Typography>
                  <Typography sx={{ fontWeight: 0, fontSize: '14px' }}>
                    {data?.yearsInOperation}
                  </Typography>
                </Box>
                <Box width="50%">
                  <Typography variant="h2">Capital Invested</Typography>
                  <Typography sx={{ fontWeight: 0, fontSize: '14px' }}>
                    {data?.capitalInvested}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box sx={{ width: '100%' }}>
              <Box
                sx={{
                  width: isMobileSize ? '300px' : null,
                  display: 'flex',

                  gap: '20px',
                  justifyContent: isMobileSize ? 'space-between' : null,
                }}
              >
                <Box width="50%">
                  <Typography variant="h2">Mezzanine Financing</Typography>
                  <Typography sx={{ fontWeight: 0, fontSize: '14px' }}>
                    {' '}
                    {data?.mezzanineFinancing}
                  </Typography>
                </Box>
                <Box width="50%">
                  <Typography variant="h2">Use Of Proceeds</Typography>
                  <Typography sx={{ fontWeight: 0, fontSize: '14px' }}>
                    {data?.useOfProceeds}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box sx={{ width: '100%' }}>
              <Box
                sx={{
                  width: isMobileSize ? '300px' : null,
                  display: 'flex',
                  gap: '20px',

                  justifyContent: isMobileSize ? 'space-between' : null,
                }}
              >
                <Box width="50%">
                  <Typography variant="h2">Yield On Mezzanine Debt</Typography>
                  <Typography sx={{ fontWeight: 0, fontSize: '14px' }}>
                    {' '}
                    {data?.yieldOnMezzanineDebt}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <FarmTabs />
        </Box>
      </Box>
    </Box>
  );
};

export default Profile;
