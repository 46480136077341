/** @format */

import { Box } from "@mui/material";
import InvestmentHistory from "../../components/InvestmentHistory";
import EnergyProduce from "../../components/EnergyProduce";
import InvestorProfile from "../../components/investorProfile";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { inWallet } from "../../redux/inWallet/inwallet.action";
import { singleCreator } from "../../redux/singleCreator/singleCreator.action";
import { useEffect, useState } from "react";
import Loading from "../../components/loading";
import CustomToaster from "../../components/toaster";
import { resetStateMint } from "../../redux/mint/mint.slicer";
import { clearLogs } from "../../redux/arts/logs.slicer";
import Notifications from "../../components/Notifications";

const InverstorProfile = () => {
  const dispatch = useDispatch();
  const { user, isAuthenticated } = useSelector((state) => state.auth);
  const { username } = useParams();
  const { data } = useSelector((state) => state.singleCreator.creator);
  const [loading, setLoading] = useState(true);
  const { isPutOnSaleFixedSuccess, isPutOnSaleFixedFailed } = useSelector(
    (state) => state.mintSlicer
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        let wallet = null;
        if (isAuthenticated) {
          wallet = user.walletAddress;
        }
        let payload = { wallet: wallet, user: username };
        await Promise.all([dispatch(singleCreator(payload))]).then(() =>
          dispatch(inWallet(user?.walletAddress))
        );

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, user, username, setLoading]);

  if (loading) {
    return (
      <p style={{ display: "flex", justifyContent: "center" }}>
        <Loading color="success" />
      </p>
    );
  }
  setTimeout(() => {
    if (isPutOnSaleFixedSuccess) {
      dispatch(resetStateMint());
      dispatch(clearLogs());
      window.location.reload();
    }
    if (isPutOnSaleFixedFailed) {
      dispatch(resetStateMint());
      dispatch(clearLogs());
    }
  }, 2000);
  return (
    <Box>
      <Notifications />
      <InvestorProfile />
      <InvestmentHistory />
    </Box>
  );
};
export default InverstorProfile;
